import React from 'react';
import './Popup.css';

function Popup(props) {
  return (props.trigger)?( 
        <div className='pop'>
            <div className='pop-inner'>
                <button 
                    className='closebtn'
                    onClick={()=>props.setTrigger(false)}>
                      ×
                </button>
                {props.children} 
            </div>
        </div>
  ):"";
}

export default Popup;