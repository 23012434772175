import React, { useEffect,useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {  Link, useHistory, useRouteMatch } from "react-router-dom";
import Content from './Content'; 
import fire from "../../config/fire";
import Select from "react-select"
import "./MotherLand.css";
import MotherLandCountry from './MotherLandCountry';

function MotherLand() {

  // country list array
  var countrylist = [
    {
      value:'PN',
      label:"Africa"
    },
    {
      value:'DZ',
      label:"Algeria"
    },
    {
      value:'AO',
      label:"Angola"
    },
    // {
    //   value:'CG',
    //   label:"Congo"
    // },
    {
      value:'DRC',
      label:"D.R.C"
    }
    ,{
      value:'EG',
      label:"Egypt"
    }
    ,{
      value:'ET',
      label:"Ethiopia"
    },
    ,{
      value:'KE',
      label:"Kenya"
    },
    ,{
      value:'MW',
      label:"Malawi"
    },
    {
      value:'NA',
      label:"Nigeria"
    },
    {
      value:'SA',
      label:"South Africa"
    },
    {
      value:'SD',
      label:"Sudan"
    },
    {
      value:'TZ',
      label:"Tanzania"
    },
    {
      value:'UG',
      label:"Uganda"
    },
  
    {
      value:'ZM',
      label:"Zambia"
    }

  ];
  // Data declaration
  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
        if (window.location.pathname === "/admin")
          history.push("/admin/dashboard");
        history.push(window.location.pathname);
      } 
      else {
        history.push("/admin/login");
        
      }
    });
  }, [dispatch]);

  // handle click
  const initail ="PN";
 
  const [selected,selectedValue] = useState(countrylist.label);
  const [content, setContent] = useState(Content);
  
   const allContent = ['All',...new Set(content.map(item =>item.lang))]
          const [buttons, setButtons] = useState(allContent);
  const filter = (button) => {
        if(button === 'All'){
          setContent(Content);
          return;
        }
        const filteredData = Content.filter(item => item.lang === button);
        setContent(filteredData);
  }


  var isSelected = selected;
  const selectedhandler = e => {
      selectedValue(e.value);
  }
     if(isSelected){
        var code= isSelected;
     }
     else
     code=initail;

    return (
   <>   
     <div className='mom-container'> 
              <MotherLandCountry code={code} />
              <div className='country-selector'>
                 <Select 
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary25: 'grey',
                        primary: '#fff',
                        neutral0: ' #dd4b39',
                      },
                    })}
                 options={countrylist}  onChange={selectedhandler}/>
              </div>
     </div>
    </>
    )
}

export default MotherLand;
