import React, { useEffect, useState } from 'react';
import { Link, useHistory , useRouteMatch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import fire from "../../../config/fire";
import './English.css'
// import { Skeleton } from "@material-ui/lab";
// import { Grid } from '@material-ui/core';


const Poetry = () => {
    const ref = fire.firestore().collection("Poetry");
    // const Action = fire.firestore().collection("Action");
    // const Adventure = fire.firestore().collection("Adventure");
    // const Fantasy = fire.firestore().collection("Fantasy");
    const dispatch = useDispatch();

    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    // const [data1, setdata1] = useState([]);
    // const [loader1, setloader1] = useState(true);

    // const [data2, setdata2] = useState([]);
    // const [loader2, setloader2] = useState(true);

    // const [data3, setdata3] = useState([]);
    // const [loader3, setloader3] = useState(true);


    function getData(){
        ref
        .orderBy('image','desc')
        .onSnapshot((querySnapshot)=> {
            const items = []
            querySnapshot.forEach((doc)=>{
                items.push(doc.data())
                 
            })
            setdata(items)
            setloader(false)
        })
    }

    // Get story files from firebase

// function getAction(){
//   Action.onSnapshot((querySnapshot)=> {
//       const items = []
//       querySnapshot.forEach((doc)=>{
//           items.push(doc.data())
           
//       })
//       setdata1(items)
//       setloader1(false)
//   })
// }

// function getAdventure(){
//   Adventure.onSnapshot((querySnapshot)=> {
//       const items2 = []
//       querySnapshot.forEach((doc)=>{
//           items2.push(doc.data())
           
//       })
//       setdata2(items2)
//       setloader2(false)
//   })
// }

// function getFantasy(){
//   Fantasy.onSnapshot((querySnapshot)=> {
//       const items3 = []
//       querySnapshot.forEach((doc)=>{
//           items3.push(doc.data())
           
//       })
//       setdata3(items3)
//       setloader3(false)
//   })
// }

    useEffect(() => {
        getData();
        // getAction();
        // getAdventure();
        // getFantasy();

      }, []);
     

     //Home posts const and firebase links
 const { posts, postsLoading, isLoggedIn, userId } = useSelector(
    (state) => ({
      posts: state.posts.posts,
      postsLoading: state.posts.postsLoading,
      isLoggedIn: state.auth.isLoggedIn,
      userId: state.auth.userId,
    }),
    shallowEqual
  );

      // Data declaration
      const history = useHistory();
      let { path } = useRouteMatch();
    
      useEffect(() => {
        fire.auth().onAuthStateChanged((user) => {
          if (user) {
            if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
            if (window.location.pathname === "/admin")
              history.push("/admin/dashboard");
            history.push(window.location.pathname);
          } 
          else {
            history.push("/admin/login");
            
          }
        });
      }, [dispatch]);
    

    return (

<div className='row'>
                    <div className='rowPosters'>
                            {loader === false && (data.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>  
                                                          <br/>    
                                                          {doc.category}

                               </div>                 

                            ))}
                    </div>

                    {/* <h2 className="Movetile changeColortwo ">Action</h2>

                    <div className='rowPosters'>
                            {loader1 === false && (data1.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link> 

                             </div>                     

                            ))}
                    </div>

                     <h2 className="Movetile changeColortwo ">Adventure</h2>

                    <div className='rowPosters'>
                            {loader2 === false && (data2.map((doc) =>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>                       

                            ))}
                    </div>

                     <h2 className="Movetile changeColortwo ">Fantacy</h2>

                    <div className='rowPosters'>
                            {loader3 === false && (data3.map((doc) =>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>                       

                            ))}
                    </div>                                                           */}

</div>



        
    );
};

export default Poetry;