import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { Editor } from '@tinymce/tinymce-react'; 
import fire from "../../config/fire";

import {
  getPosts,
  postUpdate,
} from "../../redux/actionCreators/postsActionCreator";



const EditPost = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState ("");

  const twoCalls = e => {
    handleEditorChange(e)
  
  }
  const handleEditorChange = (e) => {
    setDescription(
    e.target.getContent()
    );
  }
  const { posts, postsLoading } = useSelector(
    (state) => ({
      posts: state.posts.posts,
      postsLoading: state.posts.postsLoading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const currentPost = posts.find((post) => post.postId === id && post);

 
  
  useEffect(() => {
    if (postsLoading) {
      dispatch(getPosts());
    }
    if (currentPost) {
      setTitle(currentPost.post.title);
      setLanguage(currentPost.post.language);
      setCategory(currentPost.post.category);
      setDescription(currentPost.post.description);
    }
  }, [dispatch, currentPost]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title || !language || !category || !description) {
      return toast.warning("Please fill in all fields!");
    }

    if (description.length < 100) {
      return toast.info("Description should be of atleast 100");
    }
    if (title.trim().split(" ").length < 2) {
      return toast.info("Title should be of atleast 2 words");
    }

    const data = { title, language, description, category };
    dispatch(postUpdate(currentPost.postId, data));
    toast.success("Post Updated Successfully!!");
  };



  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-5">
          <h1 className="display-2 text-center">Edit Story</h1>
        </div>
        {currentPost ? (
          <div className="col-md-10 mx-auto mb-5 shadow p-5">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Language"
                  className="form-control"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                />
              </div>              
              <div className="form-group">              
<label>
          Category:
          <select value={category}  onChange={(e) => setCategory(e.target.value)}>            
            <option value="none">Select here</option>
            <option value="Adventure">Adventure</option>
            <option value="Action">Action</option>
            <option value="Art/architecture">Art/architecture</option>
            <option value="Fantasy ">Fantasy </option>
            <option value="Historical Fiction">Historical Fiction</option>
            <option value="Poetry">Poetry</option>
            <option value="Nonfiction">Nonfiction</option>
            <option value="Fiction">Fiction</option>
            <option value="Action and adventure">Action and adventure</option>
            <option value="Alternate history">Alternate history</option>
            <option value="Autobiography">Autobiography</option>
            <option value="Biography">Biography</option>
            <option value="Chick lit">Chick lit</option>
            <option value="Business/economics">Business/economics</option>
            <option value="Children's">Children's</option>
            <option value="Crafts/hobbies">Crafts/hobbies</option>
            <option value="Classic">Classic</option>
            <option value="Cookbook">Cookbook</option>
            <option value="Comic book">Comic book</option>
            <option value="Diary">Diary</option>
            <option value="Coming-of-age">Coming-of-age</option>
            <option value="Dictionary">Dictionary</option>
            <option value="Crime">Crime</option>
            <option value="Encyclopedia">Encyclopedia</option>
            <option value="Drama">Drama</option>
            <option value="Guide">Guide</option>
            <option value="Fairytale">Fairytale</option>
            <option value="Health/fitness">Health/fitness</option>
            <option value="History">History</option>
            <option value="History">History</option>
            <option value="Graphic novel">Graphic novel</option>
            <option value="Home and gadenr">Home and gadenr</option>
            <option value="Historical fiction">Historical fiction</option>
            <option value="Humor">Humor</option>
            <option value="Journal">Journal</option>
            <option value="Mystery">Mystery</option>
            <option value="Math">Math</option>
            <option value="Paranormal romance">Paranormal romance</option>
            <option value="Memoir">Memoir</option>
            <option value="Picture book">Picture book</option>
            <option value="Philosophy">Philosophy</option>
            <option value="Poetry">Poetry</option>
            <option value="Political thriller">Political thriller</option>
            <option value="Religion">Religion</option>
            <option value="Romance">Romance</option>
            <option value="Textbook">Textbook</option>
            <option value="Satire">Satire</option>
            <option value="True crime">True crime</option>
            <option value="Science fiction">Science fiction</option>
            <option value=">Review">Review</option>
            <option value="Short story">Short story</option>
            <option value="Action and Science">Science</option>
            <option value="Suspense">Suspense</option>
            <option value="Self hele">Self help</option>
            <option value="Sports and leisure">Sports and leisure</option>
            <option value="Sports and leisure">Sports and leisure</option>
          </select>
        </label>
              </div>
              <div className="form-group">
              <Editor
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        value={description}
        onChange={twoCalls}


        />
                {/* <textarea

                  placeholder="Enter Story"
                  className="form-control"
                  rows="30"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea> */}
              </div>
              <div className="input-group">
                <input
                  type="submit"
                  className="btn btn-dark w-50 mr-auto"
                  value="Update Post"
                />
                <button
                  type="button"
                  className="col-md-2 btn btn-danger w-50 mL-50"
                  onClick={() => history.push("/admin/dashboard/posts")}
                >
                  Go Back
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="col-md-12">
            {postsLoading ? (
              <h1 className="text-center">Story Loading...</h1>
            ) : (
              <h1 className="text-center">
                Error 404 : Story With Id {id} Not Exists
              </h1>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditPost;
