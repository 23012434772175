import "./QuotePage.css"
const QuoteCategory =()=>{



  return (
<>
<div className="card1" style={{ maxWidth: "23rem" }}>
          <div className="card-header" style={{ background: "#ef4343" , fontWeight: "Bold", color: "white", borderRadius: "0px", height: "70px", TextAlign: "center"}}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-blockquote-left" viewBox="0 0 16 16">
    <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm5 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm-5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm.79-5.373c.112-.078.26-.17.444-.275L3.524 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282c.024-.203.065-.37.123-.498a1.38 1.38 0 0 1 .252-.37 1.94 1.94 0 0 1 .346-.298zm2.167 0c.113-.078.262-.17.445-.275L5.692 6c-.122.074-.272.17-.452.287-.18.117-.35.26-.51.428a2.425 2.425 0 0 0-.398.562c-.11.207-.164.438-.164.692 0 .36.072.65.217.873.144.219.385.328.72.328.215 0 .383-.07.504-.211a.697.697 0 0 0 .188-.463c0-.23-.07-.404-.211-.521-.137-.121-.326-.182-.568-.182h-.282a1.75 1.75 0 0 1 .118-.492c.058-.13.144-.254.257-.375a1.94 1.94 0 0 1 .346-.3z"/>
  </svg> QUOTE CATEGORIES</div>
          <div className="card-body">
  
  
  
            <small className="text-dark" style={{ fontSize: "16px", display:"table-cell", fontWeight: "Bold"}}>💡 Inspirational Quotes</small><hr style={{color: "orange"}}></hr>
            <small className="text-dark"  style={{ fontSize: "16px", display:"table-cell" , fontWeight: "Bold"}}>💖 Love Quotes</small><hr style={{color: "red"}}></hr>
            <small className="text-dark"  style={{ fontSize: "16px", display:"table-cell" , fontWeight: "Bold"}}>🌍 Life Quotes</small><hr style={{color: "green"}}></hr>
  
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>🎭 Humor Quotes</small><hr style={{color: "purple"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold" }}>👼 God Quotes</small><hr style={{color: "blue"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>🤝 Truth Quotes</small><hr style={{color: "orange"}}></hr>
  
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>🦉 Wisdom Quotes</small><hr style={{color: "brown"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>📝 Quote Quotes</small><hr style={{color: "blue"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>😍 Romantic Quotes</small><hr style={{color: "red"}}></hr>
  
  
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>💀 Death Quotes</small><hr style={{color: "black"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>😀 Hapiness Quotes</small><hr style={{color: "orange"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>🤞 Hope Quotes</small><hr style={{color: "green"}}></hr>
  
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>🌟 Faith Quotes</small><hr style={{color: "blue"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>📚 Life Lessons Quotes</small ><hr style={{color: "red"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>✊ Truth Quotes</small><hr style={{color: "grey"}}></hr>
  
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold" }}>💪 Motivational Quotes</small><hr style={{color: "purple"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>💑 Relationships Quotes</small><hr style={{color: "green"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold"}}>⏰ Time Quotes</small><hr style={{color: "orange"}}></hr>
  
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold" }}>🤓 Knowledge Quotes</small><hr style={{color: "blue"}}></hr>
            <small className="text-dark" style={{ fontSize: "16px" , fontWeight: "Bold" }}>👩‍🔬 Science Quotes</small><hr style={{color: "grey"}}></hr>
  
          </div>
        </div>
</>
  );
};


export default QuoteCategory;
