/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import flags from "react-phone-number-input/flags";

function Footer() {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [value, setValue] = useState();

  return (
    <><div className="footer-container ">
      <body>

        {/* <section className="footer-subscription ">
      <p className="footer-subscription-heading">
        Join Oniontable WhatsApp Group and receive our best stories and books.
      </p>
      <p className="footer-subscription-text ">
        You can Subscribe here for WhatsApp Group
      </p>
      <div className="input-ares">
        <form>
          <input
            type="text"
            name="text"
            placeholder="   WhatsApp Number"
            className="footer-input"
          />

          <br />
          <input type="button" value="Subscribe" id="sub-btn"/>
        </form>
      </div>
    </section> */}
        <div className="footer-links">
          <div className="footer-link-wrapper ">
            <div class="footer-link-items">
              <h5>Information</h5>
              <Link to="/admin/dashboard/About">About Us</Link>
              <Link to="/admin/dashboard/HowitWorks">How it Works</Link>
              {/* <Link to="/sign-up">How it works</Link> */}
              {/* <Link to="">Investors</Link>
              <Link to="">Contact</Link>
              <Link to="">Support</Link> */}
              <Link to="/admin/dashboard/Privacy">Privacy Policy</Link>
            </div>
          </div>

          <div className="footer-link-wrapper">
            <div class="footer-link-items">
              <h5>Social Media</h5>
              <Link to="/">Instagram</Link>
              <Link to="/">Facebook</Link>
              <Link to="/">Youtube</Link>
              <Link to="/">Twitter</Link>
            </div>
          </div>
        </div>
        <section class="social-media" style={{ color: "#020202" }}>
          <div class="social-media-wrap ">
            <div class="footer-logo">
              <Link to="/" className="social-logo">
                ONIONTABLE
              </Link>
            </div>
            <small class="website-rights">ONIONTABLE © 2022</small>
            <div class="social-icons ">
              {/* <a href="http://facebook.com/OnionTable-102374795602007"
                class="social-icon-link facebook "
                target="_blank"
                aria-label="Facebook"
              >
                <i class="fab fa-facebook-f" />
              </a>
              <a href="https://www.instagram.com/oniontable_/"
                class="social-icon-link instagram"
                target="_blank"
                aria-label="Instagram"
              >
                <i class="fab fa-instagram" />
              </a> */}
              {/* <Link
      class="social-icon-link youtube"
      to="/"
      target="_blank"
      aria-label="Youtube"
    >
      <i class="fab fa-youtube" />
    </Link> */}
              {/* <a href="https://twitter.com/0niontable"
                class="social-icon-link twitter"
                target="_blank"
                aria-label="Twitter"
              >
                <i class="fab fa-twitter" />
              </a> */}

            </div>
          </div>
        </section>
      </body>
    </div><div class="sharethis-inline-follow-buttons"></div></>
  );
}

export default Footer;
