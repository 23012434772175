
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import fire from '../../config/fire';
import Countryimgs from './CountryImgs';
import HistoryEdit from './HistoryEdit';
import ImageUpload from './ImageUpload';
import Popup from './Popup';

// const ref = fire.firestore().collection("countryProfile");
const ref = fire.firestore().collection("CountryProfile");
const countryImgRef = fire.firestore().collection("CountryImages");
const flagRef = fire.firestore().collection("Flags");


const MotherLandCountry = (props) => {
   var code = props.code;
    
    //data definitions with use state
        const [edt, setEdt] = useState(false);
        const [data, setdata] = useState([]);
        const [loading, setloading] = useState();
        const [editbox0, seteditbox0] = useState(false);
        const [editbox1, seteditbox1] = useState(false);
        const [editbox2, seteditbox2] = useState(false);
        const [editbox3, seteditbox3] = useState(false);
        const [editbox4, seteditbox4] = useState(false);
        const [editbox5, seteditbox5] = useState(false);
        const [addImg, setAddImg] = useState(false);
        const [writer, setwriter] = useState(false);
        const [flag, setFlag] = useState([]);
        const [countryImgs, setCountryImgs] = useState([]);

        var title0 = "bg";
        var title1 = "hst";
        var title2 = "plts";
        var title3 = "dmgp";
        var title4 = "cul";
        var title5 = "nr";

    
        // function to get data from fire firestore
        useEffect(() => {
        
            // function to get data from fire firestore
            function dataFromFb(){
                setloading(false);
                ref.onSnapshot((querySnapshot) => {
                    const items = []
                    querySnapshot.forEach((doc) => {
                        items.push(doc.data());
        
                    });
                    setdata(items);
                    setloading(false);
                })
            }
            function flagsFromFb(){
                setloading(false);
                flagRef.onSnapshot((querySnapshot) => {
                    const items = []
                    querySnapshot.forEach((doc) => {
                        items.push(doc.data());
        
                    });
                    setFlag(items);
                    setloading(false);
                })
            }
            function countryImages(){
                setloading(false);
                countryImgRef.onSnapshot((querySnapshot) => {
                    const items = []
                    querySnapshot.forEach((doc) => {
                        items.push(doc.data());
                        console.log(items);
                    });
                    setCountryImgs(items);
                    setloading(false);
                })
            }
            countryImages();
            flagsFromFb();
            dataFromFb();
        },[])

    return(
        <div>
            <div>
                {/* filter for country name and flag */}
                {flag && flag.filter((country)=> country.id === code ).map((country) => <img  id='flag' src={country.imgURL} alt='Please wait...'/>)}
            </div>
            <div>
                {flag && flag.filter((country)=> country.id === code).map((country) => <img  id='flag2' src={country.imgURL} alt='Please wait...'/>)}
            </div>
            {
                data.filter((country)=> country.id === code).map((country) =>
                    <div key={code} >
                        {/* mother land country profile data */}
                        <br/>
                        <br/>
                    
                        <div className='history'>
                            {/* country images display call */}
                            <div className='ML-country' >
                            <h1 id='name'> {data.filter((country)=> country.id === code).map((country) =>country.name)}</h1>
                        
                                { 
                                    <Countryimgs code={code} imgs={countryImgs} /> 
                                }
                                {/* Validation as writer */}
                                    <Link to="#" id='imgBtn'
                                            onClick={() =>
                                                {
                                                    if(writer)
                                                    {
                                                        setAddImg(true)
                                                    }
                                                    else if (!writer)
                                                    {
                                                        window.location="/admin/dashboard/MotherLandForm"
                                                    } 
                                                } 
                                            }
                                    >add more images </Link>
                            </div>
                            
                            <br/>
                                    
                            <h3 id='heading'>Background</h3 >
                            <hr id='horizontal'/>
                            <p id='historytext'> 
                                {country.Background}
                                <Link to="#" id='edit-bt'
                                            onClick={() =>
                                                    {
                                                        if(writer)
                                                        {
                                                            seteditbox0(true)
                                                        }
                                                        else if (!writer)
                                                        {
                                                        window.location="/admin/dashboard/MotherLandForm"
                                                        } 
                                                    } 
                                                }
                                        > edit 
                                </Link>
                            </p>

                            <br/>

                            <h3 id='heading'>History</h3 >
                            <hr id='horizontal'/>
                            <p id='historytext'> 
                                {country.History}
                                {/* Edit button to open popup for text editing */}
                                <Link to="#" id='edit-bt'
                                            onClick={() =>
                                                    {
                                                        if(writer)
                                                        {
                                                            seteditbox1(true)
                                                        }
                                                        else if (!writer)
                                                        {
                                                        window.location="/admin/dashboard/MotherLandForm"
                                                        } 
                                                    }    
                                                }
                                        > edit 
                                </Link>
                            </p>
                            
                            <br/>

                            <h3 id='heading'>Politics</h3 >
                            <hr id='horizontal'/>
                            <p id='historytext'> 
                                {country.Politics}
                                {/* Edit button to open popup for text editing */}
                                <Link to="#" id='edit-bt'
                                            onClick={() =>
                                                    {
                                                        if(writer)
                                                        {
                                                            seteditbox2(true)
                                                        }
                                                        else if (!writer)
                                                        {
                                                        window.location="/admin/dashboard/MotherLandForm"
                                                        } 
                                                    }
                                                }
                                        > edit 
                                </Link>
                            </p>

                            <br/>

                            <h3 id='heading'>Demography</h3 >
                            <hr id='horizontal'/>
                            <p id='historytext'> 
                                {country.Demography}
                                {/* Edit button to open popup for text editing on demography */}
                                <Link to="#" id='edit-bt'
                                            onClick={() =>
                                                    {
                                                        if(writer)
                                                        {
                                                            seteditbox3(true)
                                                        }
                                                        else if (!writer)
                                                        {
                                                        window.location="/admin/dashboard/MotherLandForm"
                                                        } 
                                                    }
                                                }
                                        > edit 
                                </Link>
                            </p>
                            
                            <br/>

                            <h3 id='heading'>Culture</h3 >
                            <hr id='horizontal'/>
                            <p id='historytext'> 
                                {country.Culture}
                                {/* Edit button to open popup for text editing on Culture */}
                                <Link to="#" id='edit-bt'
                                            onClick={() =>
                                                    {
                                                        if(writer)
                                                        {
                                                            seteditbox4(true)
                                                        }
                                                        else if (!writer)
                                                        {
                                                        window.location="/admin/dashboard/MotherLandForm"
                                                        } 
                                                    }
                                                }
                                        > edit
                                </Link>
                            </p>

                            <br/>

                            <h3 id='heading'>Natural Resources</h3 >
                            <hr id='horizontal'/>
                            <p id='historytext'> 
                                {country.Resources}
                                {/* Edit button to open popup for text editing on the Natural Resources*/}
                                <Link to="#" id='edit-bt'
                                            onClick={() =>
                                                    {                    
                                                        if(writer)
                                                        {
                                                            seteditbox5(true)
                                                        }
                                                        else if (!writer)
                                                        {
                                                        window.location="/admin/dashboard/MotherLandForm"
                                                        } 
                                                    } 
                                                }
                                        > edit
                                </Link> 
                            </p>
                        
                            <br/>

                        </div > 
                            {/* =================Popup Triggers=================== */}
                            <div id="edt">
                                <Popup trigger={editbox0} setTrigger={seteditbox0}>
                                    {/* edit box entry */}
                                    {editbox0 === true && <HistoryEdit   
                                        country={country} 
                                        history={country.Background}
                                        seteditbox={seteditbox0} 
                                        code={code} 
                                        title={title0}
                                        />
                                    }
                                </Popup>
                                
                                <Popup trigger={editbox1} setTrigger={seteditbox1}>
                                
                                    {editbox1 === true && <HistoryEdit   
                                        country={country} 
                                        history={country.History} 
                                        seteditbox={seteditbox1} 
                                        code={code}
                                        title={title1}  />
                                    }
                                </Popup>
                                
                                <Popup trigger={editbox2} setTrigger={seteditbox2}>
                                
                                    {editbox2 === true && <HistoryEdit   
                                        country={country} 
                                        history={country.Politics} 
                                        seteditbox={seteditbox2} 
                                        code={code}
                                        title={title2} />
                                    }
                                </Popup>
                                
                                <Popup trigger={editbox3} setTrigger={seteditbox3}>
                                    
                                    {editbox3 === true && <HistoryEdit   
                                        country={country} 
                                        history={country.Demography} 
                                        seteditbox={seteditbox3} 
                                        code={code}
                                        title={title3}/>
                                    }
                                </Popup>
                                
                                <Popup trigger={editbox4} setTrigger={seteditbox4}>
                                    
                                    {editbox4 === true && <HistoryEdit   
                                        country={country} 
                                        history={country.Culture}
                                        seteditbox={seteditbox4} 
                                        code={code} 
                                        title={title4} />
                                    }
                                </Popup>

                                <Popup trigger={editbox5} setTrigger={seteditbox5}>
                                
                                    {editbox5 === true && <HistoryEdit   
                                        country={country} 
                                        history={country.Resources} 
                                        seteditbox={seteditbox5} 
                                        code={code}
                                        title={title5}/>
                                    }
                                </Popup>
                                
                                
                                <Popup trigger={addImg} setTrigger={setAddImg}>
                                    {/* image upload code */}
                                    {addImg === true && <ImageUpload 
                                        country={country} setAddImg={setAddImg} 
                                        code={code} />
                                    }
                                </Popup>
                            </div>
                        
                    </div>
                )
            }       
        </div>
    )
};

export {ref};
export default MotherLandCountry;