import React, { useEffect } from "react";
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import fire from "../../config/fire";

import Home from "../../Components/Home";
import AddPost from "./AddPost";
import Posts from "./Posts";
import SeePost from "./SeePost";
import EditPost from "./EditPost";
import Browse from "./Browse";
import Books from "./Books";
import Profile from "./Profile";
import Login from "../Login/index";
import Register from "../Register/index";
import OthersProfile from "./OthersProfile";
import MotherLand from "./MotherLand";
import About from "./About";
import Notification from "./Notification";
import English from "./Languages/English";
import OthersChat from "./OthersChat";
import AddChapter from "./AddChapter";
import Privacy from "./Privacy";
import Chapter from "./Chapter";
import EditChapter from "./EditChapter";
import ChapterView from "./ChapterView";
import WritersForm from "./WritersForm";
import ContactForm from "./ContactForm";
import HowItWorks from "./HowItWorks";
import PostQuote from "./PostQuote";
import MotherLandForm from "./MotherLandForm";

// import Swahili from "./Languages/Swahili";
import Bemba from "./Languages/Bemba";
import LanguageButtons from "./LanguageButtons";
import Homein from "../../Components/Home/index2";
import Poetry from "./Languages/Poetry";
import Library from "./Library";
import Africaninventers from "./AfricanInventors";
import EditInventorBio from "./EditInventorBio";


const Dashboard = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  console.log(`path`, path);


  //logout User
  const logoutUser = () => {
    fire
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "RESET_USER" });
        toast.success("You are successfully logged out");
        history.push("/admin/login");
      })
      .catch((error) => toast.error(error.message));
  };
  return (
    <>
      <Switch >
        <Route exact path={path}>
          <Home />
        </Route>
        <Route exact path={`${path}/addPost`}>
          <AddPost />
        </Route>
        <Route exact path={`${path}/posts`}>
          <Posts />
        </Route>
        <Route exact path={`${path}/post/:id`}>
          <SeePost />
        </Route>
        <Route exact path={`${path}/post/:id/edit`}>
          <EditPost />
        </Route>
        <Route exact path={`${path}/browser`}>
          <Browse />
        </Route>
        <Route exact path={`${path}/profile`}>
          <Profile />
        </Route>
        <Route exact path={`${path}/notification`}>
          <Notification />
        </Route>
        <Route exact path={`${path}/login`}>
          <Login />
        </Route>
        <Route exact path={`${path}/register`}>
          <Register />
        </Route>
        <Route exact path={`${path}/books`}>
          <Books />
        </Route>
        <Route exact path={`${path}/MotherLand`}>
          <MotherLand />
        </Route> 
        <Route exact path={`${path}/About`}> 
          <About />
        </Route> 
        <Route exact path={`${path}/English`}> 
          <English />
        </Route> 
  
        <Route exact path={`${path}/Bemba`}> 
          <Bemba />
        </Route> 

        <Route exact path={`${path}/Poetry`}> 
          <Poetry />
        </Route> 
        <Route exact path={`${path}/LanguageButtons`}> 
          <LanguageButtons />
        </Route> 
        <Route exact path={`${path}/Homein`}> 
          <Homein />
        </Route> 
        <Route exact path={`${path}/OthersChat/:otherUserid`}> 
          <OthersChat />
        </Route> 
        <Route exact path={`${path}/othersProfile/:otherUserid`}>
          <OthersProfile />
        </Route>

        <Route exact path={`${path}/AddChapter`}> 
          <AddChapter />
        </Route>

        <Route exact path={`${path}/Privacy`}> 
           <Privacy />
        </Route> 

        <Route exact path={`${path}/Chapter`}> 
           <Chapter />
        </Route> 

        <Route exact path={`${path}/EditChapter/:bookid`}> 
           <EditChapter />
        </Route> 

        <Route exact path={`${path}/ChapterView/:bookid`}> 
           <ChapterView/>
        </Route> 
        <Route exact path={`${path}/WritersForm`}>
          <WritersForm />
        </Route>
        
        <Route exact path={`${path}/ContactForm`}>
          <ContactForm />
        </Route>
                
        <Route exact path={`${path}/HowItWorks`}>
          <HowItWorks />
        </Route>

        <Route exact path={`${path}/Library`}>
          <Library />
        </Route>
        
        <Route exact path={`${path}/PostQuote`}>
          <PostQuote />
        </Route>

        <Route exact path={`${path}/MotherLandForm`}>
          <MotherLandForm />
        </Route>
        
        <Route exact path={`${path}/Africaninventers`}>
        <Africaninventers />
      </Route>

      <Route exact path={`${path}/EditInventorBio`}>
      <EditInventorBio />
      </Route>
      </Switch>
    </>
  );
};

export default Dashboard;
