import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
// import { Button } from "./Button";
import { shallowEqual, useSelector } from "react-redux";
import "./ProfileNav.css";
import { Toggle } from './Toggle';
import { useDarkMode } from '../styles/useDarkMode';
import { GlobalStyles, lightTheme, darkTheme } from '../styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import fire from "../../config/fire";
import { StickyNav } from 'react-js-stickynav'
import 'react-js-stickynav/dist/index.css'
// import "../App";
import Dropdown from './Dropdown';
import { useAuth} from "../../config/fire";


function ProfileNav() {
  const currentUser = useAuth();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  console.log(`path`, path);
  const [photoURL, setPhotoURL] = useState("/images/user.png");

  useEffect(() => {
    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL);
    }
  }, [currentUser])

  //logout User
  const logoutUser = () => {
    fire
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "RESET_USER" });
        toast.success("You are successfully logged out");
        history.push("/admin/dashboard");
      })
      .catch((error) => toast.error(error.message));
  };
  // UserTracker
  
    const { isLoggedIn, user } = useSelector(
      (state) => ({
        isLoggedIn: state.auth.isLoggedIn,
        user: state.auth.user,
      }),
      shallowEqual
    );

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  
  
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const [ theme, toggleTheme ] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);



  return (
    <>
     <body >  
   
      <br/>
      <div className="navp">
        <div className="navbar-containerp " >
          <ul  >
            <li className="nav-itemp">
              <Link
               to="/admin/dashboard/profile"
                className="nav-linksp"
                onClick={closeMobileMenu}
              >
               About
              </Link>
            </li>
            {/* <li className="nav-itemp">
              <Link
               to="/admin/dashboard/OthersChat"
                className="nav-linksp"
                onClick={closeMobileMenu}
              >
              Chats
              </Link>
            </li> */}
            <li className="nav-itemp" >
              <Link to="/admin/dashboard/notification" 
              className="nav-linksp" 
              onClick={closeMobileMenu}>
             Notification
              </Link>
            </li>
            {/* <li className="nav-itemp" >
              <Link to="/admin/dashboard/books" 
              className="nav-linksp" 
              onClick={closeMobileMenu}>
             Following
              </Link>
            </li> */}
          </ul>
        </div>
     </div>

      {/* </header> */}
      </body>
    </>
  );
}

export default ProfileNav;
