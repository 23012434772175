import React,{useEffect} from 'react'

function Books() {

    useEffect(() => {
        window.location.href = "https://oniontable-com.netlify.app/";  }, []);
    return (
        <div>
            <h2>Books</h2>
        </div>
    )
}

export default Books
