import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import Avatar from '@mui/material/Avatar';
// import { Button } from "./Button";
import { shallowEqual, useSelector } from "react-redux";
import "./Navbar.css";
import { Toggle } from './Toggle';
import { useDarkMode } from '../styles/useDarkMode';
import { GlobalStyles, lightTheme, darkTheme } from '../styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import fire from "../../config/fire";
import { StickyNav } from 'react-js-stickynav'
import 'react-js-stickynav/dist/index.css'
// import "../App";
import Dropdown from './Dropdown';
import Dropdowntwo from './Dropdowntwo'
import { useAuth} from "../../config/fire";
import PremiumModal from "../../Components/PremiumModal/PremiumModal";




function Navbar() {
  const currentUser = useAuth();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  console.log(`path`, path);
  const [photoURL, setPhotoURL] = useState("/images/user.png");
  const [show, setShow] = useState(false);
  const ref = fire.firestore().collection("UserAbout");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL);
    }
    if (currentUser?.uid) {
      getData(currentUser.uid)
    }
  }, [currentUser])

  //logout User
  const logoutUser = () => {
    fire
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "RESET_USER" });
        toast.success("You are successfully logged out");
        history.push("/admin/login");
        window.location.reload(false);

      })
      .catch((error) => toast.error(error.message));
  };
  // UserTracker
  
    const { isLoggedIn, user } = useSelector(
      (state) => ({
        isLoggedIn: state.auth.isLoggedIn,
        user: state.auth.user,
      }),
      shallowEqual
    );

    function HomeIcon(props) {
      return (
        <SvgIcon {...props}>
          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
      );
    }

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [dropdown, setDropdown] = useState(false);
  const [dropdowntwo, setDropdowntwo] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  
  // The write MenuOption
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  // The browser menuOption
  const onMouseEntertwo = () => {
    if (window.innerWidth < 960) {
      setDropdowntwo(false);
    } else {
      setDropdowntwo(true);
    }
  };

  const onMouseLeavetwo = () => {
    if (window.innerWidth < 960) {
      setDropdowntwo(false);
    } else {
      setDropdowntwo(false);
    }
  };

  const [ theme, toggleTheme ] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };


  function getData(uid) {

    ref
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          setUserDetails(snapshot.data());
        }
      });
  
}

  const handleClose = () => setShow(false);
  const handlePremium = () => { 
    ref.doc(currentUser.uid).update({ /// others profile uid
      isPremium: true
    });

    setUserDetails({...userDetails,isPremium:true})
    handleClose()
    // setShowPayment(true)
  }
  const handleShow = () => {
      setShow(true);
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);



  return (
    <>
     <body > 
  
     <StickyNav length='navbar globe mobileNav'>
     
        <div className="navbar-container " >
       

          <Link to="/admin/dashboard/Homein" className="navbar-logo" onClick={closeMobileMenu} >
            <img src="/images/logo.png" alt="Logo" width="100" height="50" className="showDesktop" />
            <div className="showMobile">
            <HomeIcon sx={{ fontSize: 40 }} />
            </div>
              </Link>


                                  
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"} >
                                  {/* Users name and Logout B */}
         {isLoggedIn && (
        <div className="nav-user">
          {/* <span className="text-warning">{user.displayName}</span> */}
          <button className="btn btn-primary ml-3" onClick={() => logoutUser()}>
            Logout
          </button>
        </div>
         )}
         
            <li className="nav-item">
              <Link to="/admin/dashboard/Homein"  className="nav-links" onClick={closeMobileMenu}>
              <img src="/images/home.png" alt="Logo" width="80" height="35" />
              </Link>
            </li>
            

            
            <li
                         className='nav-item'
                         onMouseEnter={onMouseEntertwo}
                         onMouseLeave={onMouseLeavetwo}
             >
              <Link 
              to="/admin/dashboard/LanguageButtons"
              className="nav-links" onClick={closeMobileMenu}
              >
             BROWSE <i className='fas fa-caret-down' />
              </Link>
              {dropdowntwo && <Dropdowntwo />}
            </li>
            <li className="nav-item2" >
                  <Link to="/admin/dashboard/Poetry" className="nav-links" onClick={closeMobileMenu}>
               POETRY
                  </Link>
            </li>

            
            {/* <li className="nav-item2" >
                  <Link to="/admin/dashboard/books" className="nav-links" onClick={closeMobileMenu}>
               BOOKS
                  </Link>
            </li> */}


            {/* On the menu */}


            <li className="nav-item2" >
                  <Link to="/posts" className="nav-links" onClick={closeMobileMenu}>
             SHORT STORIES
                  </Link>
            </li>

            <li className="nav-item2" >
            <a href="https://oniontable-premium-portal.vercel.app/account" className="nav-links" onClick={closeMobileMenu}>
               COMPLETE STORIES 
                  </a>
            </li>

            <li className="nav-item">
              <Link
               to="/admin/dashboard/MotherLand"
                className="nav-links"
                onClick={closeMobileMenu}
              >
               MOTHERLAND
              </Link>
            </li>
            

            <li className="nav-item2" >
                  <Link to="/admin/dashboard/PostQuote" className="nav-links" onClick={closeMobileMenu}>
                  PROVERBS/QUOTES
                  </Link>
            </li>
            <li className="nav-item2" >
                  <a href="https://peelingonion.vercel.app" className="nav-links" onClick={closeMobileMenu}>
               PEELING ONION  
                  </a>
            </li>

            
            <li className="nav-item2" >
                  <Link to="/admin/dashboard/Africaninventers" className="nav-links" onClick={closeMobileMenu}>
                  INVENTORS
                  </Link>
            </li>

            <li className="nav-item2" >
                  <Link to="/admin/dashboard/posts" className="nav-links" onClick={closeMobileMenu}>
               MY STORIES
                  </Link>
            </li>

            <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
            to="/admin/dashboard/WritersForm"
              className='nav-links'
              onClick={closeMobileMenu}
            >
              WRITE <i className='fas fa-caret-down' />
            </Link>
            {dropdown && <Dropdown />}
          </li>
          
          
          <li className='nav-item'>
          {isLoggedIn && userDetails.isPremium ?
           <button style={{cursor:'default'}} className=" Fsize Bsize btn btn-primary ml-3 mt-3" onClick={() => console.log("premium")}>
           Premium User
          </button>
          :
          isLoggedIn && userDetails.isPremium == undefined ?
          <button className="Bsize btn btn-primary ml-3 mt-3" onClick={() => handleShow()}>
           <i class="Fsize fa fa-star " aria-hidden="true">  </i>Try Premium
          </button>: 
          isLoggedIn && userDetails.isPremium == false ?
          <button className="Bsize btn btn-primary ml-3 mt-3" onClick={() => handleShow()}>
           <i class="Fsize fa fa-star " aria-hidden="true"> </i>Try Premium
          </button>: ''
          }
          </li>
    


          </ul>

          {/* {button && <Button buttonStyle="btn--outline i"  >SIGN UP</Button>} */}
          
          {/* UserIcon */}

          <Link to="/admin/dashboard/profile" onClick={closeMobileMenu}>
          
                <Avatar
                      className="user3"
                  alt="Remy Sharp"
                  src={photoURL}
                  sx={{ width: 35, height: 35 }}
                />
                {isLoggedIn &&(
              <div className="user5">
             <i class="fas fa-circle"></i>
             </div>
               )}
           </Link>
    

           {isLoggedIn &&(
           <Link to="/admin/dashboard/notification" onClick={closeMobileMenu}>
             <div className="user2">
             <i class="fas fa-bell"></i>
             </div>
             <div className="user4">
             <i class="fas fa-circle"></i>
             </div>
           </Link>

           )}
           
   
 

          {/* DarkMode and LightMode */}
          <ThemeProvider theme={themeMode}>
              <GlobalStyles />
              <Toggle theme={theme} toggleTheme={toggleTheme} />
          </ThemeProvider>
   
                                              {/* Logout B */}
    
      {!isLoggedIn && (
        <Link 
        className="user6"
        to="/admin/login"
        >
          <button buttonStyle="btn--outline" className="i btn btn-primary ml-3" >
            Login
          </button>
        </Link>
         )}

         {isLoggedIn && (
        <div className="nav-logout">
          <button buttonStyle="btn--outline" className="i btn btn-primary ml-3" onClick={() => logoutUser()}>
            Logout
          </button>
        </div>
         )}


        </div>
        
        </StickyNav>
        {/* premium modal */}
        <PremiumModal show={show} handleClose={handleClose} handlePremium={handlePremium}/>
            </body>
    </>
  );
}

export default Navbar;
