import "./WritersForm.css"
import React, { useState, useEffect } from "react";
import fire from "../../config/fire";
import { getAuth } from "firebase/auth";
import { useHistory, Link,  useRouteMatch  } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";


const WritersForm = () => {

  const auth = getAuth();
const getauth = auth.currentUser
  const ref = fire.firestore().collection("WritersPass ");
  const dispatch = useDispatch();


  const [passList, setPassList] = useState({});
  const [pass, setPass] = useState("");
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  async function getData() {
    if (getauth !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = getauth.uid;
      ref
        .doc(uid)
        .get()
      .then((snapshot) => {
        if (snapshot) {
          setPassList(snapshot.data());

        }
      });
    }
  }


 function CheckPass (e) {
  e.preventDefault();
  if(pass <= "")  
  {  
    alert("Invalid password, please signUp to get Writers verification Code")  
    
}else if(`${passList.Password}`.indexOf(pass) !== -1)  
{  
  history.push("/admin/dashboard/addPost");
  
}    
else  
{  
  alert("Invalid password, please signUp to get Writers verification Code")  
}
 }

 useEffect(() => {
  getData();
}, []);


    // Data declaration
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    useEffect(() => {
      fire.auth().onAuthStateChanged((user) => {
        if (user) {
          if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
          if (window.location.pathname === "/admin")
            history.push("/admin/dashboard");
          history.push(window.location.pathname);
        } 
        else {
          history.push("/admin/login");
          
        }
      });
    }, [dispatch]);    

    var viewport = document.querySelector('meta[name=viewport]');
    var viewportContent = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0';
    
    if (viewport === null) {
      var head = document.getElementsByTagName('head')[0];
      viewport = document.createElement('meta');
      viewport.setAttribute('name', 'viewport');
      head.appendChild(viewport);
    }
    
    viewport.setAttribute('content', viewportContent);
  return (
    <div>
    <div className="">
  <div >
    <div className="f_content">
      <div className=" formify_login">
        <div className="container custom_height">
          <div className="row custom_height">
            <div className="col-md-6 formify_content_left">
              <div className="formify_logo_wrapper">
                <a href="index.html">
                  <img src="assets/img/logo.png" alt="" />
                </a>
              </div>
              <img
                className="imgwr"

                src="/images/2022-05-21-15-00-13.png.png" width="900px" height="520px" 
                alt="" style={{marginTop: "-10px"}}
              />
            </div>

            <div className="content_right" style={{ width: "575px", height:"513px", position: "absolute", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
              <div style={{marginLeft: "10px"}}>
                 <div className="form-group" style={{marginTop: "10px"}}>
                   <h2 className="welcome" style={{fontFamily: "sans serif", fontWeight: "bold", color: "black", width: "600px"}}>Welcome <h style={{fontFamily: "sans serif", fontSize: "100px", letterSpacing: "-18px"}}>W</h>riter!</h2> 
                   <div className="input-group input-group-lg bg-white" id="passwr" style={{background: "white"}}>
                        <span className="input-group-text rounded-end text-secondary px-3" style={{background:"white", borderStyle: "solid", borderRadius: "50px", borderColor: "#003d54"}}>
                        <i class="fas fa-lock" style={{color: "#fe3f09", fontSize: "20px"}}></i>
                        </span>
                      <input style={{fontFamily: "sans serif", borderColor: "#003d54"}}
                        type={passwordShown ? "text" : "password"} 
                        className="form-control"
                        maxlength="6"
                        placeholder="Enter Password"
                        required=""
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                      />
                      <span className="input-group-text rounded-end text-secondary px-3" style={{background: "White", borderColor: "#003d54", color:"white"}}>
             <i onClick={togglePassword} class="bi bi-eye-fill" style={{color:"#fe3f09", cursor:"pointer"}}></i>
      </span>
                      <img
                        className="input_icon"
                        src="assets/img/lock.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="logwr" style={{ textShadow: "2px 2px 0px rgba(0,0,0,0.2)", width: "300px", fontWeight: "bold", fontFamily: "Nunito,sans-serif", fontSize: "25px", borderColor: "#003d54", background:"#003d54", color: "white", height: "50px"}}
                      type="submit"
                      onClick={CheckPass}
                     
                    >
                      LOGIN <i class="bi bi-door-open-fill"></i>
                    </button>
                  </div>
                   <div className="position-relative my-4" >
                      <hr style={{marginTop: "40px", color: "#fe3f09", border:"dotted", opacity:"100%", width: "570px", marginLeft: "-22px"}} />
                      <p className="small position-absolute top-50 start-50 translate-middle px-5" style={{marginTop: "0px", fontSize: "18px", background: "#fe3f09", color: "white"}}>
                        Or
                      </p>
                    </div> 

                
                <div className="form-group">
                    <button className="bawr" style={{width:"300px", marginTop: "20px", fontWeight: "bold", fontSize: "25px", borderColor: "#003d54", background: "#003d54", color: "black", height: "50px"}}>
                    <Link
                      type="submit"
                      to="/admin/dashboard/contactForm"
                     style={{color: "white", fontFamily: "Nunito,sans-serif", textShadow: "2px 2px 0px rgba(0,0,0,0.2)",}} 
                    >
                      BECOME A WRITER <i class="bi bi-pencil-fill"></i>
                    </Link></button>
                  </div>
                <form action="#" className="signup_form">
                  <div className="form-group">
                    
                    <div className="input">
                      
                      <img
                        className="input_icon"
                        src="assets/img/at.png"
                        alt=""
                      />
                    </div>
                  </div>

                
                    
                
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default WritersForm;
