import React, { useEffect, useState } from 'react';
import { Link, useHistory , useRouteMatch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import fire from "../../config/fire";
import './home.css';
import Footer from "../../admin/Dashboard/Footer";
import { getPosts } from "../../redux/actionCreators/postsActionCreator";
import { getAuth} from "firebase/auth";


// import LanguageButtons from "../../admin/Dashboard/LanguageButtons";
// import { Skeleton } from "@material-ui/lab";
// import { Grid } from '@material-ui/core';


const Cards = () => {
    const [userDetails, setUserDetails] =useState({});
    const UserAbout = fire.firestore().collection("UserAbout");
    const ref = fire.firestore().collection("NewandHot");
    const ref1 = fire.firestore().collection("FromOurStarts");
    const ref2 = fire.firestore().collection("EditorPicks");
    const ref3 = fire.firestore().collection("BunnerStories");
    const ref4 = fire.firestore().collection("african proverbs");
    const auth = getAuth();
    const user = auth.currentUser;
    // const ref4 = fire.firestore().collection("");

    
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    const [data1, setdata1] = useState([]);
    const [loader1, setloader1] = useState(true);

    const [data2, setdata2] = useState([]);
    const [loader2, setloader2] = useState(true);

    const [data3, setdata3] = useState([]);
    const [loader3, setloader3] = useState(true);

    const [data4, setdata4] = useState([]);
    const [loader4, setloader4] = useState(true);

    // const [data4, setdata4] = useState([]);
    // const [loader4, setloader4] = useState(true);


    function getData(){
        ref
        .orderBy('image','desc')
        .onSnapshot((querySnapshot)=> {
            const items = []
            querySnapshot.forEach((doc)=>{
                items.push(doc.data())
                 
            })
            setdata(items)
            setloader(false)
        })
    }

    function getUserData(){
      if (user !== null) {
        
        // The user object has basic properties such as display name, email, etc.
        // const displayName = user.displayName;
        // const email = user.email;
        // const photoURL = user.photoURL;
        // const emailVerified = user.emailVerified;
      
        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = user.uid;
    
        
        UserAbout
        .doc(uid)
        .get()
        .then((snapshot) =>{
          if(snapshot){
            setUserDetails(snapshot.data());
          }
        });
    
    
       
    
      }
    
    }
    // Get story files from firebase

function get1(){
  ref1
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items = []
      querySnapshot.forEach((doc)=>{
          items.push(doc.data())
           
      })
      setdata1(items)
      setloader1(false)
  })
}

function get2(){
  ref2
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items2 = []
      querySnapshot.forEach((doc)=>{
          items2.push(doc.data())
           
      })
      setdata2(items2)
      setloader2(false)
  })
}

function get3(){
 ref3
 .orderBy('image','desc')
 .onSnapshot((querySnapshot)=> {
      const items3 = []
      querySnapshot.forEach((doc)=>{
          items3.push(doc.data())
           
      })
      setdata3(items3)
      setloader3(false)
      createDoc({ id: user.uid});

  })
}

function createDoc(newDataObj){
  UserAbout
    .doc(user.uid)
    .update( newDataObj)
    .catch((err)=> {
      alert(err)
      console.error(err);
    })

}
function get4(){
  ref4
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
       const items3 = []
       querySnapshot.forEach((doc)=>{
           items3.push(doc.data())
            
       })
       setdata4(items3)
       setloader4(false)
   })
 }
    useEffect(() => {
      getUserData();
        getData();
        get1();
        get2();
        get3();
        get4();
      }, []);
     

     //Home posts const and firebase links
 const { posts, postsLoading, isLoggedIn, userId } = useSelector(
    (state) => ({
      posts: state.posts.posts,
      postsLoading: state.posts.postsLoading,
      isLoggedIn: state.auth.isLoggedIn,
      userId: state.auth.userId,
    }),
    shallowEqual
  );

       //Home posts const and firebase links
const latestPosts = posts;
latestPosts.sort((a, b) => {
    const postA = new Date( a.post.createdAt);
    const postB = new Date( b.post.createdAt);

    if (postA < postB) return 1;
    if (postA > postB) return -1;
    return 0;
  })
  .slice(0, 5);

const dispatch = useDispatch();
useEffect(() => {
  if (postsLoading) {
    dispatch(getPosts());
  }
}, [dispatch]);

      // Data declaration
      const history = useHistory();
      let { path } = useRouteMatch();
    
      useEffect(() => {
        fire.auth().onAuthStateChanged((user) => {
          if (user) {
            if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
            if (window.location.pathname === "/admin")
              history.push("/admin/dashboard");
            history.push(window.location.pathname);
          } 
          else {
            history.push("/admin/login");
            
          }
        });
      }, [dispatch]);
    

    return (
<>
{/* <<LanguageButtons /> */}

<div className='row'>


<div className='rowPosters'>
        {loader3 === false && (data3.map((doc) =>
                                      <Link  to={`/post/${doc.id}/${doc.title}`}>
                                          <img
                                          key={doc.id}
                                            src={doc.image}
                                            alt="---"
                                            className="rowPosterB"
                                          />
                                      </Link>                       

        ))}
</div> 

<div className="movebutton"
      >
     
     <Link exact to="/posts" >
      <div className="herobuttonB">
      Start Reading<i class="fas fa-book-reader"></i>
      </div>
      </Link> 
    
      <Link exact to="/admin/dashboard/WritersForm" >
      <div className="herobuttonB">
      Start Writing<i class="fas fa-keyboard"></i>
      </div>
      </Link> 
      </div> 
      <>
             <div className="changeColortwo  userW"> 
                   Welcome home { userDetails.name} 
                    
               </div>      
      </>
<br />


                    {/* <h2 className="Movetile changeColortwo ">Talk of the Town</h2> */}

<div className='rowPosters'>
        {loader === false && (data.map((doc) =>
        <div>
                                      <Link  to={`/post/${doc.id}/${doc.title}`}>
                                          <img
                                          key={doc.id}
                                            src={doc.image}
                                            alt="---"
                                            className="rowPoster"
                                          />
                                      </Link>     
                                      <br/>    
                                      {doc.category}
        </div>                 

        ))}
</div>



                    <h2 className="Movetile changeColortwo ">Oniontable Starts</h2>

                    <div className='rowPosters'>
                            {loader1 === false && (data1.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link> 
                                                          <br/>    
                                                          {doc.category}
                              </div>                    

                            ))}
                    </div>

                     <h2 className="Movetile changeColortwo ">Editor's picks</h2>

                    <div className='rowPosters'>
                            {loader2 === false && (data2.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>   
                                                          <br/>    
                                                          {doc.category}
                             </div>                  

                            ))}
                    </div>

 
                    <h2 className="Movetile changeColortwo ">New And Hot</h2>

<div className='rowPosters'>
        {latestPosts.map((post, id) => (
        <div>
          <Link to={`/post/${post.postId}/${post.post.title}`}>
                                          <img
                                          key={id}
                                            src={post.post.image}
                                            alt="---"
                                            className="rowPoster"
                                          />
                                      </Link>     
                                      <br/>    
                                      {post.post.category}
        </div>                 

        ))}
</div>

                                                          

</div>
<Footer />

</>

        
    );
};

export default Cards;