import React, { useEffect, useState } from "react";
import { getPosts } from "../../redux/actionCreators/postsActionCreator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Footer from "../../admin/Dashboard/Footer";
import HeroSection from "../../admin/Dashboard/HeroSection";
import "./Cards.css";
import fire from "../../config/fire";
import { getAuth} from "firebase/auth";
import Onionfeatures from "../../admin/Dashboard/Ads/Onionfeatures";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { Skeleton } from "@material-ui/lab";
import LanguageButtons from "../../admin/Dashboard/LanguageButtons";






const Cards = () => {



  const [userDetails, setUserDetails] =useState({});
  const auth = getAuth();
  const user = auth.currentUser;
    const ref = fire.firestore().collection("UserAbout");
    const storyAd1 = fire.firestore().collection("StoryAd1");
    const storyAd2 = fire.firestore().collection("StoryAd2");
    const storyAd3 = fire.firestore().collection("StoryAd3");
    const storyAd4 = fire.firestore().collection("StoryAd4");
  
    const [data1, setdata1] = useState([]);
    const [loader1, setloader1] = useState(true);

    const [data2, setdata2] = useState([]);
    const [loader2, setloader2] = useState(true);

    const [data3, setdata3] = useState([]);
    const [loader3, setloader3] = useState(true);

    const [data4, setdata4] = useState([]);
    const [loader4, setloader4] = useState(true);


 //Home posts const and firebase links
 const { posts, postsLoading, isLoggedIn, userId } = useSelector(
  (state) => ({
    posts: state.posts.posts,
    postsLoading: state.posts.postsLoading,
    isLoggedIn: state.auth.isLoggedIn,
    userId: state.auth.userId,
  }),
  shallowEqual
);


 
function getData(){
  if (user !== null) {
    
    // The user object has basic properties such as display name, email, etc.
    // const displayName = user.displayName;
    // const email = user.email;
    // const photoURL = user.photoURL;
    // const emailVerified = user.emailVerified;
  
    // The user's ID, unique to the Firebase project. Do NOT use
    // this value to authenticate with your backend server, if
    // you have one. Use User.getToken() instead.
    const uid = user.uid;

    
    ref
    .doc(uid)
    .get()
    .then((snapshot) =>{
      if(snapshot){
        setUserDetails(snapshot.data());
      }
    });


   

  }

}

// Story ADs fuctions

function getAd1(){
  storyAd1.onSnapshot((querySnapshot)=> {
      const items = []
      querySnapshot.forEach((doc)=>{
          items.push(doc.data())
           
      })
      setdata1(items)
      setloader1(false)
  })
}

function getAd2(){
  storyAd2.onSnapshot((querySnapshot)=> {
      const items2 = []
      querySnapshot.forEach((doc)=>{
          items2.push(doc.data())
           
      })
      setdata2(items2)
      setloader2(false)
  })
}

function getAd3(){
  storyAd3.onSnapshot((querySnapshot)=> {
      const items3 = []
      querySnapshot.forEach((doc)=>{
          items3.push(doc.data())
           
      })
      setdata3(items3)
      setloader3(false)
  })
}

function getAd4(){
  storyAd4.onSnapshot((querySnapshot)=> {
      const items4 = []
      querySnapshot.forEach((doc)=>{
          items4.push(doc.data())
           
      })
      setdata4(items4)
      setloader4(false)
  })
}


const latestPosts = posts;
latestPosts.sort((a, b) => {
    const postA = new Date( a.post.createdAt);
    const postB = new Date( b.post.createdAt);

    if (postA < postB) return 1;
    if (postA > postB) return -1;
    return 0;
  })
  .slice(0, 5);

const dispatch = useDispatch();
useEffect(() => {
  if (postsLoading) {
    dispatch(getPosts());
  }
}, [dispatch]);

  return (
    
   <div  className="AllPage" >

  <HeroSection/>
  <div className="globe">
    <LanguageButtons />
    <Footer />
    </div>
</div>
 
  
  );
}

export default Cards;