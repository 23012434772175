import React from "react";
// import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";
import { Link } from "react-router-dom";



function HeroSection() {
  
  return ( 
    <div className="hero-container">
      {/* <video src="/images/d.webm" autoPlay loop muted /> */}
      <img
        src="/images/d.jpg"
        alt="Logo"
       
        className="backgb"
      />
      <h1 className="typewriter-text"
      >
        HI, WE'RE<br />ONIONTABLE.
      </h1>
      <p>
      Africas first multilingual social story telling platform.
      </p>
      <div className="hero-btns"
        whileHover={{scale:1.1}}
      >
     
     <Link exact to="/admin/login" >
      <div className="r herobutton">
      Get started <i class="fas fa-book-reader"></i>
      </div>
      </Link> 
      </div>
    </div>
  );
}

export default HeroSection;
