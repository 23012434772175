import React from 'react';
import {Link} from 'react-router-dom'
import './Privacy.css'
function Privacy() {
    function myfunc (e){
        let offsetTop = document.getElementById(e).offsetTop;
        window.scrollTo(
            {
                top: offsetTop-100,
                behavior: "smooth"
            }
        )
    }
    return (
        <div >
            <h1 id='top'>Privacy Policy</h1>
            <br/>
            <br/>
            <div className="P-body"  style={{marginBottom:"5%",margin: '0% 5%'}}>
                <h2>Table of content</h2>
                <hr style={{
                    height: "5px",
                    backgroundColor: "#ccc",
                    border: "none"
                }} />
                <br/>
                <ol>
                    <li id='P-list' onClick={()=> myfunc('div1')}>What information about you do we collect?</li>
                    <li id='P-list' onClick={()=> myfunc('div2')}>How do we use this information?</li>
                    <li id='P-list' onClick={()=> myfunc('div3')}>Deleting your information</li>
                    <li id='P-list' onClick={()=> myfunc('div4')}>Cookies and Similar Technologies</li>
                    <li id='P-list' onClick={()=> myfunc('div5')}>Sharing of Information</li>
                    <li id='P-list' onClick={()=> myfunc('div6')}>Storage and Security of Information</li>
                    <li id='P-list' onClick={()=> myfunc('div7')}>Links to other sites</li>
                    <li id='P-list' onClick={()=> myfunc('div8')}>How do we respond to legal requests?</li>
                    <li id='P-list' onClick={()=> myfunc('div9')}>What are your data protection rights if you are an EU resident?</li>
                    <li id='P-list' onClick={()=> myfunc('div10')}>What are your rights if you are a California resident?</li>
                    <li id='P-list' onClick={()=> myfunc('div11')}>Children Information</li>
                    <li id='P-list' onClick={()=> myfunc('div12')}>What choices do you have in respect of your information and data?</li>
                    <li id='P-list' onClick={()=> myfunc('div13')}>Governing law and Dispute Resolution</li>
                    <li id='P-list' onClick={()=> myfunc('div14')}>Do you have questions or concerns about this Privacy Policy?</li>
                    <li id='P-list' onClick={()=> myfunc('div15')}>Welcoming of suggestions</li>
                </ol>
                <br/>
                <hr style={{
                    height: "5px",
                    backgroundColor: "#ccc",
                    border: "none"
                }}/>
                <br/>
                <div className='mainClass'>
                    <p className='P_para'>
                        This notice describes the privacy policy of <Link to="https://oniontable.com/" style={{margin:"0%",color:""}}>oniontable.com</Link>. 
                        This Privacy Policy is meant to help you understand what information
                        we collect, why we collect it, and how you can update, manage, and 
                        delete your information.
                    </p>
                    <br/>
                    <p className='P_para'>
                        To be more precise, this Privacy Policy explains what information of yours
                        will be collected by us when you access the Website, how the information 
                        will be used, and how you can control the collection, correction, and/or 
                        deletion of the information. We will not knowingly use or share your 
                        information with anyone, except as described in this Privacy Policy. The 
                        use of information collected through Our Site shall be limited to the 
                        purposes described under this Privacy Policy and our Terms of Use.
                    </p>
                    <br/>
                    <br/>
                    <p className='P_para'>
                    By visiting our Site, you are accepting and consenting to the practices 
                    described in this Privacy Policy.
                    </p>

                </div>
                <br/>
                <br/>
            
                {/* Collected info */}
                <div className='mainClass' id="div1">
                    <h2>What information about you do we collect?</h2>
                    <p className='P_para'>
                    <b>a. Information that you provide us:</b> We collect the information you provide when you use our services,
                        including without limitation, when you register for an account, browse our Website, submit your writeup/story,
                        engage with the write-ups/stories, interact with other users (through comments, likes, etc.), or engage with
                        us through any social media platform, provide information, send your query, communicate or interact with us 
                        in any manner.<i> This can include information such as your full name, email address, contact details, location, 
                        your queries, etc.</i> We also collect information about how you use our services, such as the types of content 
                        you engage with or the frequency and duration of your activities.
                    </p>
                    <p className='P_para'>
                    <b>b. Information that we collect automatically when you use the Website:</b> We also collect information while you access, 
                        browse, view or otherwise use the Website. In other words, when you access the Website, we are aware of your usage 
                        of the Website, and gather, collect and record the information relating to such usage.
                    </p>
                    <p className='P_para'>
                    <b>c. Information that we collect from third party sources:</b> You can engage with us through social media websites or mobile
                        applications. When you engage with us through social media sites, such as Instagram or Facebook, you allow us to have 
                        access to certain information from your social media profile based upon your privacy preference settings on such platform.
                    </p>
                </div>
                <br/>
                {/* Legal besis of the info and usage */}
                <div className='mainClass' id="div2">
                    <h2>How do we use this information? (LEGAL BASIS)</h2>
                    <p className='P_para'>
                        We use all of the information we have to help us provide, support and improve our services. We do not sell, rent, loan, trade,
                        lease or license any personal information collected from or provided by you. We might use the information collected from you 
                        for the following purposes:-
                    </p>
                    <p id='points'>a. To enhance your experience of our Website, including as a way to recognize you and welcome you to the Website.</p>
                    <p id='points'>b. To display your write-up or story on the Website;</p>
                    <p id='points'>c. To improve our Site, services, offerings, and your overall experience.</p>
                    <p id='points'>
                        d. To be able to facilitate services requested by you, personalize content, and make suggestions for you by using this 
                        information to understand how you use and interact with our services and the people or things you’re connected to and 
                        interested in on and off our services.
                    </p>
                    <p id='points'>
                        e. We use your information to send you marketing communications, newsletter, communicate with you about our services and let
                        you know about our policies and terms. We also use your information to respond to you when you contact us.
                    </p>
                    <p id='points'>
                        f. We use the information we have to help verify accounts and activity, and to promote safety and security on of our services, 
                        such as by investigating suspicious activity or violations of our terms or policies.
                    </p>
                    <p id='points'>
                        g. We also use your information to ensure our services are working as intended, such as tracking outages or troubleshooting 
                        issues that you report to us. And we use your information to make improvements to our services.
                    </p>
                    <p id='points'>
                        h. We use information to help improve the safety and reliability of our services. This includes detecting, preventing, 
                        and responding to fraud, abuse, security risks, and technical issues that could harm us, our users, or the public.
                    </p>
                </div>

                <br/>
                <br/>

                {/* Deleting info */}
                <div className='mainClass' id='div3'>
                    <h2>Deleting your information</h2>
                    <p className='P_para'>
                        Your information, account, write-up and the comments that you share on our platform is yours. You can at any time delete 
                        the same. However, you acknowledge that we may retain some of the information so deleted for a reasonable period of time 
                        in order to comply with legal requests. You can request us to delete your information by writing to us at <Link to="#" 
                        onClick={()=> window.location = "mailto:contact@oniontable.com"}
                        style={{color:"", margin:"0"}}>contact@oniontable.com</Link>.
                    </p>
                </div>

                <br/>
                <br/>

                {/* Cookies */}
                <div className='mainClass' id='div4'>
                    <h2>Cookies and Similar Technologies</h2>
                    <p className='P_para'>
                        Cookies are bits of electronic information that a website may transfer to a visitor’s computer to identify specific information 
                        about the visitor’s visits to other website. We may use automated technologies including the use of web server logs to collect 
                        IP addresses, device details, cookies and web beacons. The Website uses a browser feature known as a cookie, which assigns a unique 
                        identification to your computer. However, in case you do not wish for us to collect such information, simply change the cookie settings 
                        on your web browser. To understand our cookie policy in more detail, please refer to our detailed Cookie Policy.
                    </p>
                </div>

                <br/>
                <br/>

                {/* Sharing information */}
                <div className='mainClass' id='div5'>
                    <h2>Sharing of Information</h2>
                    <p id='points'>
                        a. We use the services of third-party hosting service provider, namely GoDaddy.com, and therefore your information and data would be
                        shared with them. However, such third-party hosting service provider has security measures in place in order to protect your information.
                    </p>
                    <p id='points'>
                        b. We keep your information safe and do not share your information with any other third party. However, if we merge with or are acquired 
                        by another company or we sell our Website or business unit, or if all or a substantial portion of our assets are acquired by another company, 
                        in those cases, your information will likely be one of the assets that would be transferred.
                    </p>
                    <p id='points'>
                        c. We may also share your information in response to legal request. Please refer to Section 8.
                    </p>
                </div>

                <br/>
                <br/>
                
                {/* storage and security */}
                <div className='mainClass' id='div6'>
                    <h2>Storage and Security of Information</h2>
                    <p id='points'>
                        <b>a. Storage:</b> We store the information collected by us on the database hosted by third-party service provider, namely Google Cloud, but we do not 
                        share it with any others, except for the limited purposes as mentioned in the Section 5
                    </p>
                    <p id='points'>
                        <b>b. Retention: </b>Personal information that we collect, access or process will be retained only so long as necessary for the fulfillment of the purposes 
                        for which it was collected, as necessary for our legitimate business purposes, or as required or authorized by law.<i> Personal information that is no 
                        longer required to fulfil the identified purposes will be destroyed, erased or made de-identified or anonymous.</i>
                    </p>
                    <p id='points'>
                        <b>c. Security:</b> com employs reasonable security practices to ensure that the information is safe and secure. However, no information on the internet is 
                        100% safe, and you accept and acknowledge such risk. Also, we will disclose the information so collected for limited purposes as mentioned in this Privacy Policy
                    </p>
                </div>

                <br/>
                <br/>

                {/* Links to other sites */}
                <div className='mainClass' id='div7'>
                    <h2>Links to other sites</h2>
                    <p className='P_para'>
                    The Website may contain links to third-party websites or advertisements that are not owned or controlled by us. We have no control over, and assume no 
                    responsibility for such websites. Be aware when you leave the Website; we suggest you read the terms and privacy policy of each third-party website that you visit
                    </p>
                </div>
                
                <br/>
                <br/>

                {/* legal request and response */}
                <div className='mainClass' id='div8'>
                    <h2>How do we respond to legal requests?</h2>
                    <p className='P_para'>
                        We may access, preserve and share your information in response to a legal request (like a search warrant, court order or summon) if we have a good faith belief 
                        that the law requires us to do so. This may include responding to legal requests from law enforcement agencies, courts, tribunals and government authorities. 
                        We may also access, preserve and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal 
                        activity; to protect ourselves, you and others, including as part of investigations; or to prevent death or imminent bodily harm. We also may retain information 
                        from accounts disabled for violations of our terms for at least a year to prevent repeat abuse or other violations of our terms.
                    </p>
                </div>

                <br/>
                <br/>

                {/* EU resdential rights */}
                <div className='mainClass' id='div9'>
                    <h2>What are your data protection rights if you are an EU resident?</h2>
                    <p className='P_para'>
                        If you an EU resident, Company would like to make sure you are fully aware of all of your data protection rights as per General Data Protection Regulation (“GDPR”). 
                        As per GDPR, you are entitled to the following:
                    </p>
                    <p id='points'>
                        a. The right to access – You have the right to request us for copies of your personal data. We might charge you a small fee for this service
                    </p>
                    <p id='points'>
                        b. The right to rectification – You have the right to request that us to correct or complete any of your information that you believe is inaccurate or incomplete.
                    </p>
                    <p id='points'>
                        c. The right to erasure – You have the right to request us to erase your personal data, under certain conditions.
                    </p>
                    <p id='points'>
                        d. The right to restrict processing – You have the right to request us to restrict the processing of your personal data, under certain conditions.
                    </p>
                    <p id='points'>
                        e. The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                    </p>
                    <p id='points'>
                        f. The right to data portability – You have the right to request us to transfer the data that we have collected to another organization, or directly to you, under certain conditions
                    </p>
                    <p className='P_para'>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <Link to="#"
                        onClick={()=> window.locaation ="contact@oniontable.com"}
                        style={{color:"",margin:"0"}}>contact@oniontable.com</Link> .
                    </p>
                </div>

                <br/>
                <br/>

                {/* Califonian resdential rights */}
                <div className='mainClass' id='div10'>
                    <h2>What are your rights if you are a California resident?</h2>
                    <p id='points'>
                        a. If you are a California resident, you may request information about our disclosure of personal information to third parties or affiliated companies for their direct 
                        marketing purposes of personal information to third parties or corporate affiliates for their direct marketing purposes.
                    </p>
                    <p id='points'>
                        b. To make such request, please submit a written request specifying that you want a copy of the our “California Privacy Rights” and send it to (enter email). Please include 
                        “Attn: California Privacy” in the subject line. We are not responsible for notices that are not labeled or sent properly, or do not have complete information. Please allow 
                        up to 30 days for us to process your request. You can submit such a request once per year.
                    </p>
                    <p id='points'>
                        c. Non-affiliated third parties are independent from Company and if you wish to receive information about your disclosure choices or stop communications from such third parties, 
                        you will need to contact those non-affiliated third parties directly.
                    </p>
                </div>

                <br/>
                <br/>

                {/* Children info Section */}
                <div className='mainClass' id='div11'>
                    <h2>Children Information</h2>
                    <p className='P_para'>
                        Protecting children’s privacy is important to us, and therefore we don’t knowingly collect any personal information from, such children. If you are not of the age of majority 
                        as per the law of jurisdiction that applies to you, you are not authorized to use the Website or provide us with any of your personal information. If we learn that a child has 
                        provided personally identifiable information to us, we will use reasonable efforts to remove such information from our database.
                    </p>
                </div>

                <br/>
                <br/>

                {/* Choices section */}
                <div className='mainClass' id='div12'>
                    <h2>What choices do you have in respect of your information and data?</h2>
                        <ul style={{list:"25px"}}>
                            <li id='P-list'>
                                You can always choose not to provide certain information, but then you might not be able to take advantage of many of our services.
                            </li>
                            <li id='P-list'>
                                You can add or update certain information. When you update information, we usually keep a copy of the prior version for our records.
                            </li>
                            <li id='P-list'>
                                You can also disable certain cookies.
                            </li>
                            <li id='P-list'>
                                If you want to browse our websites without linking the browsing history to your account, you may do so by logging out of your account here and blocking cookies on your browser.
                            </li>
                        </ul>
                    <p className='P_para'>
                        If you want to browse our websites without linking the browsing history to your account, you may do so by logging out of your account here and blocking cookies on your browser.
                    </p>
                </div>

                <br/>
                <br/>

                {/* Governing Law */}
                <div className='mainClass' id='div13'>
                    <h2>Governing law and Dispute Resolution</h2>
                    <p className='P_para'>
                        In case of any disputes, issues, claims or controversies arising out of or in relation to your use of the Website, or our services, the governing law and dispute resolution mechanism 
                        as provided in the Terms of Use shall apply to this Privacy Policy statement as well.
                    </p>
                </div>
                <br/>
                {/* Questions/Concerns section */}
                <div className='mainClass' id='div14'>
                    <h2>Do you have questions or concerns about this Privacy Policy?</h2>
                    <p className='P_para'>
                        As per the legislation of most of the jurisdiction that we operate in, we are mandated to appoint a Grievance Officer (or a similar officer) in order to address the user complaints.
                    </p>
                    <p className='P_para'>
                        Therefore, in the event you have any grievance regarding anything related to these Terms or Privacy Policy, or with any content of Company, in that case you may freely address or write 
                        your concerns to the Grievance Officer to:- <Link to="#" onClick={()=> window.location='mailto:contact@oniontable.com'}
                            style={{marginLeft:"0%",color:""}}>contact@oniontable.com</Link>
                    </p>
                    {/* Questions/concerns form */}
                    {/* <form className='P-form' id=''>
                        <label>Name</label>
                        <br/>
                        <input type="text" id="name" placeholder='Name' />
                        <br/>
                        <br/>
                        <label>Contact</label> 
                        <br/>
                        <input type="text" id='phone' placeholder='Phone Number'/>
                        <br/>
                        <br/><
                        <label>Email</label>
                        <br/>
                        <input type="email" id='email' placeholder='Email'/>
                        <br/>
                        <br/>
                        <input type='button' id='send-btn' value="SEND" 
                            style={{backgroundColor:"hotpink",textAlign:"center",width:"95%",color: "#fff"}}/>
                    </form> */}
                
                </div>
                <br/>
                <div className='mainClass' id='div15'>
                    <h2>Welcoming of suggestions</h2>
                    <p className='P_para'>
                        We welcome your comments regarding this Privacy Policy. Please write to us at <Link to="#"
                        onClick={()=> window.location = "contact@oniontable.com"}
                        style={{margin:"0",color:""}}>contact@oniontable.com</Link>.
                    </p>
                </div>
                <div>
                    <button id='top-btn'
                    onClick={() => myfunc("top")}
                    >Top</button>
                    
                </div>
            </div>
            <div className='P-footer' >
                <Link to="/" id='link'>contact us</Link>
                <Link to="/admin/dashboard/HowItWorks" id='link'>How it works</Link>
                <Link to="/admin/dashboard/About" id='link'>About us</Link>
                <Link to="/"  id='link'>Home</Link>
            </div>
        </div>
    )
}

export default Privacy;
