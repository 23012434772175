import React, {useState, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import "./Chapter.css"
import fire from "../../config/fire";
import { shallowEqual, useDispatch, useSelector } from "react-redux";



function Chapter() {
    const ref = fire.firestore().collection("Books");
  
    const ref1 = fire.firestore().collection("-FromOurStarts"); 
    const history = useHistory();


    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    const [data1, setdata1] = useState([]);
    const [loader1, setloader1] = useState(true);

    const [data2, setdata2] = useState([]);
    const [loader2, setloader2] = useState(true);

    const [data3, setdata3] = useState([]);
    const [loader3, setloader3] = useState(true);

    // const [ uid, setUid] = useState({});
// Get story files from firebase

function get(){
    ref
    .onSnapshot((querySnapshot)=> {
        const items = []
        querySnapshot.forEach((doc)=>{
            items.push({ ...doc.data(), id: doc.id })
        })            
        setdata(items)
        setloader(false)
    })
  }

function deleteDoc(docx){
ref.doc(docx.id).delete()
}
  function get1(){
    ref1
    .onSnapshot((querySnapshot)=> {
        const items = []
        querySnapshot.forEach((doc)=>{
            items.push({ ...doc.data(), id: doc.id })
             
        })
        setdata1(items)
        setloader1(false)
    })
  }

  useEffect(() => {
    get();
    get1();
  }, []); 
  const dispatch = useDispatch();

       // Data declaration
       const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

       useEffect(() => {
        fire.auth().onAuthStateChanged((user) => {
          if (user) {
            if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
            if (window.location.pathname === "/admin")
              history.push("/admin/dashboard");
            history.push(window.location.pathname);
          } 
          else {
            history.push("/admin/login");
            
          }
        });
      }, [dispatch]);  

      
  return (
    <div className='body '>

        <div className='addButton shadow '>
                {/* <i class="fa-solid fa-plus"></i> */}
                <Link to="/admin/dashboard/AddChapter">
                <span className='plusL'> + </span>
                </Link>
                <br/>
                <span  className='plusTxt'>Add Books</span>
        </div>
 
        <div className='bookSlides shadow'>
        {/* <h2 className="Movetile changeColortwo ">Talk of the Town</h2> */}

<div className='rowPosters'>
        {loader === false && (data.map((doc) =>
        <div>
                                      <Link  to={`/admin/dashboard/ChapterView/${doc.id}`}>
                                          <img
                                          key={doc.id}
                                            src={doc.image}                                         
                                            className="rowPoster"
                                          />
                                      </Link>     
                                      <br/>  
                                      <div className='PriceButton shadow '><Link  to={`/admin/dashboard/ChapterView/${doc.id}`}>{doc.price}$</Link></div>                                  
                                      {doc.category}
<br />

<i class="fa fa-trash dati" style={{fontSize:"16px"}}  onClick={() => deleteDoc(doc)}></i> 
{/* <i class="fa fa-keyboard dati" style={{fontSize:"16px"}}  onClick={() => deleteDoc(doc)}></i>  */}


{/* <i class="fa-solid fa-keyboard"></i> */}
                                      {/* <button className="btn btn-primary ml-3" onClick={() => deleteDoc(doc)}>Delete</button> */}
        </div>                 

        ))}
</div>


        {/* <h2 className="Movetile changeColortwo ">Talk of the Town</h2> */}

        {/* <div className='rowPosters'>
        {loader1 === false && (data1.map((doc) =>
        <div>
                                      <Link  to={`/admin/dashboard/ChapterView/${doc.id}`}>
                                          <img
                                          key={doc.id}
                                            src={doc.image}
                                            alt="---"
                                            className="rowPoster"
                                          />
                                      </Link>     
                                      <br/>    
                                      {doc.category}
                                      <br />

<i class="fa fa-trash dati" style={{fontSize:"16px"}}  onClick={() => deleteDoc(doc)}></i> 
        </div>                 

        ))}
</div> */}
        </div>


       

    </div>
  )
}

export default Chapter