import React from 'react'
import { Link } from 'react-router-dom';
import './About.css'

function About() {
    var text1='', text2='', text3='',text4="",text5="",text6="";
    var li1 ='',li2='',li3='';
    const aboutInfo = [
        text1="We are Africa's first multilingual social storytelling platform",
        text2="We pride ourselves in crafting stories from all walks of life. We work like bees on a hive to bring out sweet stories, which are in harmony like a symphony. Our stories are full of suspense and always awaken readers to desire for more content.",
        text3="Oniontable was initially founded in July 2021. Its development was based on the idea of sharing Cultural literature in various African Languages. Oniontable was therefore created as a pan African platform with content written in Multiple African Languages.",
        text4="The platform is user friendly and anyone can sign up and become a writer.",
        text5="We believe in turning stories into movies. If you need a publisher, we are the answer to your publishing needs both for digital and print media.",
        // lists data
        li1="",
        li2="",
        li3=""
    ]
    return (
        <div className='about-main '>
            <h1 className='btn-primary'>About Us</h1>
            <div  className='about-container'>
                <div className='about-header'>
                    <div className='about-paragraph'>
                        <h3>{text1}</h3>
                        <p className='A_para'>{text2}</p>
                        <p className='A_para'>{text3}</p>
                        <p className='A_para'>{text4}</p>
                        <p className='A_para'>{text5}</p>
                    </div>
                    <img id='img1' src="/images/c.jpg"/>
                </div>
                <br/><br/><br/>
                <div className='about-goals'> 
                    <h3>Goals</h3>
                    <div className='goals-list'>
                        <p className='A_para'>To allow writers to tell their stories in their native languages that can be read by people from their country.</p>
                        <p className='A_para'>To share the history of African countries with the rest of the world.</p>
                        <p className='A_para'>To collaborate with people through sharing stories.</p>
                    </div>
                </div>
                <img id='img2' src='/images/c1.jpg'/>
                <br />
                <br />
            </div>
            <div className='about-footer btn-primary' >
                <div className='about-footer2'>
                    {/* <Link to="/" id='link'>contact us</Link> */}
                    {/* <Link to="/admin/dashboard/HowItWorks" id='link'>How it works</Link> */}
                    {/* <Link to="/admin/dashboard/About" id='link'>About us</Link> */}
                    {/* <Link to="/"  id='link'>Home</Link> */}
                    <br/>
                </div>
                <h6 style={{position:"relative", paddingBottom:"2%"}}>Copyright 2021 - All Rights Reserved | www.oniontable.com</h6>   
            </div>
        </div>
    )
}

export default About;
