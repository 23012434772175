import React,{useState,useEffect} from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { newPost } from "../../redux/actionCreators/postsActionCreator";
import "./AddPost.css";
import {  useHistory, useRouteMatch } from "react-router-dom";
import fire from "../../config/fire";
import { ProgressBar } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react'; 
import parse from 'html-react-parser';
import { serverTimestamp } from "firebase/firestore";
import DatePicker from 'react-date-picker';
import {Modal,Button,Offcanvas} from "react-bootstrap"
function FullStoryAdd() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [disable, setDisable] = React.useState(false);
  const userId = useSelector((state) => state.auth.userId);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [date, setDate ] = useState("");
  const [language, setLanguage] = useState ("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [progress, setProgress] = useState(0);

  function refreshPage() {
    window.location.reload(false);
  }


  const history = useHistory();
  let { path } = useRouteMatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  

  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
        if (window.location.pathname === "/admin")
          history.push("/admin/dashboard");
        history.push(window.location.pathname);
      } 
      else {
        history.push("/admin/login");
        
      }
    });
  }, [dispatch]);


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!title || !date || !language || !category || !description) {
      return toast.warning("Please fill in all fields!");
    }

    if (!image || image === undefined) {
      return toast.warning("Please select an image!");
    }

    if (description.length < 100) {
      return toast.info("Description should be of atleast 100");
    }
    if (title.trim().split(" ").length < 2) {
      return toast.info("Title should be of atleast 2 words");
    }
    const data = {
      language,
      timestamp: serverTimestamp(),
      date,
      title,
      category,
      description,
      image,
    };

    dispatch(newPost(data, userId, user.displayName, setProgress));
    setDisable(true);
  };

  const handleEditorChange = (e) => {
    setDescription(
    e.target.getContent()
    );
  }
  
    return (
      <>
        <Button variant="primary" onClick={handleShow}>
          Add Story
        </Button>
  
        <Offcanvas show={show} onHide={handleClose} style={{width:"100%"}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Story With Chapters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
          <div className="container" style={{marginTop:"-13%"}}>
      <div className="row w-100">
        <div className="col-md-12 my-5 text-right">
         
        </div>
        <div className="col-md-12 mb-3">
          <h1 className="display-3 text-dark text-center">Add Story</h1>
        </div>
        <div className="col-md-10 mx-auto mb-5 shadow p-5" >
          {progress ? (
            progress !== 100 ? (
              <div className="mx-auto p-5">
                <h1 className="text-center my-2">
                  Uploading Story - {progress}%
                </h1>
                <ProgressBar now={progress} max={100} animated/>
                <progress
                  className="text-center form-control"
                  max={100}
                  value={progress}
                ></progress>
              </div>
            ) : (
              <div className="mx-auto p-5   text-center ">
                <i className="fa fa-tick text-success mx-auto my-2"></i>
                <h1 className="text-center my-2">Story Uploaded successfully</h1>
                <Link
                  to={"/admin/dashboard/addPost"}
                  onClick={refreshPage}
                  className="my-2 mx-auto btn btn-primary"
                >
                 Finish
                </Link>
              </div>
            )
          ) : (
            <form onSubmit={handleSubmit} className="writerform" >
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="date"
                  placeholder=".../.../2022"
                  className="form-control"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>


          <div className="form-group">
          <label>
          Languages and File-Types:
          <select value={language}  onChange={(e) => setLanguage(e.target.value)}>            
            <option value="none">Select here</option>
            <option value="SWAHILI">SWAHILI</option>
            <option value="BEMBA">BEMBA</option>
            <option value="NewandHot">New and Hot</option>
            <option value="EditorPicks">Editor's Picks</option>
            <option value="FromOurStarts">From Our Stars</option>
            <option value="StoryAd1">StoryAd1</option>
            <option value="StoryAd2">StoryAd2</option>
            <option value="StoryAd3">StoryAd3</option>
            <option value="StoryAd4">StoryAd4</option>
            <option value="Adventure">Adventure</option>
            <option value="Genre">Genre</option>
            <option value="Action">Action</option>
            <option value="Fantasy">Fantasy</option>
            <option value="Historical Fiction">Historical Fiction</option>
            <option value="Poetry">Poetry</option>
            <option value="Diary">Diary</option>
            <option value="Fiction">Fiction</option>
            <option value="Action and adventure">Action and adventure</option>
            <option value="Alternate history">Alternate history</option>
            <option value="Autobiography">Autobiography</option>
            <option value="Biography">Biography</option>
            <option value="Comic book">Comic book</option>
            <option value="BunnerStories">Top bunner Stories</option>

          </select>
        </label>
              </div>
              <div className="form-group">
          <label>
          Category:
          <select value={category}  onChange={(e) => setCategory(e.target.value)}>            
            <option value="none">Select here</option>
            <option value="Genre">Genre</option>
            <option value="Adventure">Adventure</option>
            <option value="Action">Action</option>
            <option value="Art/architecture">Art/architecture</option>
            <option value="Fantasy">Fantasy</option>
            <option value="Historical Fiction">Historical Fiction</option>
            <option value="Poetry">Poetry</option>
            <option value="Nonfiction">Nonfiction</option>
            <option value="Fiction">Fiction</option>
            <option value="Action and adventure">Action and adventure</option>
            <option value="Alternate history">Alternate history</option>
            <option value="Autobiography">Autobiography</option>
            <option value="Biography">Biography</option>
            <option value="Chick lit">Chick lit</option>
            <option value="Business/economics">Business/economics</option>
            <option value="Children's">Children's</option>
            <option value="Crafts/hobbies">Crafts/hobbies</option>
            <option value="Classic">Classic</option>
            <option value="Cookbook">Cookbook</option>
            <option value="Comic book">Comic book</option>
            <option value="Diary">Diary</option>
            <option value="Coming-of-age">Coming-of-age</option>
            <option value="Dictionary">Dictionary</option>
            <option value="Crime">Crime</option>
            <option value="Encyclopedia">Encyclopedia</option>
            <option value="Drama">Drama</option>
            <option value="Guide">Guide</option>
            <option value="Fairytale">Fairytale</option>
            <option value="Health/fitness">Health/fitness</option>
            <option value="History">History</option>
            <option value="Graphic novel">Graphic novel</option>
            <option value="Home and gadenr">Home and gadenr</option>
            <option value="Historical fiction">Historical fiction</option>
            <option value="Humor">Humor</option>
            <option value="Journal">Journal</option>
            <option value="Mystery">Mystery</option>
            <option value="Math">Math</option>
            <option value="Paranormal romance">Paranormal romance</option>
            <option value="Memoir">Memoir</option>
            <option value="Picture book">Picture book</option>
            <option value="Philosophy">Philosophy</option>
            <option value="Poetry">Poetry</option>
            <option value="Political thriller">Political thriller</option>
            <option value="Religion">Religion</option>
            <option value="Romance">Romance</option>
            <option value="Textbook">Textbook</option>
            <option value="Satire">Satire</option>
            <option value="True crime">True crime</option>
            <option value="Science fiction">Science fiction</option>
            <option value=">Review">Review</option>
            <option value="Short story">Short story</option>
            <option value="Action and Science">Science</option>
            <option value="Suspense">Suspense</option>
            <option value="Self hele">Self help</option>
            <option value="Sports and leisure">Sports and leisure</option>
          </select>
        </label>
              </div>
              <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange}
      />


              </div>
              <div className="form-group">
               <div className="Form-label">Select Story Cover: In JPG</div>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setImage(e.target.files[0])}
                  accept="image/jpeg, image/jpg"
                />

              </div>
              <div className="form-group">
                <input
                  type="submit"
                  className="btn btn-dark btn-block"
                  value={disable ? 'Loading...' : 'Add Story'}
                  disabled={disable} 
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
    </Offcanvas.Body>
    </Offcanvas>
      </>
    );
  }
  
  export default FullStoryAdd;