import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from "react-toastify";
import { auth } from "../../config/fire"
import "./ForgotPass.css"


  const ForgotPass = () => {
  
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true)

      const config = {
        url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT,
        handleCodeInApp: true,
      };

      await auth
      .sendPasswordResetEmail(email, config)
      .then(() => {
        setEmail('')
        setLoading(false)
        toast.success('check your email on the Spam section for password rest link')
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        console.log("ERROR MSG IN FORGOT PASSWORD", error);
      });
    };
  
    var viewport = document.querySelector('meta[name=viewport]');
  var viewportContent = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0';
  
  if (viewport === null) {
    var head = document.getElementsByTagName('head')[0];
    viewport = document.createElement('meta');
    viewport.setAttribute('name', 'viewport');
    head.appendChild(viewport);
  }
  
  viewport.setAttribute('content', viewportContent);
  
    return (
      <>
 <img src="/images/3227472.jpg" width="620px" height="550px" style={{marginTop: "35px", marginLeft: "25px"}}  className= "Forgotimg" alt=""/>
    <div className="fotgotcontent">
 {loading ? (
   <h4 className='text-danger'>loading</h4>
 ) : (
  <h4  className="headf" style={{fontFamily: "Bebas Neue", fontWeight:"bold"}}>
  FORGOT PASSWORD?
</h4>
 )}
      <div maxW='md' mx='auto' mt={4}>
        <form
          onSubmit={handleSubmit}
        >
           <div className="input-group" id="emailF" style={{background:"white"}}>
                        <span className="input-group-text rounded-start " style={{background:"white", borderStyle: "solid", borderColor: "#EF4343"}}>
                          <i className="bi bi-envelope-fill" style={{color: "#EF4343"}}/>
                        </span>
      
              <input style={{borderColor: "#EF4343", fontFamily: "calibre", background: "white"}}
                className="form-control rounded-end shadow-none"
                placeholder="E-mail"
                name='email'
                type='email'
                autoComplete='email'
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <button style={{width: "200px", marginTop: "10px", fontWeight: "bold", fontFamily: "Nunito,sans-serif", fontSize: "22px", borderColor: "#EF4343", background: "#EF4343", color: "white", height: "50px", borderRadius: "50px"}}  className='btn btn-raised' disabled={!email} type='submit' id="forgotbut" size='lg' fontSize='md'>
              Reset password
            </button>
        </form>
        <div className="position-relative my-4" >
                      <hr className="hrf" style={{marginTop: "6px", color: "black"}} />
                      <p className="small position-absolute top-50 translate-middle bg-body px-5" id="forgotor" style={{ marginTop: "0px", fontSize: "15px", color: "#2d2d2d", fontWeight: "bold", fontFamily: "Nunito"}}>
                        OR
                      </p>
                    </div>
        <div>
          <button className='logf' style={{ marginTop: "10px", fontWeight: "bold", fontFamily: "Nunito,sans-serif", fontSize: "20px", borderColor: "#EF4343", background: "#EF4343", color: "white", height: "50px", borderRadius: "50px", width: "200px"}} variant='link' onClick={() => history.push('/admin/login')}>
            Login
          </button>
        </div>
      </div>
    </div>
    </>
);
};

export default ForgotPass;