// import { height } from '@mui/system';
import React, { useEffect, useState } from 'react';
import fire from '../../config/fire';
import parse from 'html-react-parser';
import './AfricanInventors.css';
import EditInventorBio from './EditInventorBio';
import { useDispatch, useSelector } from "react-redux";
// import Select from 'react-select';
// import {countrylist} from './MotherLand';


function AfricanInventors(){
    const userId = useSelector((state) => state.auth.userId);
    const [data, setData] = useState([]);
    // const [code, setCode] = useState([]);
    const [loading, setLoading] = useState();
    // const [selected,selectedValue] = useState(countrylist.label);
    const [altImg, setAltImg] = useState('/images/user.jpg');

    const types = ['image/png','image/jpeg'];
    var id = 'c590cca6-f24f-4186-9c0c-9fd38e72478e';
    const initail ="";

    
    // var isSelected = selected;
    // const selectedhandler = e => {
    //     selectedValue(e.value);
    // }
    //     if(isSelected){
    //         var code= isSelected;
    //     }
    //     else
    //     code=initail;

    // functior for profile image for the inventor, also places dummy image if none is supplied
    const ProfileImage = (pic) =>{
        
        if(pic !== ''|| undefined )
        {
            return <img src={pic || '/images/user.jpg'} alt='' className='inventor-pic'/>
        }else{
            return <img src={''} alt='' className='inventor-pic'/>
        }
    }
    // function for images to appear i the paragraphs
    const paraImage=(e)=>{
        if(e !== undefined)
        {
            return <img src={e} alt='' className='para-pic' />
        }
        else{
            return <img src={''} alt='' className='alt-para-pic' />
        } 
    }
   
    useEffect(()=>{
        // function to get data from fire firestore
        function dataFromFb(){
            const bioRef = fire.firestore().collection('AfricaInventors');
            bioRef.get().then((item)=>{
                const items = item.docs.map((doc)=>doc.data());
                setData(items);
            })
        }
        dataFromFb();
    },[])
    
    function edBio(e){
        // var id = 'c590cca6-f24f-4186-9c0c-9fd38e72478e';
       console.log(e);
        { e && <EditInventorBio bioId={e} />}
    }
    return (
        <div className='main-box'>
            {/* Button to add another biography */}
            <div className='add-bio'>
                <button onClick={()=>{
                    window.location='/admin/dashboard/AddInventorBio'
                }}>Add Bio</button>
                {/* <i class="fa fa-filter" aria-hidden="true"></i> */}
                {/*<h3>Filter: </h3>
                   <Select 
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 4,
                      colors: {
                        ...theme.colors,
                        primary25: '#fff',
                        primary: '#fff',
                        neutral0: ' #dd4b39',
                      },
                    })}
                 options={countrylist}  onChange={selectedhandler}/> */}
            </div>
                { data && data.length > 0 ? data.map((data)=>( 
                    <div key={data.id}>
                            {data.id && edBio(data)}
                        <div className='inventor-container'>
                            {/* function call to display the inventors information */}
                            <div className='bio-display'>
                            <div className='inventor-class'>
                                    <div className='inventor-bg'>
                                         <div className='avator-class'>
                                            {/* profile image function called */}
                                            {ProfileImage(data.image)}
                                            <div className='inventor-title'>
                                                    <h1>{data.name}</h1>
                                                    <h2>{data.profession } </h2>
                                            </div>
                                        </div>
                                        {userId === data.authorID && 
                                        
                                        <div className='edit-bio'>
                                        {/* <button  
                                                onClick={()=>{
                                                    
                                                    window.location='/admin/dashboard/EditInventorBio';
                                                }}
                                                
                                                >Edit Bio</button>  */}
                                                
                                        </div>
                                        }
                                    </div>
                                    {/* container to store manage the inventors personal information and works that the inventor did */}
                                    <div className='inventor-details'>
                                        <div className='details-persona'>
                                            <h3>Personal Information</h3>
                                            <h6>Born: {data.birthdate }</h6>
                                            <h6>Died: {data.deathdate ? data.deathdate : 'Present' }</h6>
                                            <h6>Country of Origin: {data.origin }</h6>
                                        </div>
                                        {/* inventors life paragraph */}
                                        <div className='details-para'>
                                            <h3>{data.name}'s life</h3> 
                                            <div id='inventor-life' >    
                                                    {paraImage(data.paraImage1)}
                                                    <h5><i>{data.img1Desc}</i></h5>
                                                <p>
                                                    {parse(data.life )}
                                                </p>
                                            </div>                       
                                        </div>

                                        {/* inventors work and inventions paragraph */}
                                        <div className='details-para'>
                                            <h3>{data.name }'s work and inventions</h3> 
                                            <div id='inventor-work'>
                                                    {paraImage(data.paraImage2)}
                                                    <h5><i>{data.img2Desc}</i></h5>
                                                <p>
                                                    {parse(data.work )}
                                                </p>
                                            </div>                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : <div style={{textAlign:'center',margin:'4% 0',padding:'5% 0'}}> <i class=' text-muted mt-3 ml-4 '>Click on Add Bio to add a new biography...</i> </div>}
      </div>
  )
}
export default AfricanInventors;