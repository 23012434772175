import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPosts } from "../../redux/actionCreators/postsActionCreator";
import PostCard from "./PostCard";
import { useHistory, useRouteMatch } from "react-router-dom";
import fire from "../../config/fire";

//  My Stories Page Which Calls the Post Cards to display the Card and deitals

const Posts = () => {
  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
        if (window.location.pathname === "/admin")
          history.push("/admin/dashboard");
        history.push(window.location.pathname);
      } 
      else {
        history.push("/admin/login");
        
      }
    });
  }, [dispatch]);
  const { postsLoading, allPosts, userId } = useSelector(
    (state) => ({
      postsLoading: state.posts.postsLoading,
      allPosts: state.posts.posts,
      userId: state.auth.userId,
    }),
    shallowEqual
  );
  const posts = allPosts.filter((post) => post.post.author === userId && post);
  useEffect(() => {
    if (postsLoading) {
      dispatch(getPosts());
    }
  }, [dispatch]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 my-5 text-right">
          <Link to="/" className="btn btn-dark mr-2">
            Go Back
          </Link>
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center">
        {postsLoading ? (
          <h1 className="text-center">Loading Oniontable Stories...</h1>
        ) : posts.length > 0 ? (
          <>
            {posts.map((post, id) => (
              <PostCard post={post} key={id} id={id} />
            ))}
          </>
        ) : (
          <h1 className="text-center">
            You haven't uploaded any Stories{" "}
            <Link to="/admin/dashboard/WritersForm" className="ml-2">
              Write a Story
            </Link>
          </h1>
        )}
      </div>
    </div>
  );
};

export default Posts;
