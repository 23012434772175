/* eslint-disable jsx-a11y/alt-text */
import {useState,useEffect} from "react";
import {  deleteDoc,doc,setDoc,onSnapshot, collection, query, orderBy } from "@firebase/firestore";
import { db } from "../../config/fire";
import "./QuotePage.css"
import {  useSelector } from "react-redux";
import Moment from "react-moment";
import { RWebShare } from "react-web-share";
import {Card} from "react-bootstrap"
import {Tab} from "react-bootstrap"
import {Tabs} from "react-bootstrap"

const QuotePage = ({id, post, postPage }) => {
  const [likes, setLikes] = useState([]);
  const [liked, setLiked] = useState(false);
  const userId = useSelector((state) => state.auth.userId);

  useEffect(
    () =>
      onSnapshot(collection(db, "proverbs", id, "likes"), (snapshot) =>
        setLikes(snapshot.docs)
      ),
    [db, id]
  );

  useEffect(
    () =>
      setLiked(
        likes.findIndex((like) => like.id === userId) !== -1
      ),
    [likes]
  );

  const likePost = async () => {
    if (liked) {
      await deleteDoc(doc(db, "proverbs", id, "likes", userId));
    } else {
      await setDoc(doc(db, "proverbs", id, "likes",userId), {
        userId,
      });
    }
  };
  var viewport = document.querySelector('meta[name=viewport]');
var viewportContent = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0';

if (viewport === null) {
  var head = document.getElementsByTagName('head')[0];
  viewport = document.createElement('meta');
  viewport.setAttribute('name', 'viewport');
  head.appendChild(viewport);
}

viewport.setAttribute('content', viewportContent);

  return (
<>



<figure class="figure p-3 ">
  <img src={post?.image} className="imagx  bg-dark shadow-lg rounded-2xl max-h-[700px] object-cover mr-2"  
  style={{width:"300px",height: "350px",marginBottom:"3%",marginLeft:"60%"}} >

  </img>
  

    <Card style={{ width: '54rem' }}>

    
    <Card.Text  >
    <figcaption className="figure-caption text-light " 
    style={{marginLeft:"70%"}}
    >
   {post?.text}  {post?.quote_writer}
    </figcaption> 


    </Card.Text>
   
   
</Card>
    
    
        <div className="Move">
          
        {userId=== post?.id ? (  
          <button className="button">
    <svg 
     
   className="button"
    onClick={(e) => {
      e.stopPropagation();
      deleteDoc(doc(db, "proverbs",id));}}
    xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi-trash3 " viewBox="0 0 16 16">
  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
</svg>
</button>
  ) : (
    <></>
  )}




          <button class="button"  
  onClick={(e) => {
              e.stopPropagation();
              likePost();
            }}>
        
          {liked ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill text-danger"  viewBox="0 0 16 16">
                <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                 </svg>

) : (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill "  viewBox="0 0 16 16">
  <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
   </svg>
 )}

{likes.length > 0 && (
                 <span className={`likes group-hover:text-pink-600 text-sm  ${
                  liked && "text-pink-600"}`}
                 
                  >
                         {likes.length}
                 </span>
                 )}
                 </button>

                
          <button class="button"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-chat-quote-fill" viewBox="0 0 16 16">
          <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM7.194 6.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 6C4.776 6 4 6.746 4 7.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 9.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 6c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
          </svg></button>
          
          <RWebShare
        data={{
          text: "Join The Family Of OnShare & Share What You Have!",
          url: "https://oniontable.com/",
          title: "Share Post",
        }}
        onClick={() => console.log("shared successfully!")}
      >
          <button class="button"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" class="bi bi-arrow-up-right-circle-fill" viewBox="0 0 16 16">
  <path d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0 8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"/></svg></button>
  </RWebShare>
</div>
             <footer className="footer1 ">
             <Moment fromNow>{post?.timestamp?.toDate()}</Moment>
            </footer>
</figure>




</>
  );
};


export default QuotePage;
