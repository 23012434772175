import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './CountryImgs.css';

const Countryimgs = ({code,imgs}) => {
  
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        margin: 0,
        autoplay: true,
        dots: false,
        autoplayTimeout: 9500,
        smartSpeed: 250,
        nav: true,
        navText:["<i class='fa fa-chevron-circle-left' aria-hidden='true'></i>",
                 "<i class='fa fa-chevron-circle-right' aria-hidden='true'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };
    return (
        <section id="testimonia" className="">
            <div className="CID-container mt-5 m">
                <div className="ro ">
                    {/* col-md-12 */}
                    <div className="">
                        {/* customer-testimonials/owl-carousel owl-theme */}
                        <OwlCarousel id="" className="" {...options}>
                            {
                                    imgs.filter((images) => images.id === code).map(images => { console.log(images)
                                        return (
                                            <div class="CID-img-box" key={images._key}>  
                                                    <img class="CID-img" src={images.imgURL} alt='Please wait...'/>
                                            </div>  

                                        )
                                    })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Countryimgs;