import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: 'Roboto', sans-serif;

  }
  .user1{
    position: absolute;
    top: 0.5rem;
    right: 9.5rem;
    transition: all .5s linear;
    
  }
  .user2{
    position: absolute;
    top: 0.3rem;
    right: 7.0rem;
    font-size: 29px;
    transition: all .5s linear;
    
  }
  .user3{
    position: absolute;
    top: 0.5rem;
    right: 9.5rem;
    font-size: 25px;
    transition: all .5s linear;
    
  }
  
  .user4{
    position: absolute;
    top: 0.8rem;
    right: 7.2rem;
    font-size: 9px;
    transition: all .5s linear;
    color: red
    
  }
  .user5{
    position: absolute;
    top: 2.2rem;
    right: 9.5rem;
    font-size: 10px;
    transition: all .5s linear;
    color:#0ebf0a;
    
  }
    .user3{
    position: absolute;
    top: 0.6rem;
    right: 9.5rem;
    font-size: 25px;
    transition: all .5s linear;
    
  }
  .user6{
    position: absolute;
    top: 0.2rem;
    right: 8.5rem;
    font-size: 29px;
    transition: all .5s linear;
    
  }
  .button {
    color: ${({ theme }) => theme.text};
  
  }
  .button-1{
    color: ${({ theme }) => theme.text};
   
  }
  .button-1a{
    color: ${({ theme }) => theme.text};
  }

a.nav-links-mobile.sign{
  color: ${({ theme }) => theme.text};
}

  p {
    line-height: 1.4rem;
  }
  h1{
    color: ${({ theme }) => theme.text};
  }

  .nav-menu.active {
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.primary};
  }

  .menu-icon{
    color:white;
  }

  .fa-bars{
    color: ${({ theme }) => theme.text};

  }
    
  .fa-times{
      color: ${({ theme }) => theme.text};
      
  }

  a{
      color: ${({ theme }) => theme.text};
  }
  .menu-icon{
    color: ${({ theme }) => theme.primary};
  }
 .btn--outline{
  color: ${({ theme }) => theme.text};
  
 }
 .changeColor{
  color: ${({ theme }) => theme.text};
 }
 .changeColorN{
  color: ${({ theme }) => theme.text};
 }
 .changeColortwo{
  color: ${({ theme }) => theme.text};
 }
 .Form-label{
  color: ${({ theme }) => theme.text};
 }
.card-body{
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.primary};
}
.cardborder{
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.primary};
}


 
 .herobutton{
  color: ${({ theme }) => theme.text};
  background:  #ef4343; 
 }
//  .herobuttonB{
//   color: ${({ theme }) => theme.text};
//   background:  #ef4343; 
//  }
 .btn--large{
  color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.text};
  background: ${({ theme }) => theme.primary};
 }

  .globe {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.body};
  }
  .nav{
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.body};
  
  }

  .fa-twitter{
    color: ${({ theme }) => theme.text};

  }
  .fa-linkedin{
     color: ${({ theme }) => theme.text};
  }
  
  .fa-youtube{
     color: ${({ theme }) => theme.text};
  }
  
  .fa-instagram{
     color: ${({ theme }) => theme.text};
  }
  
  .fa-facebook-f{
     color: ${({ theme }) => theme.text};
  }
  .website-rights{
    color: ${({ theme }) => theme.text};
 }
  .social-logo{
    color: ${({ theme }) => theme.text};
  }
  .nav-menu.active{
    color: ${({ theme }) => theme.text};
  }

  a.nav-links{
    color: ${({ theme }) => theme.text};
  }
`;

export const lightTheme = {
  body: "#ffffff",
  text: "#020202",
  primary: "#ffffff",

};

export const darkTheme = {
  body: "#121217",
  text: "#ffffff",
  primary: "#020202",
};
