import React, { useState, useEffect, useRef } from "react";
import {  useHistory, useRouteMatch } from "react-router-dom";
import fire from "../../config/fire";
import { Editor } from '@tinymce/tinymce-react'; 
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { serverTimestamp } from "firebase/firestore";
import { ProgressBar } from 'react-bootstrap';
import "./AddPost.css";
import { storage } from "../../config/fire";
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "../../config/fire";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";




function AddChapter() {
    
    const [photoURL, setPhotoURL] = useState("/images/user.png");
    const [writersDetails, setWritersDetails] = useState({});
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");

    const [date, setDate ] = useState("");
    const [language, setLanguage] = useState ("");
    const [category, setCategory] = useState("");
   
   
    const [c1, setC1]  = useState("");
    const [c2, setC2]  = useState("");
    const [c3, setC3]  = useState("");
    const [c4, setC4]  = useState("");
    const [c5, setC5]  = useState("");


    const auth = getAuth();
    const currentUser = useAuth();
    const user = auth.currentUser;


    // const [image, setImage] = useState("");
    // const [imageURL, setImageURL] = useState("");

    // const [chapters, setChapters] = useState(""); `${language}`
 



    const ref = fire.firestore().collection("Books");  
    const ref1 = fire.firestore().collection("UserAbout");
   
    // useEffect(() => {
    //         storage.ref(`${user.uid}.png`).getDownloadURL()
    //     .then((photoURL) => {
    //       setPhotoURL(photoURL);
    //     });
    // }, []);
  
    // const ProfileImgLink = () => {

    //   // Getting Download Link
    //   storage.ref(`${user.uid}.png`).getDownloadURL()
    //     .then((photoURL) => {
    //       setPhotoURL(photoURL);
    //     });
    // }

    function getData() {
      if (user !== null) {
        // The user object has basic properties such as display name, email, etc.
        // const displayName = user.displayName;
        // const email = user.email;
        // const photoURL = user.photoURL;
        // const emailVerified = user.emailVerified;
  
        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = user.uid;
  
        ref1
          .doc(uid)
          .get()
          .then((snapshot) => {
            if (snapshot) {
              setWritersDetails(snapshot.data());
            }
          });
      }
    }
    // const [data, setdata] = useState({});
        // const [loader, setloader] = useState(true);
        const allImputs = {imgUrl: ''}
    const [imageAsFile, setImageAsFile] = useState('')
    const [imageAsUrl, setImageAsUrl] = useState(allImputs)
    
        const handleImageAsFile = (e) => {
          const image = e.target.files[0]
          setImageAsFile(imageFile => (image))
      }

        const handleFireBaseUpload = e => {
          e.preventDefault()
          if (!title || !date || !price || !language || !category || !c1) {
            return toast.warning("Please fill in all fields!");
          }
      
          if (!imageAsFile || imageAsFile === undefined) {
            return toast.warning("Please select an image!");
          }
      
          if (c1.length < 100) {
            return toast.info("Chapter content should be of atleast 100");
          }
          if (title.trim().split(" ").length < 2) {
            return toast.info("Title should be of atleast 2 words");
          } else {
            console.log('start of upload')
            // async magic goes here...
            if(imageAsFile === '') {
              console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
            }
            const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
            //initiates the firebase side uploading 
            uploadTask.on('state_changed', 
            (snapShot) => {
              //takes a snap shot of the process as it is happening
              console.log(snapShot)
            }, (err) => {
              //catches the errors
              console.log(err)
            }, () => {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage.ref('images').child(imageAsFile.name).getDownloadURL()
               .then( fireBaseUrl => {
                 setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
                 createDoc({title, price, name: writersDetails.name,bio: writersDetails.boi, location: writersDetails.location, date , language,author: user.uid, category,  image: fireBaseUrl ,  c1, c2, c3, c4, c5,  timestamp: serverTimestamp()});

               })
            })

          }

        
        }
    const history = useHistory();
    const [disable, setDisable] = React.useState(false);
    const [progress, setProgress] = useState(0);
    

  //   function getData(){
  //     ref
  //     // .orderBy('image','desc')
  //     .onSnapshot((querySnapshot)=> {
  //         const items = []
  //         querySnapshot.forEach((doc)=>{
  //             items.push(doc.data())
               
  //         })
  //         setdata(items)
  //         setloader(false)
  //     })
  // }

  function createDoc(newDataObj){
    ref
      .doc(doc.id)
      .set( newDataObj)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })

    

}



// function pasteText(e){
//   console.log(e.clipboardData.getData('Text'));
//   console.log(e.clipboardData.getData('Text'));
//   console.log(e.clipboardData.getData('text/plain'));
//   console.log(e.clipboardData.getData('text/html'));
//   console.log(e.clipboardData.getData('text/rtf'));

//   console.log(e.clipboardData.getData('Url'));
//   console.log(e.clipboardData.getData('text/uri-list'));
//   console.log(e.clipboardData.getData('text/x-moz-url'));

// }

    const handleEditorChange1 = (e) => {
      setC1(
        e.target.getContent()
        );
    }

    const handleEditorChange2 = (e) => {
      setC2(
      e.target.getContent()
      );
    }

    const handleEditorChange3 = (e) => {
      setC3(
      e.target.getContent()
      );
    }

    const handleEditorChange4 = (e) => {
      setC4(
      e.target.getContent()
      );
    }
    const handleEditorChange5 = (e) => {
      setC5(
      e.target.getContent()
      );
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     if (!title || !date || !language || !category || !description) {
    //       return toast.warning("Please fill in all fields!");
    //     }
    
    //     if (!imageAsFile || imageAsFile === undefined) {
    //       return toast.warning("Please select an image!");
    //     }
    
    //     if (description.length < 100) {
    //       return toast.info("Description should be of atleast 100");
    //     }
    //     if (title.trim().split(" ").length < 2) {
    //       return toast.info("Title should be of atleast 2 words");
    //     } else {
    //       createDoc({title, date , language, category, imageAsUrl,  description,  timestamp: serverTimestamp()});
    //       console.log('imageURL');
    //     }
    // };


    // function validateForm(){
    //  handleFireBaseUpload();
    //  handleSubmit();
    // }
    // const handleEditorChange = (e) => {
    //     setDescription(
    //     e.target.getContent()
    //     );
    //   }


 
    useEffect(() => {
      getData();
    }, []) 

    
  return (
    <div className="container">
    <div className="row w-100">
      <div className="col-md-12 my-5 text-right">
        <Link to="/admin/dashboard/Chapter" className="btn btn-dark mr-2">
          Go Back
        </Link>
      </div>
      <div className="col-md-12 mb-3">
        <h1 className="display-3 text-dark text-center">Add Story</h1>
      </div>
      <div className="col-md-10 mx-auto mb-5 shadow p-5" >
        {progress ? (
          progress !== 100 ? (
            <div className="mx-auto p-5">
              <h1 className="text-center my-2">
                Uploading Story - {progress}%
              </h1>
              <ProgressBar now={progress} max={100} animated/>
              <progress
                className="text-center form-control"
                max={100}
                value={progress}
              ></progress>
            </div>
          ) : (
            <div className="mx-auto p-5   text-center ">
              <i className="fa fa-tick text-success mx-auto my-2"></i>
              <h1 className="text-center my-2">Story Uploaded successfully</h1>
              <Link
                to={"/admin/dashboard/addPost"}
                // onClick={refreshPage}
                className="my-2 mx-auto btn btn-primary"
              >
               Finish
              </Link>
            </div>
          )
        ) : (
          <form onSubmit={handleFireBaseUpload} className="writerform" >
                <div className="form-group">
                <input
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="number"
                  placeholder="Price of this book in Dollars"
                  className="form-control"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  type="date"
                  placeholder=".../.../2022"
                  className="form-control"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>


        <div className="form-group">
        <label>
        Languages and File-Types:
        <select value={language}  onChange={(e) => setLanguage(e.target.value)}>            
          <option value="none">Select here</option>
          <option value="SWAHILI">SWAHILI</option>
          <option value="BEMBA">BEMBA</option>
          <option value="NewandHot">New and Hot</option>
          <option value="EditorPicks">Editor's Picks</option>
          <option value="FromOurStarts">From Our Stars</option>
          <option value="StoryAd1">StoryAd1</option>
          <option value="StoryAd2">StoryAd2</option>
          <option value="StoryAd3">StoryAd3</option>
          <option value="StoryAd4">StoryAd4</option>
          <option value="Adventure">Adventure</option>
          <option value="Genre">Genre</option>
          <option value="Action">Action</option>
          <option value="Fantasy">Fantasy</option>
          <option value="Historical Fiction">Historical Fiction</option>
          <option value="Poetry">Poetry</option>
          <option value="Diary">Diary</option>
          <option value="Fiction">Fiction</option>
          <option value="Action and adventure">Action and adventure</option>
          <option value="Alternate history">Alternate history</option>
          <option value="Autobiography">Autobiography</option>
          <option value="Biography">Biography</option>
          <option value="Comic book">Comic book</option>
          <option value="BunnerStories">Top bunner Stories</option>

        </select>
      </label>
            </div>
            <div className="form-group">
        <label>
        Category:
        <select value={category}  onChange={(e) => setCategory(e.target.value)}>            
          <option value="none">Select here</option>
          <option value="Genre">Genre</option>
          <option value="Adventure">Adventure</option>
          <option value="Action">Action</option>
          <option value="Art/architecture">Art/architecture</option>
          <option value="Fantasy">Fantasy</option>
          <option value="Historical Fiction">Historical Fiction</option>
          <option value="Poetry">Poetry</option>
          <option value="Nonfiction">Nonfiction</option>
          <option value="Fiction">Fiction</option>
          <option value="Action and adventure">Action and adventure</option>
          <option value="Alternate history">Alternate history</option>
          <option value="Autobiography">Autobiography</option>
          <option value="Biography">Biography</option>
          <option value="Chick lit">Chick lit</option>
          <option value="Business/economics">Business/economics</option>
          <option value="Children's">Children's</option>
          <option value="Crafts/hobbies">Crafts/hobbies</option>
          <option value="Classic">Classic</option>
          <option value="Cookbook">Cookbook</option>
          <option value="Comic book">Comic book</option>
          <option value="Diary">Diary</option>
          <option value="Coming-of-age">Coming-of-age</option>
          <option value="Dictionary">Dictionary</option>
          <option value="Crime">Crime</option>
          <option value="Encyclopedia">Encyclopedia</option>
          <option value="Drama">Drama</option>
          <option value="Guide">Guide</option>
          <option value="Fairytale">Fairytale</option>
          <option value="Health/fitness">Health/fitness</option>
          <option value="History">History</option>
          <option value="Graphic novel">Graphic novel</option>
          <option value="Home and gadenr">Home and gadenr</option>
          <option value="Historical fiction">Historical fiction</option>
          <option value="Humor">Humor</option>
          <option value="Journal">Journal</option>
          <option value="Mystery">Mystery</option>
          <option value="Math">Math</option>
          <option value="Paranormal romance">Paranormal romance</option>
          <option value="Memoir">Memoir</option>
          <option value="Picture book">Picture book</option>
          <option value="Philosophy">Philosophy</option>
          <option value="Poetry">Poetry</option>
          <option value="Political thriller">Political thriller</option>
          <option value="Religion">Religion</option>
          <option value="Romance">Romance</option>
          <option value="Textbook">Textbook</option>
          <option value="Satire">Satire</option>
          <option value="True crime">True crime</option>
          <option value="Science fiction">Science fiction</option>
          <option value=">Review">Review</option>
          <option value="Short story">Short story</option>
          <option value="Action and Science">Science</option>
          <option value="Suspense">Suspense</option>
          <option value="Self hele">Self help</option>
          <option value="Sports and leisure">Sports and leisure</option>
        </select>
      </label>
            </div>
     
      <div className="form-group">
               <div className="Form-label">Select Story Cover: In JPG</div>
   
               <input
          type="file"
          onChange={handleImageAsFile}
                  accept="image/jpeg, image/jpg"
                />
     </div>

     {/* Copy Button */}

        {/* <div>
          <button >Paste</button> 
          
        </div> */}
     
      {/* Chapter1 */}
      <h2>Chapter1</h2>
    <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange1}
      />


        </div>


   {/* Chapter2 */}
   <h2>Chapter2</h2>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange2}
      />


        </div>

   {/* Chapter3 */}
   <h2>Chapter3</h2>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange3}
      />


        </div>

   {/* Chapter4 */}
   <h2>Chapter4</h2>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange4}
      />


        </div>

   {/* Chapter5 */}
   <h2>Chapter5</h2>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange5}
      />


        </div>





              <img src={imageAsUrl.imgUrl} alt="image tag" />

              <div className="form-group">
                <input
                  type="submit"
                  className="btn btn-dark btn-block"
                  value={disable ? 'Loading...' : 'Add Story'}
                  disabled={disable} 
                />
              </div>

          </form>
        )}
      </div>
    </div>
  </div>
  )
}

export default AddChapter