import emailjs from "emailjs-com";
import React, {useState} from 'react';
import "./AddPost.css";

export default function ContactForm() {


    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm(
        'service_8849lz3', 
        'template_usxvbh9', 
        e.target, 
        'user_1px7kA6uzhxCjIjtYaTQn'
        )
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
        <div
        className="container border"
        style={{
            marginTop:"50px",
            width: "100%",
            backgroundImage: `url('https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/v975-background-07-b-kqkr4ukm.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=d92b38f7af4f92cbb48fb96c37560242')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
        }}
        >
            <div className="container">
            <form onSubmit={sendEmail} className="writerform">
            <div className="col-md-12 mb-3">
          <h1 className="display-3 text-dark text-center">Become a Writer</h1>
           </div>
                    <div className="row ">
                        <div className="col-10 form-group ">
                            <input type="text" className="form-control" placeholder="FullName" name="name"/>
                        </div>
                        <div className="col-10 form-group ">
                            <input type="text" className="form-control" placeholder="Phone Number" name="phone"/>
                        </div>                       
                        <div className="col-10 form-group">
                            <input type="email" className="form-control" placeholder="Email Address" name="email"/>
                        </div>
                      
                        <div className="col-10 form-group">
                        Your level of writing:                         
                            <input type="text" className="form-control w-100" placeholder="Beginner, Competent, Proficient and Expert " name="level"/>
                        </div>
                     

                        <div className="col-20 form-group">
                        <label>
                        Add your best Story:
                        <div className="form-group writerform">
                            <textarea
                            placeholder="Enter Story"
                            className="form-control"
                            rows="20"
                            cols="100"
                            name="file"
                            ></textarea>
                        </div>
                        </label>
                        </div>
                        <div className="col-10 form-group ">
                            <textarea className="form-control" id="" cols="30" rows="8" placeholder="Tell us about yourself and your writing journey" name="message"></textarea>
                        </div>
                        <div className="col-8 ">
                            <input type="submit" className="btn btn-primary" value="Send Message"></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}