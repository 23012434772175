import React,{useState, useEffect } from 'react';
import Popup from "./ProfileAbout";
import { shallowEqual, useSelector } from "react-redux";
import fire from "../../config/fire";
import { getAuth} from "firebase/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";




function ProfileView() {
  const history = useHistory();
  const [userDetails, setUserDetails] =useState({});
  const auth = getAuth();
  const user = auth.currentUser;
    const ref = fire.firestore().collection("UserAbout");
    const [name, setName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [boi, setBoi] = useState("");
    const [location, setLocation] = useState("");
   
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

    
    function getData(){
      if (user !== null) {
        
        // The user object has basic properties such as display name, email, etc.
        // const displayName = user.displayName;
        // const email = user.email;
        // const photoURL = user.photoURL;
        // const emailVerified = user.emailVerified;
      
        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = user.uid;
  
        
        ref
        .doc(uid)
        .get()
        .then((snapshot) =>{
          if(snapshot){
            setUserDetails(snapshot.data());
          }
        });
  
  
       
  
      }
  
    }

  

  function editDoc(updtedDoc){
    if (user !== null) {
      
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;
    
      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;

      
      ref
      .doc(uid)
      .update(updtedDoc)
      .catch((err) => {
        alert(err)
        console.error(err);
      })


     

    }

  }
useEffect(() => {
getData();
}, [])

    return (
        <div>
         
            <div>
            <label className='changeColor'><i class="far fa-address-card"></i><b>Bio</b></label>
            <br/>
            {userDetails.boi}
            <br />
            <lable><i class="fas fa-map-marker-alt"></i><b>Location</b></lable> 
            <br/>
            {userDetails.location}
              </div>
        
<br/>
   
    <input
      type="button"
      value="Edit About"
      onClick={togglePopup}
    />
    {isOpen && <Popup
      content={<>
       <h2><b>About</b></h2> 
       <lable><b>Name</b></lable>
       <br/>
            <input
               type="text"
                placeholder="First Name"
                id="name"
                onChange={(e) => setName(e.target.value)}
            />
          <br />
        <br />
        <lable><i class="fas fa-map-marker-alt"></i><b>Location</b></lable>
        <br/> 
        <input 
        type='text'
        id="location"
        onChange={(e) => setLocation(e.target.value)}
        />
        <br/>
        <label className='changeColor'><i class="far fa-address-card"></i><b>Boi</b></label>
        <br />
       <textarea
                         placeholder="About you"
                         className="form-control"
                         rows="4"
                         id='boi'
                         onChange={(e) => setBoi(e.target.value)}
                      
       >

       </textarea>
        <br />
        <br/>
        <div className="form-group">
                     
                     <input 
                     onClick={() => {
                      if ( !name || !boi || !location ) {
                        return toast.warning("Please fill in all fields!!");
                      }
                    editDoc({boi: boi, location: location, name: name});
                    document.getElementById("boi").value =""
                    document.getElementById("name").value =""
                    document.getElementById("location").value =""
                    history.push("/");
                     }}
                     type="submit"           
                     className="btn btn-primary "
                     />
             </div>
      </>}
      handleClose={togglePopup}
    />}
  </div>
    )
}
export default ProfileView
