import React, { useEffect, useState} from "react";
import {Card,ListGroup,Button,Offcanvas,Figure,Container,Row,Toast,Dropdown,Tab,Tabs,Col,Nav,Carousel} from "react-bootstrap"
import { useParams,Link } from "react-router-dom";
import parse from 'html-react-parser';
import {  useHistory, useRouteMatch } from "react-router-dom";
import fire from "../../config/fire";
import {useAuth} from "../../config/fire";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { storage } from "../../config/fire";

function OffCanvasExample({ name, ...props }) {
    const [show, setShow] = useState(false);
  
    
  const history = useHistory();
  let { path } = useRouteMatch();
 
  const [open, setOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lgShow, setLgShow] = useState(false);
  const [photoURL, setPhotoURL] = useState("/images/user.png");
  const [key, setKey] = useState('home');
  const auth = getAuth();
  const getauth = auth.currentUser
  const [author, setAuthor] = useState({});
  const ref = fire.firestore().collection("UserAbout");
  const ref1 = fire.firestore().collection("Books");

  const [othersFollowers, setOthersFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chapters, setChapters] =  useState({});
  const [data, setdata] = useState([]);

     




  
function get(){
  ref1
  .onSnapshot((querySnapshot)=> {
      const items = []
      querySnapshot.forEach((doc)=>{
          items.push({ ...doc.data(), id: doc.id })
      })            
      setdata(items)
      
  })
}

  const { bookid } = useParams();


  function getChapters() {
    const uid = bookid; /// others profile uid
    ref1
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          setChapters(snapshot.data());

        }
      });
  }

 const [otherUserid, setOtherUserId] = useState("");



  // const currentPost = posts.find((post) => post.postId === id && post);
 

  
  useEffect(() => {
    // ProfileImgLink();   
    getChapters();
    getData();
  }, []);

  async function getData() {
    setLoading(true)
    if (getauth !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = getauth.uid;
      await ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            // setUserDetails(snapshot.data());
            // console.log('following', snapshot.data().following)
            setFollowing(
              snapshot.data().following ? [...snapshot.data().following] : []
            );
          }
          setLoading(false)
        });
    }
  }

  // const getAuthordata = async () => {
  //  await ref
  //     .doc(currentPost.post.author)
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot) {
  //         // console.log(snapshot.data())
  //         setOtherUserId(snapshot.id);
  //         setAuthor(snapshot.data());
  //         setOthersFollowers(
  //           snapshot.data().followedBy ? snapshot.data().followedBy : []
  //         );
  //       }
  //     });
  // };

  function handleClick() {
    let temp = othersFollowers;
    let temp2 = following;
    // console.log(getauth)
    let isFollower = temp.find((f) => f == getauth.uid);
    let isfollowing = temp2.find((f) => f == otherUserid); /// others profile uid
    if (isFollower) {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayRemove(getauth.uid),
      });
      let filtered = temp.filter(t => t != getauth.uid)
      setOthersFollowers(filtered)
    } else {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayUnion(getauth.uid),
      });
      temp.push(getauth.uid)
      setOthersFollowers([...temp])
    }

    if(isfollowing){
      ref.doc(getauth.uid).update({
        following: firebase.firestore.FieldValue.arrayRemove(otherUserid), /// others profile uid
      });
      let filtered = temp2.filter(t => t != otherUserid) /// others profile uid
      setFollowing(filtered)
    } else {
      ref.doc(getauth.uid).update({
        following: firebase.firestore.FieldValue.arrayUnion(otherUserid), /// others profile uid
      });
      temp2.push(otherUserid) /// others profile uid
      setFollowing([...temp2])
    }
  }


//   const ProfileImgLink = () => {

//     // Getting Download Link
//     storage.ref(`${chapters.author}.png`).getDownloadURL()
//       .then((photoURL) => {
//         setPhotoURL(photoURL);
//       });
// }


  // useEffect(() => {
  //   ProfileImgLink();

  // }, []);

  
    
    
    return (
      <>
        <Button variant="light" onClick={handleShow} className="me-2">
        Chapters <i class="fas fa-book-reader bg-light"></i>
        </Button>
        <Offcanvas show={show} onHide={handleClose} {...props} className="offcanvas"
        style={{height:"100%"}}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header>
        
          <Offcanvas.Body>
     <Card className="text-center bg-light">
  <Card.Header>Featured</Card.Header>
  <Card.Body className="bg-light">
  <Figure className="float-left">
  <Figure.Image
    width={110}
    height={150}
    alt="171x180"
    src={chapters.image}
  />
  
</Figure>
    <Card.Title> {chapters.title} </Card.Title>
    <Card.Text>
      
    </Card.Text>
    <Link to="/admin/dashboard/Chapter">
    <Button variant="primary">Latest Stories</Button>
    </Link>
   {/* <span  style={{marginLeft:"-8%"}}><CommentModal /></span> */}
  </Card.Body>
  <Card.Footer className="text-muted"><i class="fa fa-calendar"
   style={{fontSize:"16px"}}
  ></i>  {chapters.date}</Card.Footer>
</Card>




          <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">

          <>
  <p>
    
    <button
      className="btn btn-dark disappear mt-3 "
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseExample"
      aria-expanded="false"
      aria-controls="collapseExample"
      style={{marginLeft:"2%"}}
    >
      Book Chapters
    </button>
  </p>
  <div className="collapse" id="collapseExample">
    <div className="card card-body">
    <Row style={{marginTop:"-4%"}}>
    <Col sm={4}>

    

      <ListGroup>


        <ListGroup.Item action href="#link1"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 1 
        </ListGroup.Item>
        <ListGroup.Item action href="#link2"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 2
        </ListGroup.Item>
        <ListGroup.Item action href="#link3"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 3 
        </ListGroup.Item>
        <ListGroup.Item action href="#link4" 
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 4 
        </ListGroup.Item>

        <ListGroup.Item action href="#link5"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
         >
        <i class="fas fa-book-reader"></i> 5 
        </ListGroup.Item>


        <ListGroup.Item action href="#link6" 
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 6 
        </ListGroup.Item>

        <ListGroup.Item action href="#link7" >
        <i class="fas fa-book-reader"></i> 7 
        </ListGroup.Item>



        <ListGroup.Item action href="#link8"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 8 
        </ListGroup.Item>


        <ListGroup.Item action href="#link9" 
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 9 
        </ListGroup.Item>



        <ListGroup.Item action href="#link10"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 10 
        </ListGroup.Item>


        <ListGroup.Item action href="#link11"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 11 
        </ListGroup.Item>

        <ListGroup.Item action href="#link12"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 12 
        </ListGroup.Item>

        <ListGroup.Item action href="#link13"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 13 
        </ListGroup.Item>

        <ListGroup.Item action href="#link14"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 14 
        </ListGroup.Item>

        <ListGroup.Item action href="#link15"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 15 
        </ListGroup.Item>

        <ListGroup.Item action href="#link16"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 16 
        </ListGroup.Item>

        <ListGroup.Item action href="#link17"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 17 
        </ListGroup.Item>

        <ListGroup.Item action href="#link18"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 18 
        </ListGroup.Item>

        <ListGroup.Item action href="#link19"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 19 
        </ListGroup.Item>

        <ListGroup.Item action href="#link20"
        data-bs-toggle="collapse"
        data-bs-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
        >
        <i class="fas fa-book-reader"></i> 20 
        </ListGroup.Item>

      </ListGroup>
    </Col>
    </Row>
    </div>
  </div>
</>
      
  <Row >
    <Col sm={4} id="GroupM" >

      <ListGroup style={{marginLeft:"10%"}} >
        <ListGroup.Item action href="#link1">
        <i class="fas fa-book-reader"></i> 1 
        </ListGroup.Item>
        <ListGroup.Item action href="#link2">
        <i class="fas fa-book-reader"></i> 2
        </ListGroup.Item>
        <ListGroup.Item action href="#link3" >
        <i class="fas fa-book-reader"></i> 3 
        </ListGroup.Item>
        <ListGroup.Item action href="#link4" >
        <i class="fas fa-book-reader"></i> 4 
        </ListGroup.Item>
        <ListGroup.Item action href="#link5" >
        <i class="fas fa-book-reader"></i> 5 
        </ListGroup.Item>


        <ListGroup.Item action href="#link6" >
        <i class="fas fa-book-reader"></i> 6 
        </ListGroup.Item>

        <ListGroup.Item action href="#link7" >
        <i class="fas fa-book-reader"></i> 7 
        </ListGroup.Item>



        <ListGroup.Item action href="#link8" >
        <i class="fas fa-book-reader"></i> 8 
        </ListGroup.Item>


        <ListGroup.Item action href="#link9" >
        <i class="fas fa-book-reader"></i> 9 
        </ListGroup.Item>



        <ListGroup.Item action href="#link10" >
        <i class="fas fa-book-reader"></i> 10 
        </ListGroup.Item>

        <ListGroup.Item action href="#link11">
        <i class="fas fa-book-reader"></i>11 
        </ListGroup.Item>
        <ListGroup.Item action href="#link12">
        <i class="fas fa-book-reader"></i> 12
        </ListGroup.Item>
        <ListGroup.Item action href="#link13" >
        <i class="fas fa-book-reader"></i> 13 
        </ListGroup.Item>
        <ListGroup.Item action href="#link14" >
        <i class="fas fa-book-reader"></i> 14 
        </ListGroup.Item>
        <ListGroup.Item action href="#link15" >
        <i class="fas fa-book-reader"></i> 15 
        </ListGroup.Item>


        <ListGroup.Item action href="#link6" >
        <i class="fas fa-book-reader"></i> 16 
        </ListGroup.Item>

        <ListGroup.Item action href="#link17" >
        <i class="fas fa-book-reader"></i> 17 
        </ListGroup.Item>



        <ListGroup.Item action href="#link18" >
        <i class="fas fa-book-reader"></i> 18 
        </ListGroup.Item>


        <ListGroup.Item action href="#link19" >
        <i class="fas fa-book-reader"></i> 19 
        </ListGroup.Item>



        <ListGroup.Item action href="#link20" >
        <i class="fas fa-book-reader"></i> 20 
        </ListGroup.Item>


      </ListGroup>
    </Col>

   
    <Col sm={8}>
    <article className="bg-c">
      <Tab.Content>
     
        <Tab.Pane eventKey="#link1" className="text-muted" >
        {parse(`${chapters.c1}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link2" className="text-muted">
        {parse(`${chapters.c2}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link3" className="text-muted">
        {parse(`${chapters.c3}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link4" className="text-muted">
        {parse(`${chapters.c4}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link5" className="text-muted">
        {parse(`${chapters.c5}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link6" className="text-muted">
        {parse(`${chapters.c6}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link7" className="text-muted">
        {parse(`${chapters.c7}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link8" className="text-muted">
        {parse(`${chapters.c8}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link9" className="text-muted">
        {parse(`${chapters.c9}`)}
        </Tab.Pane>

        <Tab.Pane eventKey="#link10" className="text-muted">
        {parse(`${chapters.c10}`)}
        </Tab.Pane>
       
        <Tab.Pane eventKey="#link11" className="text-muted">
        {parse(`${chapters.c11}`)}
        </Tab.Pane>
               
        <Tab.Pane eventKey="#link12" className="text-muted">
        {parse(`${chapters.c12}`)}
        </Tab.Pane>
               
        <Tab.Pane eventKey="#link13" className="text-muted">
        {parse(`${chapters.c13}`)}
        </Tab.Pane>
                       
        <Tab.Pane eventKey="#link14" className="text-muted">
        {parse(`${chapters.c14}`)}
        </Tab.Pane>
                       
        <Tab.Pane eventKey="#link15" className="text-muted">
        {parse(`${chapters.c15}`)}
        </Tab.Pane>
                       
        <Tab.Pane eventKey="#link16" className="text-muted">
        {parse(`${chapters.c16}`)}
        </Tab.Pane>
                       
        <Tab.Pane eventKey="#link17" className="text-muted">
        {parse(`${chapters.c17}`)}
        </Tab.Pane>
                       
        <Tab.Pane eventKey="#link18" className="text-muted">
        {parse(`${chapters.c18}`)}
        </Tab.Pane>
                       
        <Tab.Pane eventKey="#link19" className="text-muted">
        {parse(`${chapters.c19}`)}
        </Tab.Pane>
                       
        <Tab.Pane eventKey="#link20" className="text-muted">
        {parse(`${chapters.c20}`)}
        </Tab.Pane>
        
      </Tab.Content>
      </article>
    </Col>
    
  </Row>
</Tab.Container>
          
          </Offcanvas.Body>
        
        </Offcanvas>
      </>
    );
  }
  
  function Mode2() {
    return (
      <>
        {['top'].map((placement, idx) => (
          <OffCanvasExample key={idx} placement={placement} name={placement} />
        ))}
      </>
    );
  }
  
  export default Mode2;