import { useEffect, useState } from "react";
import { useAuth } from "../../config/fire";
import firebase from "firebase/compat/app";
import { shallowEqual, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import "./Profile.css";
import { toast } from "react-toastify";
import ProfileNav from "./ProfileNav";
import fire from "../../config/fire";
import { serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { storage } from "../../config/fire";
import ProfileView from "./ProfileView";
import { Modal, ListGroup, Button } from "react-bootstrap";
/// replace others profile uid
export default function Chats(props) {
  const [userDetails, setUserDetails] = useState({});
  const [userDetails1, setUserDetails1] = useState({});
  const [otherUserDetail, setOtherUserDetail] = useState({});
  const [userDetailsLog, setUserDetailsLog] = useState({});
  const [works, setWorks] = useState({});
  const currentUser = useAuth();
  const auth = getAuth();
  const getauth = auth.currentUser
  const user = auth.currentUser;
  const ref = fire.firestore().collection("UserAbout");
  const { otherUserid } = useParams();
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState("/images/user.png");
  const [MphotoURL, setMphotoURL] = useState("/images/user.png");
  const [followers, setFollowers] = useState([]);
  const [othersFollowers, setOthersFollowers] = useState([]);
  const [othersFollowers1, setOthersFollowers1] = useState([]);

  const [following, setFollowing] = useState([]);
  const [following1, setFollowing1] = useState([]);

  const [disable, setDisable] = useState(false);
  const [show, setShow] = useState(false);


  /// othersChat consts
  const messages = fire.firestore().collection(`${props.fileName} ChatRoom`);
  const [name, setName] = useState("New Message..");
  const [Message, setMessage] = useState("");
  const [data, setdata] = useState([]);
  const [loader, setloader] = useState(true);

  
  const ProfileImgLink = () => {

    // Getting Download Link
    storage.ref(`${otherUserid}.png`).getDownloadURL()
      .then((MphotoURL) => {
        setMphotoURL(MphotoURL);
      });
}
 
  useEffect(() => {
    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL);
    }
  }, [currentUser]) 


function createDoc(newDataObj){
    messages
      .doc()
      .set(newDataObj)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })
      getMessages();                   

    

}

function getMessages(){
  messages
  .orderBy('timestamp','desc')
  .onSnapshot((querySnapshot)=> {
        const items = []
        querySnapshot.forEach((doc)=>{
            items.push(doc.data())
        })
        setdata(items)
        setloader(false)
    })
}

  
const handleClose = () => setShow(false);
const handleShow = () => {
  let promises = [];
  userDetails1.followedBy.forEach((item) => {
    let follower = ref.doc(item).get();
    promises.push(follower);
  });
  Promise.all(promises).then((res) => {
    let temp = [];
    res.forEach((item) => {
      if (item.data()) temp.push(item.data());
      else console.log("not ok");
    });
    setFollowers(temp);
    if(temp.length>0)
    setShow(true);
  });
};

  function handleClick() {
    let temp = othersFollowers;
    let temp2 = following
    let isFollower = temp.find((f) => f == user.uid);
    let isfollowing = temp2.find((f) => f == otherUserid); /// others profile uid
    if (isFollower) {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayRemove(user.uid),
      });
      let filtered = temp.filter(t => t != user.uid)
      setOthersFollowers(filtered)
    } else {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayUnion(user.uid),
      });
      temp.push(user.uid)
      setOthersFollowers([...temp])
    }

    if(isfollowing){
      ref.doc(user.uid).update({ 
        following: firebase.firestore.FieldValue.arrayRemove(otherUserid), /// others profile uid
      });
      let filtered = temp2.filter(t => t != otherUserid) /// others profile uid
      setFollowing(filtered)
    } else {
      ref.doc(user.uid).update({
        following: firebase.firestore.FieldValue.arrayUnion(otherUserid), /// others profile uid
      });
      temp2.push(otherUserid) /// others profile uid
      setFollowing([...temp2])
    }
  }





  async function getUserData(){
    if (user !== null) {
      
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;
    
      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;
  
      
      ref
      .doc(uid)
      .get()
      .then((snapshot) =>{
        if(snapshot){
          setUserDetailsLog(snapshot.data());
        }
      });
  

     
  
    }
  
  }

  async function getData() {
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;
      await ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            setUserDetails(snapshot.data());
            console.log('following', snapshot.data().following)
            setFollowing(
              snapshot.data().following ? [...snapshot.data().following] : []
            );
          }
        });
        
    }
  }

  async function getData1() {
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;
      await ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            console.log('following', snapshot.data().following)
            setFollowing1(
              snapshot.data().following ? [...snapshot.data().following] : []
            );
          }
        });
        
    }
  }

  async function getData2() {
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = otherUserid;
      await ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            setUserDetails1(snapshot.data());
   
          }
        });
        
    }
  }

  function getOtherUserData() {
    const uid = otherUserid; /// others profile uid
    ref
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          setOtherUserDetail(snapshot.data());
          setOthersFollowers(
            snapshot.data().followedBy ? snapshot.data().followedBy : []
          );
        }
      });
  }
  useEffect(() => {
    getData1();
    getData2();
    ProfileImgLink();
    getUserData();
    getData();
    getOtherUserData();
  }, []);



  return (
    <div className="fields">
      {/* {console.log('others followers =====>',othersFollowers)} */}
      {/* profile picture upload */}
      <div className="profileCover">
      <br />
      <br />
      <br />
      <br />
      <br />
        <>
          <Avatar
            className="avatar"
            alt="Remy Sharp"
            src={MphotoURL}
            sx={{ width: 156, height: 156 }}
          />
        </>
        {/* user proflie details */}
        <div className="userN">@{otherUserDetail.name}</div>

        <div className="form-group">
          <Button className="btn btn-primary uploadImg" onClick={handleClick} disabled={loading}>
            {following.find((f) => f == otherUserid) ? <i class="fas fa-user">Following</i> : <i class="fas fa-user-plus"> Follow</i>}
          </Button>
        </div>

        <div className="d-flex justify-content-center mt-3">
          <button  className="FollowB works">
            <h6 className="text-center mb-0">{otherUserDetail.works}</h6>
            <h6 className="text-center">Works</h6>
          </button>
          <button  className="FollowB reading">
            <h6 className="text-center mb-0">{otherUserDetail.ReadingList}</h6>
            <h6 className="text-center">Reading List</h6>
          </button>
          <button  className="FollowB followers " onClick={handleShow}>
            <h6 className="text-center mb-0">
              {othersFollowers.length}
            </h6>
            <h6 className="text-center">Followers</h6>
          </button>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="customModal">
            <ListGroup style={{background: '#121217'}}>
              {followers.map((item, index) => {
                return (
                  
                  <ListGroup.Item  className="text-secondary d-flex flex-column" style={{background: '#121217'}}>
                   <div className="d-flex align-items-center">
                    <Avatar
                      className=""
                      alt="Remy Sharp"
                      src={item.image}
                      sx={{ width: 50, height: 50 }}
                    />
                    <p className="m-3">{item.name}</p>
                    <Button   className=" d-flex justify-content-end" 
                    onClick={() =>{

                      let temp = othersFollowers1;
                      let temp2 = following1;
                      // console.log(getauth)
                      let isFollower = temp.find((f) => f == user.uid);
                      let isfollowing = temp2.find((f) => f == item.id); /// others profile uid
                      if (isFollower) {
                        ref.doc(item.id).update({ /// others profile uid
                          followedBy: firebase.firestore.FieldValue.arrayRemove(user.uid),
                        });
                        let filtered = temp.filter(t => t != user.uid)
                        setOthersFollowers1(filtered)
                      } else {
                        ref.doc(item.id).update({ /// others profile uid
                          followedBy: firebase.firestore.FieldValue.arrayUnion(user.uid),
                        });
                        temp.push(user.uid)
                        setOthersFollowers1([...temp])
                      }
                  
                      if(isfollowing){
                        ref.doc(user.uid).update({
                          following: firebase.firestore.FieldValue.arrayRemove(item.id), /// others profile uid
                        });
                        let filtered = temp2.filter(t => t != item.id) /// others profile uid
                        setFollowing1(filtered)
                      } else {
                        ref.doc(user.uid).update({
                          following: firebase.firestore.FieldValue.arrayUnion(item.id), /// others profile uid
                        });
                        temp2.push(item.id) /// others profile uid
                        setFollowing1([...temp2])
                      }
                    }} 
                    disabled={loading}>
                         {following1.find((f) => f == item.id) ? <i class="fas fa-user">Following</i> : <i class="fas fa-user-plus"> Follow</i> }
                   </Button>
                    </div>
                  </ListGroup.Item>
                  
                );
              })}
            </ListGroup>
            <div className="d-flex justify-content-end"><Button variant="primary" onClick={handleClose}>
              Close
            </Button></div>
            
          </Modal.Body>
        </Modal>
      </div>
      <>
            {/* Profile NavBar */}
    <div className="navp">
        <div className="navbar-containerp " >
          <ul  >
            <li className="nav-itemp">
              <Link
               to="/admin/dashboard/profile"
                className="nav-linksp"
                
              >
               About
              </Link>
            </li>
            <li className="nav-itemp">
              <Link
               to={`/admin/dashboard/OthersChat/${user.uid}`}
               
                className="nav-linksp"
               
              >
              Chats
              </Link>
            </li>
            <li className="nav-itemp" >
              <Link to="/admin/dashboard/notification" 
              className="nav-linksp" 
              >
             Notification
              </Link>
            </li>
            {/* <li className="nav-itemp" >
              <Link to="/admin/dashboard/books" 
              className="nav-linksp" 
              onClick={closeMobileMenu}>
             Following
              </Link>
            </li> */}
          </ul>
        </div>
     </div>
        <div className=" AboutUserin   shadow p-5  ">
        <br />
        <br />
          <div className="BoxMessage">

       <input   id='Message' onChange={(e) => setMessage(e.target.value)} className="InputMessageBox" placeholder={" Say something " + userDetailsLog.name} />
       <i class="sendB fas fa-paper-plane"
                                        onClick={() => {
                                      
                                          if (  !Message  ) {
                                            return toast.warning("Please fill in Post field!!");
                                          }
                                          setName(props.name);
                                          createDoc({Message, name, photoURL, timestamp: serverTimestamp()});
                                          document.getElementById("Message").value =""                    

                                        }}
                     ></i>
       <Avatar
            className="avatarM"
            alt="Remy Sharp"
            src={photoURL}
            sx={{ width: 48, height: 48 }}
          />

             </div>
             <br/>
             <div className="form-group">
          <Button className="btn btn-primary" onClick={() => {
               getMessages();
          }}>
           <i class="fas fa-chevron-circle-down"></i> view Chats
          </Button>
           </div>
             {/* Show all Messages Here */}
             <div>
                <div className='changeColor glob '>
                              {/* <h2 className='changeColor'><b>Notifications</b></h2>  */}
                        {loader === false && (data.map((dev) =>
                         <div className="MessageB">
                         <div key={dev.id} className='changeColor MessageBox'>
                      <span className="textMessage"> {dev.name}</span>   
                        <br />
                                <Avatar
                    className="avatarM"
                    alt="Remy Sharp"
                    src={dev.photoURL}
                    sx={{ width: 48, height: 48 }}
                                />
                        <p  className='textMessage changeColor  ' >  {dev.Message}</p>

                          </div>
                          </div>
                        ))}
                    <br/>
                    
                </div>
                </div>
  
        </div>
      </>
    </div>
  );
}
