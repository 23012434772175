import React from 'react'
import { BrowserRouter as Router,Switch,Route,Link } from 'react-router-dom';
import './HowItWorks.css'
import { useState } from 'react';
import HIWpopup from './HIWpopup';

export default function HowItWorks() {
    const [popbtn, setPopbtn]= useState(false);
 
    return (
        <div className='HIW-body'> 
        
            <Router>
                {/* How it works Selection List */}
                <div className="HIW_welcome_page">
                <h1 id='h1'>How it works ?</h1>
                <hr style={{backgroundColor:"#9f1f4a",height:"5px",margin:"0% 15%"}}/>
                    <ul>
                        <div className='HIW_links'><Link id='HIW_links' onClick={()=>{setPopbtn(true)}}to={"/Hintro"} >Begin</Link></div>
                        <div className='HIW_links'><Link id='HIW_links' onClick={()=>{setPopbtn(true)}}to={"/Hhome"} >Home tab</Link></div>
                        <div className='HIW_links'><Link id='HIW_links' onClick={()=>{setPopbtn(true)}}to={"/Hbrowse"} >Browse tab</Link></div>
                        <div className='HIW_links'><Link id='HIW_links' onClick={()=>{setPopbtn(true)}}to={"/HmotherLand"} >Mother Land tab </Link></div>
                        <div className='HIW_links'><Link id='HIW_links' onClick={()=>{setPopbtn(true)}}to={"/Hwrite"} > Write tab </Link></div>
                        <div className='HIW_links'><Link id='HIW_links' onClick={()=>{setPopbtn(true)}}to={"/Hfeatures"} >Features</Link></div>
                    </ul>
                <hr style={{backgroundColor:"#9f1f4a",height:"5px",margin:"0% 15%"}}/>
              </div>
                <div className='HIW-container'>
                    <HIWpopup trigger={popbtn} setTrigger={setPopbtn}>
                        <div className='HIW-content'>
                            <Switch>
                               
                                <Route exact path={`/Hintro`}>
                                    < Hintro/>
                                </Route>
                                <Route exact path={`/Hhome`}>
                                    < Hhome/>
                                </Route>
                                <Route exact path={`/Hbrowse`}>
                                    < Hbrowse/>
                                </Route>
                                <Route exact path={`/HmotherLand`}>
                                    < HmotherLand/>
                                </Route>
                                <Route exact path={`/Hwrite`}>
                                    < Hwrite/>
                                </Route>
                                <Route exact path={`/Hfeatures`}>
                                    < Hfeatures/>
                                </Route>

                            </Switch>
                        </div>
                    </HIWpopup>
                </div>
            </Router>
        </div>
    )
};

//
//
//
//How it works Intro component 
 function Hintro() {
  return <div className='intro_comp'>
            <h3 id='head'>For your best experience, please follow the steps to sign up with an account.</h3>
            <h3 id='steps'>Step 1</h3>
            <img id='signImg' alt='image here' src='/HIWs/signup1.jpg'/>

            <h3 id='HIW-mobile'>Mobile</h3>
            <img id='signImgb' alt='image here' src='/HIWs/signup1b.png'/>
            <br/>

            <h3 id='steps'>Step 2</h3>
            <img id='signImg' alt='image here' src='/HIWs/signup2.jpg'/>
            
            <h3 id='HIW-mobile'>Mobile</h3>
            <img id='signImgb' alt='image here' src='/HIWs/signup2b.png'/>
            <br/>
    
            <h3 id='steps'>Step 3</h3>
            <img id='signImg' alt='image here' src='/HIWs/signup3.jpg'/>
            
            <h3 id='HIW-mobile'>Mobile</h3>
            <img id='signImgb' alt='image here' src='/HIWs/signup3b.png'/>
            <br/>
        
            <h3 id='steps'>Step 4</h3>
            <img id='signImg' alt='image here' src='/HIWs/signup4.jpg'/>
            
            <h3 id='HIW-mobile'>Mobile</h3>
            <img id='signImgb' alt='image here' src='/HIWs/signup4b.png'/>
            <br/>
            
            <h3 id='steps'>Step 5</h3>
            <img id='signImg' alt='image here' src='/HIWs/signup5.jpg'/>
            
            <h3 id='HIW-mobile'>Mobile</h3>
            <img id='signImgb' alt='image here' src='/HIWs/signup5b.png'/>
            
        <div className='HIW-nav-btn'>
            <button className='HIW-nav-btn→'><Link to="/Hhome" id='next'>next</Link></button>
        </div>
  </div>
}
// How it works home component
function Hhome() {
    return <div className='home_comp'>
        <h3>Tab Navigation</h3>
        <h3 id='steps'>Home</h3>
        <img id='signImg' alt='image here' src='/HIWs/home1.png'/>

        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/home1b.png'/>
        <br/>
 
        <div className='HIW-nav-btn'>
            <button className='HIW-nav-btn←'><Link to="/Hintro" id='back'>back</Link></button>
            <button className='HIW-nav-btn→'><Link to="/Hbrowse" id='next'>next</Link></button>
        </div>
    </div>
}
// How it works Browse component
function Hbrowse() {
    return <div className='browse_comp'>
        <h3>Tab Navigation</h3>
        <h3 id='steps'>Browse</h3>
        <img id='signImg' alt='image here' src='/HIWs/browse.png'/>

        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/browseb.png'/>
        
        <div className='HIW-nav-btn'>
            <button className='HIW-nav-btn←'><Link to="/Hhome" id='back'>back</Link></button>
            <button className='HIW-nav-btn→'><Link to="/HmotherLand" id='next'>next</Link></button>
        </div>
    </div>
}
// How it works Mother Land component
function HmotherLand() {
    return <div className='mother_comp'>
        <h3>Tab Navigation</h3>
        <h3 id='steps'>MotherLand</h3>
        <img id='signImg' alt='image here' src='/HIWs/mother1.png'/>
 
        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/mother1b.png'/>
        <br/>

        <img id='signImgb' alt='image here' src='/HIWs/mother1c.png'/>
        <br/>
        
        <div className='HIW-nav-btn'>
            <button className='HIW-nav-btn←'><Link to="/Hbrowse" id='back'>back</Link></button>
            <button className='HIW-nav-btn→'><Link to="/Hwrite" id='next'>next</Link></button>
        </div>
    </div>
}
// How it works Write component
function Hwrite() {
    return <div className='write_comp'>
        <h3>Tab Navigation</h3>
        <h3 id='steps'>Write</h3>
        <img id='signImg' alt='image here' src='/HIWs/write1.png'/>
        <br/>

        <h3 id='steps'>Write a story</h3>
        <img id='signImg' alt='image here' src='/HIWs/addstory.png'/>
        
        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/addstoryb.png'/>
        <br/>
    
        <h3 id='steps'>All stories</h3>
        <img id='signImg' alt='image here' src='/HIWs/shortstories.png'/>
        
        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/shortstoriesb.png'/>
        <br/>

        <h3 id='steps'>Complete stories</h3>
        <img id='signImg' alt='image here' src='/HIWs/books.png'/>
        
        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/booksb.png'/>
        <br/>
        
        <div className='HIW-nav-btn'>
            <button className='HIW-nav-btn←'><Link to="/HmotherLand" id='back'>back</Link></button>
            <button className='HIW-nav-btn→'><Link to="/Hfeatures" id='next'>next</Link></button>
        </div>
    </div>
}
// How it works Feature component
function Hfeatures() {
    return <div className='feature_comp'>
        <h3 >The Premium, Light and Dark mode features</h3>
        <h3 id='steps'>Try premium</h3>
        <img id='signImg' alt='image here' src='/HIWs/premium.png'/>
        
        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/premiumb.png'/>
        <br/>
        <br/>

        <h3 id='steps'>Light Mode</h3>
        <img id='signImg' alt='image here' src='/HIWs/mode1.png'/>

        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/mode1b.png'/>
        <br/>
        <br/>

        <h3 id='steps'>Dark Mode</h3>
        <img id='signImg' alt='image here' src='/HIWs/mode2.png'/>

        <h3 id='HIW-mobile'>Mobile</h3>
        <img id='signImgb' alt='image here' src='/HIWs/mode2b.png'/>
        
        <div className='HIW-nav-btn'>
            <button className='HIW-nav-btn←'><Link to="/Hwrite" id='back'>back</Link></button>
        </div>
    
    </div>
}