import React,{useState} from 'react'
import { ref } from './MotherLandCountry';
import { arrayUnion } from 'firebase/firestore';
import './MotherLand.css';

function EditFbDoc({seteditbox,country,code,value,history,title}) {
    const [editBackground, setEditBackground] = useState("");
    const [editHistory, setEditHistory] = useState("");
    const [editPolitics, setEditPolitics] = useState("");
    const [editDemography, setEditDemography] = useState("");
    const [editResources, setEditResources] = useState("");
    const [editCulture, setEditCulture] = useState("");
    
// Function to update the text
    function editDoc(updtDoc){
        ref
        .doc(updtDoc.id)
        .update(updtDoc)
        // catch errors and display to console
        .catch((err)=> {
            alert(err)
            console.error(err);
        })
    }
    console.log("value is: ", title);
    return (
        <div className='textedit-box' >
            {/* input text area for the new text */}
            
            <div className='textarea'>
                <textarea type="text" id='text' placeholder='Enter text here' 
                defaultValue={history}
                 onChange={(e) =>{
                     
                    setEditBackground(e.target.value)                     
                    setEditHistory(e.target.value)
                    setEditDemography(e.target.value)
                    setEditCulture(e.target.value)
                    setEditResources(e.target.value)
                    setEditPolitics(e.target.value)
                    
                    } } />
            </div>
            <br/>
            <button id='update-btn' className='btn-primary' onClick={()=>{
               
                if(title === "bg"){
                    editDoc({
                        id: code,
                        Background: editBackground
                    })
                }
                else if(title === "hst"){
                    editDoc({
                        id: code,
                        History: editHistory
                    })
                }
                else if(title === "plts"){
                    editDoc({
                        id: code,
                        Politics: editPolitics
                    })
                }
                else if(title === "dmgp"){
                    editDoc({
                        id: code,
                        Demography: editDemography
                    })
                }
                else if(title === "cul"){
                    editDoc({
                        id: code,
                        Culture: editCulture
                    })
                }
                else if(title === "nr"){
                    editDoc({
                        id: code,
                        Resources: editResources
                    })
                }
                // editDoc({
                //     id: code,
                //     Background: editBackground,
                //     History: editHistory,
                //     Culture: editCulture,
                //     Demography: editDemography,
                //     Politics: editPolitics,
                //     Resources: editResources,
                // })
                //edit function called and to update history field
                seteditbox(false)
                document.getElementById("text").value="";//reset text area
            }}>update</button>
        </div>
    )
}

export default EditFbDoc;
