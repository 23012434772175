import { useEffect, useState } from "react";
import { useAuth, upload } from "../../config/fire";
import { shallowEqual, useSelector } from "react-redux";
import Avatar from '@mui/material/Avatar';
import "./Profile.css"
import ProfileNav from "./ProfileNav";
import fire from "../../config/fire";
import { getAuth} from "firebase/auth";
import Notify from "./Notify";


export default function Profile() {
  const [userDetails, setUserDetails] =useState({});
  const currentUser = useAuth();
  const auth = getAuth();
  const user = auth.currentUser;
    const ref = fire.firestore().collection("UserAbout");
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState("/images/user.png");
  const [disable, setDisable] = useState(false);




  function handleChange(e) {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0])
      alert("Click the Edit Profile button to Upload");

    }
  }

  function handleClick() {
    upload(photo, currentUser, setLoading);
    setDisable(true);



  }

  useEffect(() => {
    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL);
    }
  }, [currentUser]);



  function getData(){
    if (user !== null) {
      
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;
    
      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;

      
      ref
      .doc(uid)
      .get()
      .then((snapshot) =>{
        if(snapshot){
          setUserDetails(snapshot.data());
        }
      });


     

    }

  }
  useEffect(() => {
 getData();
  }, [])

  return (
    <div className="fields">
      {/* profile picture upload */}
              <div  className="profileCover">

              <label class="filebutton">
              <i class="fas fa-camera "></i>
            <span><input type="file" onChange={handleChange}  /></span>
              </label>
              <>
                <Avatar
                      className="avatar"
                  alt="Remy Sharp"
                  src={photoURL}
                  sx={{ width: 156, height: 156 }}
                />
                </>
            {/* user proflie details */}
            <div className="userN">
            @{userDetails.name}
            </div>
           

              <br />
              <div className="form-group">
                     
                      <input     
                      type="submit"           
                      value={disable ? 'Uploading...' : 'Edit Profile'}
                      disabled={disable} 
                      className="btn btn-primary uploadImg"
                      onClick={handleClick}
                      />
              </div>

              </div>
              <>
              <ProfileNav />
              <div className="AboutUser">
     
     <Notify />
    </div>


              </>
    </div>
  );
}