import "./WritersForm.css"
import React, { useState, useEffect } from "react";
import fire from "../../config/fire";
import { getAuth } from "firebase/auth";
import { useHistory, Link,  useRouteMatch  } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";


const MotherLandForm = () => {

const auth = getAuth();
const getauth = auth.currentUser
const ref = fire.firestore().collection("WritersPass ");
const dispatch = useDispatch();


  const [passList, setPassList] = useState({});
  const [pass, setPass] = useState("");
  const history = useHistory();

  async function getData() {
    if (getauth !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = getauth.uid;
      ref
        .doc(uid)
        .get()
      .then((snapshot) => {
        if (snapshot) {
          setPassList(snapshot.data());

        }
      });
    }
  }


 function CheckPass (e) {
  e.preventDefault();
  if(pass <= "")  
  {  
    alert("Invalid password, please signUp to get Writers verification Code")  
    
}else if(`${passList.Password}`.indexOf(pass) !== -1)  
{  
  history.push("/admin/dashboard/MotherLand");
  
}    
else  
{  
  alert("Invalid password, please signUp to get Writers verification Code")  
}
 }

 useEffect(() => {
  getData();
}, []);


    // Data declaration
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    useEffect(() => {
      fire.auth().onAuthStateChanged((user) => {
        if (user) {
          if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
          if (window.location.pathname === "/admin")
            history.push("/admin/dashboard");
          history.push(window.location.pathname);
        } 
        else {
          history.push("/admin/login");
          
        }
      });
    }, [dispatch]);    


  return (
    <div>
    <div className="body_wrapper frm-vh-md-100">
  <div className="formify_body" data-bg-color="#fafafa">
    <div className="f_content">
      <div className="formify_box_three formify_login">
        <div className="container custom_height">
          <div className="row custom_height">
            <div className="col-md-6 formify_content_left">
              <div className="formify_logo_wrapper">
                <a href="index.html">
                  <img src="assets/img/logo.png" alt="" />
                </a>
                <p style={{marginTop:"-15%"}}>
                “There is no greater agony than bearing an untold story inside you.” “Don't bend; don't water it down; don't try to make it logical; don't edit your own soul according to the fashion. Rather, follow your most intense obsessions mercilessly.” “If the book is true, it will find an audience that is meant to read it.”
                </p>
              </div>
              <img
                className="img-fluid img"
                src="/img/logo.png"
                alt=""
              />
            </div>
            <div className="col-md-6 formify_content_right">
              <div className="formify_box">
               
                <h4 className="form_title">Sign Up To Become A Writer </h4>
                <div className="form-group">
                    <Link
                      type="submit"
                      to="/admin/dashboard/contactForm"
                      className="btn action_btn thm_btn text-transform-inherit btn-primary"
                    >
                      Sign UP
                    </Link>
                  </div>
                <form action="#" className="signup_form">
                  <div className="form-group">
                    
                    <div className="input">
                      
                      <img
                        className="input_icon"
                        src="assets/img/at.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="input_title" htmlFor="inputPassword">
                      Password 
                    </label>
                    <div className="input">
                      <input
                        type="password"
                        className="form-control"
                        maxlength="6"
                        placeholder="Enter Login Password"
                        required=""
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                      />
                      <img
                        className="input_icon"
                        src="assets/img/lock.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      onClick={CheckPass}
                      className="btn action_btn thm_btn text-transform-inherit btn-primary"
                    >
                      Login
                    </button>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default MotherLandForm;
