import React,{useState} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { postDel } from "../../redux/actionCreators/postsActionCreator";
import parse from 'html-react-parser';
import { storage } from "../../config/fire";
import fire from "../../config/fire";



// THis page is conected to the Posts page to display the cards which are the stories on My stories page
const PostCard = ({ post, id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = fire.firestore().collection(`${post.post.language}`);  


  const allImputs = {imgUrl: ''}
  const [imageAsFile, setImageAsFile] = useState('')
  const [imageAsUrl, setImageAsUrl] = useState(allImputs)


  const handleImageAsFile = (e) => {
    const image = e.target.files[0]
    setImageAsFile(imageFile => (image))
}
const reupload = (e) => {
  e.preventDefault()
  if(imageAsFile === '') {
    console.error(`not an image, the image file is a ${typeof(imageAsFile)}`);
    return toast.info(`not an image, the image file is a ${typeof(imageAsFile)}`);

  }else {
  const uploadTask = storage.ref(`/${post.post.language}/${post.postId}`).put(imageAsFile)
  //initiates the firebase side uploading 
  uploadTask.on('state_changed', 
  (snapShot) => {
    //takes a snap shot of the process as it is happening
    console.log(snapShot)
  }, (err) => {
    //catches the errors
    console.log(err)
  }, () => {
    // gets the functions from storage refences the image storage in firebase by the children
    // gets the download url then sets the image from firebase as the value for the imgUrl key:
    storage.ref(`${post.post.language}`).child(`${post.postId}`).getDownloadURL()
     .then( fireBaseUrl => {
       setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
       createDoc({image: fireBaseUrl, id: post.postId});
     })
  })
}
}
  const [language, setLanguage] = useState (post.post.language);
  const postDelete = () => {

    const data = {
      language
 
    };
    dispatch(postDel(post.postId, data));
    toast.success("Post deleted successfully!");
  };

  function createDoc(newDataObj){
    ref
      .doc(post.postId)
      .update( newDataObj)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })

    

}
  return (
    <div className="card col-md-5 px-0 m-2" key={id}>
      <img
        className="cards__item__img cards1"
        src={post.post.image}
        alt={post.post.title}
      />
      <div className="card-body card-body2">
        <h5 className="card-title">{post.post.title.toUpperCase()}</h5>
        <p className="card-text ">
          {parse(post.post.description.substring(0, 100))}...
        </p>
      </div>
      <div className="card-footer p-5 bg-white">
        <div className="d-flex align-items-center my-2 justify-content-between">
          <p>
            Likes {post.post.likes}
          </p>
          {/* <p className="bg-dark text-white py-1 px-2">{post.post.postedBy}</p> */}
        </div>
        <div className="">
          <button
            className="btn btn-primary  my-2 btn-block"
            onClick={() => history.push(`/admin/dashboard/post/${post.postId}`)}
          >
             Read Story
          </button>
          <div className="text-right">
            <button
              type="button"
              onClick={() =>
                history.push(`/admin/dashboard/post/${post.postId}/edit`)
              }
              className="btn btn-outline-primary  my-2  mx-1"
            >
            Edit Story
            </button>
            <button
              type="button"
              onClick={postDelete}
              className="btn btn-danger my-2ss"
            >
               Delete Story
            </button>


            <div className="Form-label">Re-Upload Cover</div>
              <img src={imageAsUrl.imgUrl} className="rowPoster" alt="image tag" />

            <div className="form-group">
   
               <input
          type="file"
          onChange={handleImageAsFile}
                  accept="image/jpeg, image/jpg"
                />
     </div>
     <button type="submit" onClick={reupload}>Upload</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
