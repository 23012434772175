import React, { useState } from "react";
import { Form, Button, Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Avatar from '@mui/material/Avatar';




const Register = ({ registerUser }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    registerUser({ name, email, password, confirmPassword });
  };

  var viewport = document.querySelector('meta[name=viewport]');
  var viewportContent = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0';
  
  if (viewport === null) {
    var head = document.getElementsByTagName('head')[0];
    viewport = document.createElement('meta');
    viewport.setAttribute('name', 'viewport');
    head.appendChild(viewport);
  }
  
  viewport.setAttribute('content', viewportContent);
  

  return (
    <>
    <div
     className=" style"
     style={{ minHeight: "90vh" }}
    >
    <div className="style2" style={{ 
    }} >

  
    <Card style={{ minWidth: "65vh",minHeight: "50vh" }} className="globe">
      <Card.Body >
        <h2 className=" text-center mb-4" style={{color: "darkgrey", fontSize: "16px"}}>Create an account</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group >
          <PhoneInput className="regwidth" style={{color: "#EF4343", fontFamily: "calibre", background: "White", height: "45px", fontSize: "20px"}}
          international
          defaultCountry="ZM"
      value={name}
      onChange={setName} />
          </Form.Group>
          <br />
          <Form.Group>
         
            <Form.Control className="ewidth"     style={{borderColor: "#EF4343", fontFamily: "calibre", background: "White", height: "45px", fontSize: "20px"}}
               type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            /> 
          </Form.Group>
          <br /> 
          
          
          <Form.Group>  
            <div className="input-group input-group-lg bg-white">
            <Form.Control  style={{borderColor: "#EF4343", fontFamily: "calibre", background: "White"}}
               type={passwordShown ? "text" : "password"} 
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id="Password"
             />
            <span className="input-group-text rounded-end border-0 text-secondary px-3" style={{background:"#EF4343"}}>
             <i onClick={togglePassword} class="bi bi-eye-fill" style={{color:"white"}}></i>
      </span> </div>
          </Form.Group>
      
          <br />
          <Form.Group>
          <div className="input-group input-group-lg bg-white" >
            <Form.Control  style={{borderColor: "#EF4343", fontFamily: "calibre", background: "White"}}
                type={passwordShown ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
             />
                <span className="input-group-text rounded-end border-0 text-secondary px-3" style={{background:"#EF4343", color:"white"}}>
             <i onClick={togglePassword} class="bi bi-eye-fill" style={{color:"white"}}></i>
      </span> </div>
        
          </Form.Group>
          <br />
          <Button className="nxt" style={{minWidth: "50px", maxWidth:"200px", width: "200px", fontWeight: "bold", fontFamily: "Nunito,sans-serif", fontSize: "22px", borderColor: "#EF4343", background: "#EF4343", color: "white", height: "50px", borderRadius: "50px"}} type="submit">
           NEXT
          </Button>
        </Form>
      </Card.Body>
    </Card>
    <div className="w-100 text-center mt-2 " style={{color: "black"}}>
      Already have an account? <Link to="/admin/login" >Log In</Link>
    </div>
    </div>
   </div>

   <div><img class="img11" src="/images/2022-05-17-17-47-06.png" width="620" height="560" style={{marginTop: "-600px"}}></img></div>
  </>
  );
};

export default Register;
