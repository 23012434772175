import React,{useEffect, useState} from 'react';
import Chats from './Chats';
import { useParams } from "react-router-dom";
import fire from "../../config/fire";
import { getAuth } from "firebase/auth";



function OthersChat() {
  const auth = getAuth();
  const user = auth.currentUser;
  const ref = fire.firestore().collection("UserAbout");
  const ref1 = fire.firestore().collection("UserAbout");

  const [otherUserDetail, setOtherUserDetail] = useState({});
  const [senderDetails, setSenderDetails] = useState({});

  const { otherUserid } = useParams();

  function getOtherUserData() {
    const uid = otherUserid; /// others profile uid
    ref
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          setOtherUserDetail(snapshot.data());

        }
      });
  }


  function getSenderData(){
  
      const uid = user.uid;
  
      
      ref1
      .doc(uid)
      .get()
      .then((snapshot) =>{
        if(snapshot){
          setSenderDetails(snapshot.data());
        }
      });
  

  
  
  }
  useEffect(() => {
    getOtherUserData();
    getSenderData();
  }, []);

  return <>
    <Chats name={`${senderDetails.name}`} fileName={`${otherUserDetail.name}`}/>
  </>;
}

export default OthersChat;
