import React, { useState, useEffect } from "react";
import { Form, Button, Card} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./Register.css";
import 'react-phone-number-input/style.css'
import fire from "../../config/fire";
import Avatar from '@mui/material/Avatar';
import { getAuth} from "firebase/auth";
import { toast } from "react-toastify";
import { shallowEqual, useSelector } from "react-redux";





const Nxt = () => {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const auth = getAuth();
  const user = auth.currentUser;
    const ref = fire.firestore().collection("UserAbout");
  const [name, setName] = useState("");
  const [boi, setBoi] = useState("");
  const [location, setLocation] = useState("");



  const handleSubmit = (e) => {
    e.preventDefault();
    if ( !name || !boi || !location ) {
      return toast.warning("Please fill in all fields!!");
    }


    createDoc({boi, location, name});



  };
  function createDoc(newDataObj){
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;
    
      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;

      ref
      .doc(uid)
      .set(newDataObj)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })
    }

    history.push("/");

}


  return (
    <>

    <div
     className="d-flex align-items-center signupM"
     style={{ minHeight: "90vh" }}
    >
    <div className="w-10" style={{ maxWidth: "800px", marginTop: "20px"  }}>

  
    <Card style={{ minWidth: "50vh",minHeight: "50vh" }} className="globe">
      <Card.Body>
        <h2 className="changeColor text-center mb-4">Sign Up</h2>
        <Form onSubmit={handleSubmit}>
        <lable><b>Name</b></lable>
        <Form.Group>
            <Form.Control 
               type="text"
                name="name"
                placeholder="First Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <br />
        <Form.Group>
        <lable><i class="fas fa-map-marker-alt"></i><b>Location</b></lable>
        <br/> 
        <input 
        type='text'
        id="location"
        onChange={(e) => setLocation(e.target.value)}
        />
        </Form.Group>
        <br/>
        <Form.Group>
        <label className='changeColor'><i class="far fa-address-card"></i><b>Boi</b></label>
        <br />
       <textarea
                         placeholder="About you"
                         className="form-control"
                         rows="5"
                         id='boi'
                         onChange={(e) => setBoi(e.target.value)}
                      
       >

       </textarea>
       </Form.Group>
        <br />
          <Button  className="w-100" type="submit">
            Finish Sign Up
          </Button>
        </Form>
      </Card.Body>
    </Card>
    </div>
   </div>
   <div className="col-md-8 mb-5 shadow p-5 signupInfo ">
    <h2 className="txtcenter">Sign up for a Reading Account</h2>
    
    <>
                <Avatar
                      className="signupImg"
                  alt="Remy Sharp"
                  src="/images/signup.jpg"
                  sx={{ width: 156, height: 156 }}
                />
                </>
    <p className="info">
        In order to access the great wealth of stories on Oniontable, you need to create a reading account. 
    We shall need you number and email address to maximise your experience. We also need to protect the 
    work of the writers who submit to this platform. Hence, we shall need to be able to reach you at any 
    point to clarify a purchase of a book or story.

         Additionally, we also need to protect you, our dear. If for some reason, you have purchased a
    book or story, and money has been debited from your account but you have not gotten access to
    the book you have bought, we need to get in touch with you to initiate the refund process
    </p>
   </div>
  </>
  );
};

export default Nxt;
