import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Form, Button, Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext"
import "./index.css"




const Login = ({ loginUser }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };



  useEffect(() => {
    if (isLoggedIn) history.push("/admin/dashboard/Homein");
   
    
  });

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !pass) {
      return toast.warning("Please fill in all fields");
    } else {
      loginUser(email, pass);
    }
  };

  return (
    <>
    {/* **************** MAIN CONTENT START **************** */}
    
    <main style={{marginTop:"-2%", backgroundColor:"white"}}>
      <section className="d-flex align-items-center position-relative overflow-hidden">
        <div className="container-fluid ">
          <div className="row">
            {/* left */}
            <div className="col-12 col-lg-6 d-md-flex align-items-center justify-content-center">
              <div    style={{marginTop:"-35%"}}>
              
                {/* SVG Image */}
                <img src="/images/2022-05-11-16-25-34.png.png" width="620px" height="550px" style={{marginTop: "235px", marginLeft: "25px"}} className="img10" id="image-log" alt="" 
                />
                {/* Info */}
               
              </div>
            </div>
            {/* Right */}
            <div className="col-12 col-lg-6" style={{marginTop: "-23px"}}>
              <div className="row my-5">
                <div className="col-sm-10 col-xl-8 m-auto">

                 
                  {/* Form START */}
                  <form  onSubmit={handleSubmit}>
                    {/* Email */}
                    <div className="mb-4" style={{marginTop: "90px"}}>
                      
                      <div className="input-group input-group-lg" style={{background:"white"}}>
                        <span className="input-group-text rounded-start border-0" style={{background:"#EF4343"}}>
                          <i className="bi bi-envelope-fill" style={{color: "white"}}/>
                        </span>
                        <input style={{borderColor: "#EF4343", fontFamily: "calibre", background: "white"}}
                          type="text"
                          name = "email"
                          className="form-control rounded-end shadow-none"
                          placeholder="E-mail"
                          id="exampleInputEmail1"
                          value={email}
                          onChange={(e) => setEmail(e.target.value.trim())}
                        />
                      </div>
                    </div>
                    {/* Password */}
                    <div className="mb-4" style={{background: "white"}}>

                      <div className="input-group input-group-lg bg-white" style={{background: "white"}}>
                        <span className="input-group-text border-0 text-secondary px-3" style={{background:"#EF4343"}}>
                          <i className="fas fa-lock" style={{color: "white"}} />
                        </span>
                        <input style={{borderColor: "#EF4343", fontFamily: "calibre", background: "White"}}
                         type={passwordShown ? "text" : "password"} 
                          name="password"
                          className="form-control bg-white shadow-none"
                          placeholder="password"
                          id="inputPassword5"
                          value={pass}
                          onChange={(e) => setPass(e.target.value)}
                        />
                                <span className="input-group-text rounded-end text-secondary px-3" style={{background: "White", borderColor: "#EF4343", color:"white"}}>
             <i onClick={togglePassword} class="bi bi-eye-fill" style={{color:"#EF4343", cursor:"pointer"}}></i>
      </span>
                      </div>
                      <div id="passwordHelpBlock" className="form-text" style={{fontFamily:"Nunito,sans-serif"}}>
                        Your password must be at least 8 characters 
                      </div>
                    </div>
                    {/* Check box */}
                    <div className="mb-4 d-flex justify-content-between mb-4" style={{marginTop: "-15px"}}>
                    
                      <div className="text-primary-hover">
                      
                        <Link to="/admin/ForgotPass"><u style={{letterSpacing: "0.5px", fontFamily:"Nunito,sans-serif", marginLeft: "127px", fontSize: "14px", textDecoration: "none", color: "darkgrey"}}>Forgot password?</u></Link>
                       
                      </div>
                    </div>
                    {/* Button */}
                    <div className="align-items-center mt-0">
                      <div className="d-grid">
                        <button style={{ marginTop: "-15px", fontWeight: "bold", fontFamily: "Nunito,sans-serif", fontSize: "22px", borderColor: "#EF4343", background: "#EF4343", color: "white", height: "50px", borderRadius: "50px"}} type="submit">
                          LOGIN
                        </button>
                      </div>
                    </div>
                  </form>
                  {/* Form END */}
                  {/* Social buttons and divider */}
                  <div className="row">
                    {/* Divider with text */}
                    <div className="position-relative my-4" >
                      <hr style={{marginTop: "6px", color: "black"}} />
                      <p className="small position-absolute top-50 start-50 translate-middle bg-body px-5" style={{marginTop: "-8px", fontSize: "15px"}}>
                        Or
                      </p>
                    </div>
                    
                  
                  {/* Sign up link */}
                  <div className="mt-1 text-center">
                    <button class="signup">
                      <Link to="/admin/register" style={{color: "white", fontFamily: "Nunito,sans-serif"}}>SIGN UP</Link>
                    </button>
                  </div>
                </div>
              </div>{" "}
              {/* Row END */}
            </div>
          </div>{" "}
          {/* Row END */}
        </div>
        </div>
      </section>
    </main>
    {/* **************** MAIN CONTENT END **************** */}
  </>
  
    

  );
};

export default Login;
