export const MenuItemstwo = [

    {
      
      title: 'English',
      path: '/admin/dashboard/English',
      cName: 'dropLt'
    },
    // {
      
    //   title: 'Swahili',
    //   path: '/admin/dashboard/Swahili',
    //   cName: 'dropLt'
    // },
    {
      title: 'Bemba',
      path: '/admin/dashboard/Bemba',
      cName: 'dropLt'
    },
    {
      title: 'Poetry',
      path: '/admin/dashboard/Poetry',
      cName: 'dropLt'
    },

  
  
    
  
  ];
  