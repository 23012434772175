import React, { useState, useEffect, useRef } from "react";
import {  useHistory, useParams } from "react-router-dom";
import fire from "../../config/fire";
import { Editor } from '@tinymce/tinymce-react'; 
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { serverTimestamp } from "firebase/firestore";
import { ProgressBar } from 'react-bootstrap';
import "./AddPost.css";
import { storage } from "../../config/fire";
import { v4 as uuidv4 } from 'uuid';





function EditChapter () {
  const ref = fire.firestore().collection("Books");

    // const [title, setTitle] = useState("");
    // const [language, setLanguage ] = useState("");
    
    const [c1, setC1] = useState("");
    const [c2, setC2] = useState("");
    const [c3, setC3] = useState("");
    const [c4, setC4] = useState("");
    const [c5, setC5] = useState("");
    
    const [c6, setC6] = useState("");
    const [c7, setC7] = useState("");
    const [c8, setC8] = useState("");
    const [c9, setC9] = useState("");
    const [c10, setC10] = useState("");
    
    const [c11, setC11] = useState("");
    const [c12, setC12] = useState("");
    const [c13, setC13] = useState("");
    const [c14, setC14] = useState("");
    const [c15, setC15] = useState("");
    
    const [c16, setC16] = useState("");
    const [c17, setC17] = useState("");
    const [c18, setC18] = useState("");
    const [c19, setC19] = useState("");
    const [c20, setC20] = useState("");

    const [chapters, setChapters] = useState({});
    // const [chapter2, setChapter2]  = useState("");
    // const [chapter3, setChapter3]  = useState("");
    // const [chapter4, setChapter4]  = useState("");
    // const [chapter5, setChapter5]  = useState("");

    const { bookid } = useParams();

    // The hidding style #By Tesla
      const [hideDivs, setHideDivs]  = useState("HideMe");
      const changeStyle = () => {
      
        setHideDivs("DisplayMe");
      };

      const [hideDivs1, setHideDivs1]  = useState("HideMe");
      const changeStyle1 = () => {
      
        setHideDivs1("DisplayMe");
      };
    // const [image, setImage] = useState("");
    // const [imageURL, setImageURL] = useState("");

    // const [chapters, setChapters] = useState("");

    // const ref = fire.firestore().collection("Books").doc(`file: ${language} `).collection(`file: ${language} `);
        // const [data, setdata] = useState({});
        // const [loader, setloader] = useState(true);
        const allImputs = {imgUrl: ''}
    const [imageAsFile, setImageAsFile] = useState('')
    const [imageAsUrl, setImageAsUrl] = useState(allImputs)
    
        const handleImageAsFile = (e) => {
          const image = e.target.files[0]
          setImageAsFile(imageFile => (image))
      }

      function getChapters() {
        const uid = bookid; /// others profile uid
        ref
          .doc(uid)
          .get()
          .then((snapshot) => {
            if (snapshot) {
              setChapters(snapshot.data());
    
            }
          });
      }
        // const handleFireBaseUpload = e => {
        //   e.preventDefault()
        //   if (!title || !number || !chapter1) {
        //     return toast.warning("Please fill in all fields!");
        //   }
      
     
      
        //   if (chapter1.length < 100) {
        //     return toast.info("Chapters contents should be of atleast 100");
        //   }
        //   if (title.trim().split(" ").length < 2) {
        //     return toast.info("Title should be of atleast 2 words");
        //   } else {
        //     console.log('start of upload')
        //     // async magic goes here...
        //     if(imageAsFile === '') {
        //       console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
        //     }
        //     const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
        //     //initiates the firebase side uploading 
        //     uploadTask.on('state_changed', 
        //     (snapShot) => {
        //       //takes a snap shot of the process as it is happening
        //       console.log(snapShot)
        //     }, (err) => {
        //       //catches the errors
        //       console.log(err)
        //     }, () => {
        //       // gets the functions from storage refences the image storage in firebase by the children
        //       // gets the download url then sets the image from firebase as the value for the imgUrl key:
        //       storage.ref('images').child(imageAsFile.name).getDownloadURL()
        //        .then( fireBaseUrl => {
        //          setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
        //          createDoc({title,id: uuidv4(),   image: fireBaseUrl ,  chapter1, chapter2, chapter3, chapter4, chapter5,  timestamp: serverTimestamp()});

        //        })
        //     })

        //   }

        
        // }
    const history = useHistory();
    const [disable, setDisable] = React.useState(false);
    const [progress, setProgress] = useState(0);
    

  //   function getData(){
  //     ref
  //     // .orderBy('image','desc')
  //     .onSnapshot((querySnapshot)=> {
  //         const items = []
  //         querySnapshot.forEach((doc)=>{
  //             items.push(doc.data())
               
  //         })
  //         setdata(items)
  //         setloader(false)
  //     })
  // }

  function editDoc(updtedDoc){
    ref
      .doc(bookid)
      .update(updtedDoc)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })

    

}

// function pasteText(e){
//   console.log(e.clipboardData.getData('Text'));
//   console.log(e.clipboardData.getData('Text'));
//   console.log(e.clipboardData.getData('text/plain'));
//   console.log(e.clipboardData.getData('text/html'));
//   console.log(e.clipboardData.getData('text/rtf'));

//   console.log(e.clipboardData.getData('Url'));
//   console.log(e.clipboardData.getData('text/uri-list'));
//   console.log(e.clipboardData.getData('text/x-moz-url'));

// }

const handleEditorChange1 = (e) => {
    setC1(
    e.target.getContent()
    );
  }
    const handleEditorChange2 = (e) => {
      setC2(
      e.target.getContent()
      );
    }

    const handleEditorChange3 = (e) => {
      setC3(
      e.target.getContent()
      );
    }

    const handleEditorChange4 = (e) => {
      setC4(
      e.target.getContent()
      );
    }
    const handleEditorChange5 = (e) => {
      setC5(
      e.target.getContent()
      );
    }
    const handleEditorChange6 = (e) => {
      setC6(
      e.target.getContent()
      );
    }
    const handleEditorChange7 = (e) => {
      setC7(
      e.target.getContent()
      );
    }
    const handleEditorChange8 = (e) => {
      setC8(
      e.target.getContent()
      );
    }
    const handleEditorChange9 = (e) => {
      setC9(
      e.target.getContent()
      );
    }
    const handleEditorChange10 = (e) => {
      setC10(
      e.target.getContent()
      );
    }
    const handleEditorChange11 = (e) => {
      setC11(
      e.target.getContent()
      );
    }
    const handleEditorChange12 = (e) => {
      setC12(
      e.target.getContent()
      );
    }

    const handleEditorChange13 = (e) => {
      setC13(
      e.target.getContent()
      );
    }

    const handleEditorChange14 = (e) => {
      setC14(
      e.target.getContent()
      );
    }
    const handleEditorChange15 = (e) => {
      setC15(
      e.target.getContent()
      );
    }
    const handleEditorChange16 = (e) => {
      setC16(
      e.target.getContent()
      );
    }
    const handleEditorChange17 = (e) => {
      setC17(
      e.target.getContent()
      );
    }
    const handleEditorChange18 = (e) => {
      setC18(
      e.target.getContent()
      );
    }
    const handleEditorChange19 = (e) => {
      setC19(
      e.target.getContent()
      );
    }
    const handleEditorChange20 = (e) => {
      setC20(
      e.target.getContent()
      );
    }
    const uploadc1 = (e) => {
      e.preventDefault();
      editDoc({c1});
      setDisable(true);
      alert("Chapter Uploaded successfully");
      history.push("/admin/dashboard/Chapter");

  };
  const uploadc2 = (e) => {
    e.preventDefault();
    editDoc({c2});
    setDisable(true);
    alert("Chapter Uploaded successfully");
    history.push("/admin/dashboard/Chapter");

};
const uploadc3 = (e) => {
  e.preventDefault();
  editDoc({c3});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");

};
const uploadc4 = (e) => {
  e.preventDefault();
  editDoc({c4});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");

};
const uploadc5 = (e) => {
  e.preventDefault();
  editDoc({c5});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");

};
    const uploadc6 = (e) => {
        e.preventDefault();
        editDoc({c6});
        setDisable(true);
        alert("Chapter Uploaded successfully");
        history.push("/admin/dashboard/Chapter");

    };

    const uploadc7 = (e) => {
      e.preventDefault();
      editDoc({c7});
      setDisable(true);
      alert("Chapter Uploaded successfully");
      history.push("/admin/dashboard/Chapter");
  };

  const uploadc8 = (e) => {
    e.preventDefault();
    editDoc({c8});
    setDisable(true);
    alert("Chapter Uploaded successfully");
    history.push("/admin/dashboard/Chapter");
};

const uploadc9 = (e) => {
  e.preventDefault();
  editDoc({c9});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

const uploadc10 = (e) => {
  e.preventDefault();
  editDoc({c10});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

const uploadc11 = (e) => {
  e.preventDefault();
  editDoc({c11});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

  const uploadc12 = (e) => {
    e.preventDefault();
    editDoc({c12});
    setDisable(true);
    alert("Chapter Uploaded successfully");
    history.push("/admin/dashboard/Chapter");

};

const uploadc13 = (e) => {
  e.preventDefault();
  editDoc({c13});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");

};

const uploadc14 = (e) => {
  e.preventDefault();
  editDoc({c14});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");

};

const uploadc15 = (e) => {
  e.preventDefault();
  editDoc({c15});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

const uploadc16 = (e) => {
  e.preventDefault();
  editDoc({c16});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

const uploadc17 = (e) => {
  e.preventDefault();
  editDoc({c17});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

const uploadc18 = (e) => {
  e.preventDefault();
  editDoc({c18});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

const uploadc19 = (e) => {
  e.preventDefault();
  editDoc({c19});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

const uploadc20 = (e) => {
  e.preventDefault();
  editDoc({c20});
  setDisable(true);
  alert("Chapter Uploaded successfully");
  history.push("/admin/dashboard/Chapter");
};

    // editDoc({id: uuidv4(),   c1, c2, c3, c4, c5, c6, c7, c8, c9, c10,c11, c12, c13, c14, c15, c16, c17, c18, c19, c20,image: fireBaseUrl,   timestamp: serverTimestamp()});


    // function validateForm(){
    //  handleFireBaseUpload();
    //  handleSubmit();
    // }
    // const handleEditorChange = (e) => {
    //     setDescription(
    //     e.target.getContent()
    //     );
    //   }


 
    useEffect(() => {
      getChapters();
    }, []) 

    
  return (
    <div className="container">
    <div className="row w-100">
      <div className="col-md-12 mb-3">
        <h1 className="display-3 text-dark text-center">Add Story</h1>
      </div>
      <div className="col-md-10 mx-auto mb-5 shadow p-5" >
        {progress ? (
          progress !== 100 ? (
            <div className="mx-auto p-5">
              <h1 className="text-center my-2">
                Uploading Story - {progress}%
              </h1>
              <ProgressBar now={progress} max={100} animated/>
              <progress
                className="text-center form-control"
                max={100}
                value={progress}
              ></progress>
            </div>
          ) : (
            <div className="mx-auto p-5   text-center ">
              <i className="fa fa-tick text-success mx-auto my-2"></i>
              <h1 className="text-center my-2">Story Uploaded successfully</h1>
              <Link
                to={"/admin/dashboard/addPost"}
                // onClick={refreshPage}
                className="my-2 mx-auto btn btn-primary"
              >
               Finish
              </Link>
            </div>
          )
        ) : (
          <form  className="writerform" >
                  <div className="col-md-12 my-5 text-right">
        <Link to="/admin/dashboard/Chapter" className="btn btn-dark mr-2">
          Go Back
        </Link>
      </div>
         <img
                src={chapters.image}                                         
                className="rowPoster"
        />         
     <div className="form-group">
               <div className="Form-label">Change Story Cover: In JPG</div>
   
               <input
          type="file"
          onChange={handleImageAsFile}
          accept="image/jpeg, image/jpg"
                />
     </div>

  < div className={hideDivs}>

      {/* Chapter1 */}
      <h3>Chapter1</h3>
      <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc1}
          />
        </div>
    <div className="form-group">
              <Editor
        initialValue="first Chapter"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        
        onChange={handleEditorChange1}
      />


        </div>


   {/* Chapter2 */}
   <h3>Chapter2</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc2}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange2}
      />


        </div>

   {/* Chapter3 */}
   <h3>Chapter3</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc3}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange3}
      />


        </div>

   {/* Chapter4 */}
   <h3>Chapter4</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc4}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange4}
      />


        </div>

   {/* Chapter5 */}
   <h3>Chapter5</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc5}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange5}
      />


        </div>


        </div>     
        <span className="buttonMe" onClick={changeStyle}>
          Edit Chapter 1-5 
        </span>


      {/* Chapter6 */}
      <h3>Chapter6</h3>
      <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc6}
          />
        </div>
     
    <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange6}
      />


        </div>


   {/* Chapter7 */}
   <h3>Chapter7</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc7}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange7}
      />


        </div>

   {/* Chapter8 */}
   <h3>Chapter8</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc8}
          />
        </div><div className="form-group">
         
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange8}
      />


        </div>

   {/* Chapter9 */}
   <h3>Chapter9</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc9}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange9}
      />


        </div>

   {/* Chapter10 */}
   <h3>Chapter10</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc10}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange10}
      />


        </div>


        <div div className={hideDivs1}>

   {/* Chapter11 */}
   <h3>Chapter11</h3>

   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc11}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange11}
      />


        </div>

   {/* Chapter12 */}
   <h3>Chapter12</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc12}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange12}
      />


        </div>

  {/* Chapter13 */}
  <h3>Chapter13</h3>
  <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc13}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange13}
      />


        </div>

   {/* Chapter14 */}
   <h3>Chapter14</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc14}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange14}
      />


        </div>

   {/* Chapter15 */}
   <h3>Chapter15</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc15}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange15}
      />


        </div>
              
      {/* Chapter16 */}
      <h3>Chapter16</h3>
      <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc16}
          />
        </div>
    <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange16}
      />


        </div>


   {/* Chapter17 */}
   <h3>Chapter17</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc17}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange17}
      />


        </div>

   {/* Chapter18 */}
   <h3>Chapter18</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc18}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange18}
      />


        </div>

   {/* Chapter19 */}
   <h3>Chapter19</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc19}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange19}
      />


        </div>

   {/* Chapter20 */}
   <h3>Chapter20</h3>
   <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Upload"}
            disabled={disable}
            className="btn btn-primary uploadBooks"
            onClick={uploadc20}
          />
        </div>
   <div className="form-group">
              <Editor
        initialValue="<p>Initial content</p>"
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image', 
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange20}
      />


        </div>
  </div>
        <span className="buttonMe" onClick={changeStyle1}>
         Add more Chapters 
        </span>
<br />

              <img src={imageAsUrl.imgUrl} alt="image tag" />

              <div className="form-group">
                <input
                  type="submit"
                  className="btn btn-dark btn-block"
 
                />
              </div>

          </form>
        )}
      </div>
    </div>
  </div>
  )
}

export default EditChapter 