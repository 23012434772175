import {useState,useEffect, useRef} from "react"
import {Form,Button,Modal} from "react-bootstrap"
import { db, storage } from "../../config/fire";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "@firebase/firestore";
import "./QuotePage.css"
import {
 CalendarIcon,
 ChartBarIcon,
 EmojiHappyIcon,
 PhotographIcon,
 XIcon,
} from "@heroicons/react/outline";
import { getDownloadURL, ref, uploadString } from "@firebase/storage";
import { useDispatch, useSelector } from "react-redux";

function QuotePageInput() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const userId = useSelector((state) => state.auth.userId);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [writer, setWriter] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const filePickerRef = useRef(null);

  const sendPost = async () => {
      if (loading) return;
      setLoading(true);

  const docRef = await addDoc(collection(db, "proverbs"), {
      id: userId,
      quote_writer:writer,
      text: input,
      timestamp: serverTimestamp(),
    });

    const imageRef = ref(storage, `proverbs/${docRef.id}/image`);

    if (selectedFile) {
      await uploadString(imageRef, selectedFile, "data_url").then(async () => {
        const downloadURL = await getDownloadURL(imageRef);
        await updateDoc(doc(db, "proverbs", docRef.id), {
          image: downloadURL,
        });
      });
    }

    setLoading(false);
    setInput("");
    setSelectedFile(null);
   
  };

  const addImageToPost = (e) => {
      const reader = new FileReader();
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
  
      reader.onload = (readerEvent) => {
        setSelectedFile(readerEvent.target.result);
      };
    };

  return (
    <>


      <Button variant="primary" onClick={handleShow}
       style={{marginTop:"5%",width:"22%",marginLeft:"42%",height:"5%"}}
       className="shadow-lg1"
      id="uploadbtn"
     >
      Upload Picture + Quote
      </Button>

      <Modal show={show} onHide={handleClose}
       className="mode"
       style={{marginTop:"4%"}}
      >
        <Modal.Header closeButton>
          <Modal.Title className="title label">Fill Up  Quote Details Below </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label">Quote Writer's Name</Form.Label>
              <Form.Control
              value={writer}
              onChange={(e) => setWriter(e.target.value)}
                placeholder="Quote Writer's Name"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            >
              <Form.Label>Quote Text</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>

            <div
                className="icon"
                onClick={() => filePickerRef.current.click()}
              >
            <PhotographIcon 
            style={{width:"30px",height:"30px",marginLeft:"-1%"}}
            className="icon"
            />
          <input 
          type="file"
          hidden
          style={{width:"50%",marginLeft:"20%"}}
          className="form-control "
          ref={filePickerRef}
         
          onChange={addImageToPost}
          onClick={() => setSelectedFile(null)}
       
          />

{selectedFile && (
            <div className="position-relative" id="selected">
              <div
                    className="position-absolute w-8 h-8 bg-[#15181c] hover:bg-[#272c26] bg-opacity-75 rounded-full flex items-center justify-center top-1 left-1 cursor-pointer"
                onClick={() => setSelectedFile(null)}

                
              >
                
              </div>
              <img
                src={selectedFile}
                alt=""
                className=" rounded-1xl max-h-1 object-contain  "
                style={{width:"200px",height:"120px",marginTop:"-2%",marginLeft:"23%"}}
                id="photoinput"
              />
            </div>
          )}
          </div>
         
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" id="cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}
              disabled={!input && !selectedFile && !writer}
              id="sendbutton"
          >
            Post Quote
          </Button>
        </Modal.Footer>
      </Modal>

    
      </>
  );
}

export default QuotePageInput;