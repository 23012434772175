/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams ,Link} from "react-router-dom";
// import { toast } from "react-toastify";
import parse from 'html-react-parser';
import {Card,Button,Offcanvas,Figure,Carousel,Container,Row,Toast,Dropdown,Tab,Tabs,ListGroup} from "react-bootstrap"
import {Helmet} from "react-helmet";
import Avatar from "@mui/material/Avatar";


import {
  doComment,
  doReply,
  getPosts,
  deleteComment,
} from "../../redux/actionCreators/postsActionCreator";
import '../../admin/Dashboard/seePost.css'
import AddReply from "./AddReply";
import {  useHistory, useRouteMatch } from "react-router-dom";
import fire from "../../config/fire";

import "./seePost.css"
import {useAuth} from "../../config/fire";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { storage } from "../../config/fire";
import Mode2 from "./Mode2";

  



const ChapterView = () => {

  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lgShow, setLgShow] = useState(false);
  const [photoURL, setPhotoURL] = useState("/images/user.png");
  const [key, setKey] = useState('home');
  const auth = getAuth();
  const getauth = auth.currentUser
  const [author, setAuthor] = useState({});
  const ref = fire.firestore().collection("UserAbout");
  const ref1 = fire.firestore().collection("Books");
  // const ref3 = fire.firestore().collection("-Action");
  const ref2 = fire.firestore().collection("FromOurStarts");

  const [data1, setdata1] = useState([]);
  const [loader1, setloader1] = useState(true);

  const [othersFollowers, setOthersFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chapters, setChapters] =  useState({});
  const [chapters1, setChapters1] =  useState({});

  const [data, setdata] = useState([]);

     


  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
        if (window.location.pathname === "/admin")
          history.push("/admin/dashboard");
        history.push(window.location.pathname);
      } 
      else {
        history.push("/admin/login");
        
      }
    });
  }, [dispatch]);

  
// function get(){
//   ref1
//   .onSnapshot((querySnapshot)=> {
//       const items = []
//       querySnapshot.forEach((doc)=>{
//           items.push({ ...doc.data(), id: doc.id })
//       })            
//       setdata(items)
      
//   })
// }

  const { bookid } = useParams();


  function getChapters() {
    const uid = bookid; /// others profile uid
    ref1
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          setChapters(snapshot.data());

        }
      });
  }
  // function getChapters1() {
  //   const uid = bookid; /// others profile uid
  //   ref3
  //     .doc(uid)
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot) {
  //         setChapters1(snapshot.data());

  //       }
  //     });
  // }

  
function get1(){
  ref2
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items = []
      querySnapshot.forEach((doc)=>{
          items.push(doc.data())
           
      })
      setdata1(items)
      setloader1(false)
  })
}
 const [otherUserid, setOtherUserId] = useState("");

  const { posts, postsLoading, isLoggedIn, user, userId } = useSelector(
    (state) => ({
      posts: state.posts.posts,
      postsLoading: state.posts.postsLoading,
      isLoggedIn: state.auth.isLoggedIn,
      user: state.auth.user,
      userId: state.auth.userId,
    }),
    shallowEqual
  );


  // const currentPost = posts.find((post) => post.postId === id && post);
 
  useEffect(() => {
    if (postsLoading) {
      dispatch(getPosts());
    }
  }, [dispatch]);

  // useEffect(() => {
    
  //   if (currentPost) {
  //     getAuthordata();
  //   }
    
  // }, [currentPost]);
  
  useEffect(() => {
    // getChapters1();
    getChapters();
    getData();
    get1();
  }, []);

  async function getData() {
    setLoading(true)
    if (getauth !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = getauth.uid;
      await ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            // setUserDetails(snapshot.data());
            // console.log('following', snapshot.data().following)
            setFollowing(
              snapshot.data().following ? [...snapshot.data().following] : []
            );
          }
          setLoading(false)
        });
    }
  }

  // const getAuthordata = async () => {
  //  await ref
  //     .doc(currentPost.post.author)
  //     .get()
  //     .then((snapshot) => {
  //       if (snapshot) {
  //         // console.log(snapshot.data())
  //         setOtherUserId(snapshot.id);
  //         setAuthor(snapshot.data());
  //         setOthersFollowers(
  //           snapshot.data().followedBy ? snapshot.data().followedBy : []
  //         );
  //       }
  //     });
  // };

  function handleClick() {
    let temp = othersFollowers;
    let temp2 = following;
    // console.log(getauth)
    let isFollower = temp.find((f) => f == getauth.uid);
    let isfollowing = temp2.find((f) => f == otherUserid); /// others profile uid
    if (isFollower) {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayRemove(getauth.uid),
      });
      let filtered = temp.filter(t => t != getauth.uid)
      setOthersFollowers(filtered)
    } else {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayUnion(getauth.uid),
      });
      temp.push(getauth.uid)
      setOthersFollowers([...temp])
    }

    if(isfollowing){
      ref.doc(getauth.uid).update({
        following: firebase.firestore.FieldValue.arrayRemove(otherUserid), /// others profile uid
      });
      let filtered = temp2.filter(t => t != otherUserid) /// others profile uid
      setFollowing(filtered)
    } else {
      ref.doc(getauth.uid).update({
        following: firebase.firestore.FieldValue.arrayUnion(otherUserid), /// others profile uid
      });
      temp2.push(otherUserid) /// others profile uid
      setFollowing([...temp2])
    }
  }


//   const ProfileImgLink = () => {

//     // Getting Download Link
//     storage.ref(`${chapters.author}.png`).getDownloadURL()
//       .then((photoURL) => {
//         setPhotoURL(photoURL);
//       });
// }


 
  return (
    <div className="body-top">
    <>
    <nav className="sticky-top nav-res ">
    <div id="progress-bar" className="progress-bar" style={{ margin: "57px" }}></div>
     {/*Second Navbar */}
      </nav>
  {/* page loader */}
  <div className="theme-layout " style={{marginTop:"-5%"}}>
 
  
 
    {/* carousel menu */}
    <section>
      <div className="gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div id="page-contents" className="row merged20">
           
              <><div className="col-lg-9">
              
                            <div className="main-wraper shadow-lg p-3 mb-5 bg-light rounded txx image-bg">
                              <div className="row">
                                
                              <div className="card  bg-light " id="border-shadow" style={{ maxWidth: "740px",marginLeft:"5%" }}>
  <div className="row g-0 ">
    <div className="col-md-4 ">
      <img src={chapters.image} className="img-fluid rounded-start " alt="..." 
      style={{width:"130px",marginTop:"-3%"}}
      />
    </div>
    <div className="col-md-8 ">
      <div className="card-body bg-light">
        <h5 className="card-title  fw-normal">{chapters.title}</h5>
        <p className="card-text mb-0" style={{fontSize:"14px"}}>
        <i class="fas fa-eye eye"></i>0k |{" "} <i class="fas fa-star star"></i>0k | {" "} <i class="fas fa-comment comment"></i>0k <br/>
                          <span id="by" className="text-muted">Written By: </span> <span id="author" className="fs-2">{" "}</span>
                          <Link
                          to={`/admin/dashboard/othersProfile/${chapters.author}`}
                          >
          <span className="text-primary">{chapters.name ? chapters.name : ""}</span>{" "}
        </Link>
        </p>
       
        <p className="card-text" >
        
        <small className="lead text-info  m-3" style={{fontSize:"20px"}}>{chapters.category}</small>
          <small className="text-muted"><i class="fa fa-calendar " style={{fontSize:"16px"}}></i> {chapters.date}</small>
          <Button  
                     >
                            <i class="fas fa-plus" ></i>
                      </Button>
        </p>

      </div>
    </div>
  </div>
</div>


                              </div>

                            </div>



                            {/* books carousel */}
                          </div>
                          
                          <div className="col-lg-3  " style={{height:"120%"}}>
                              <aside className="sidebar static right card-up  rounded" >


                                <div className="widget shadow-lg p-3 mb-5 bg-light" >
                                 
                                  <div className="popular-book">

                                    

                                    <div className="book-about">
                                    <div class="card-design card-image-right">
                 
                 <Avatar
           className="profile-image"
           alt="Remy Sharp"
           src={author.ProfilePic}
           sx={{ width: 156, height: 156 }}
           style={{marginTop:"-5%"}}
         />
                  
                     <div class="information">
                     <div class="title1"> <label>{author.name ? author.name : ""}</label> </div>
                     <div className="btn-left">  <Link
                                 to={`/admin/dashboard/othersProfile/${chapters.author}`}
                               ><button className="btn-primary btn-sm " >View Profile</button></Link> 
                               <button className="btn-primary btn-sm" onClick={handleClick}>
                               {following.find((f) => f == otherUserid) ? <i class="fas fa-user-minus">Unfollow</i> : <i class="fas fa-user-plus"> Follow</i>}
                                 </button> </div>
          
                                     </div>
                                         <div class="top"></div>
                                         <div class="bottom"></div>
                                         <div class="left"></div>
                                         <div class="right"></div>
                                 </div>
                                    </div>
                                  </div>
                                 


                                </div>

                               
                              </aside>
                            </div>

                            <div className="col-lg-9 paragraph-up">
                              <div className="main-wraper shadow-lg p-3 mb-5 bg-light">
                              
                                
                              

                                <div className="row  text-muted">
                               <p>
                                {parse(`${chapters.c1}`)}
                                </p>
                                <br/>
                                <Mode2 />
                                  
                                </div>

                                
                              </div>

                              

                              {/* books carousel */}
                            </div><div className="col-lg-3">
                              <aside className="sidebar static right">


                                <div className="widget shadow-lg p-3 mb-5 bg-light">
                                  <h4 className="widget-title">Latest Stories</h4>
                                  {loader1 === false && (data1.map((doc) => <div className="popular-book">

                                    <figure>
                                      <Link to={`/post/${doc.id}/${doc.title}`}>
                                        <img src={doc.image} alt="" 
                                         className="image"
                                        />
                                      </Link>
                                    </figure>

                                    <div className="book-about">
                                      <h6>
                                        <a href="#" title="">
                                          {doc.title}
                                        </a>
                                      </h6>
                                      <span></span>
                                      <a href="#" title="Book Mark">
                                        <i className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={20}
                                            height={20}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-bookmark"
                                          >
                                            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                                          </svg>
                                        </i>
                                      </a>
                                    </div>
                                  </div>
                                  ))}


                                </div>

                               
                              </aside>
                            </div></>
               
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    
  </div>
</>

    </div>
  );
};


export default ChapterView;
