export default[
  {
    id:1,
    title:'Ulwendo Mukufwaya ine ...',
    image:'/images/ab.png',
    category:'Fantasy',
    lang:'Bemba'
  },
  {
    id:2,
    title:'My Adventure to ...',
    image:'/images/ab.png',
    category:'Romance',
    lang:'English'
  },
          {
    id:3,
    title:'Ulubilo Lwa Mwipii ...',
    image:'/images/ab.png',
    category:'Adventure',
    lang:'Bemba'
  },
  {
    id:4,
    title:'Une Dos ...',
    image:'/images/ab.png',
    category:'Thrill',
    lang:'French'
  },
  {
    id:5,
    title:'My Adventure to ...',
    image:'/images/ab.png',
    category:'Drama',
    lang:'Swahili'
  },
  {
    id:6,
    title:'My Adventure to ...',
    image:'/images/ab.png',
    category:'Fantasy',
    lang:'Zulu'
  },
  {
  id:7,
  title:'My Adventure to ...',
  image:'/images/ab.png',
  category:'Fantasy',
  lang:'Shona'
},
{
  id:8,
  title:'My Adventure to ...',
  image:'/images/ab.png',
  category:'Romance',
  lang:'Lozi'
},
{
  id:9,
  title:'My Adventure to ...',
  image:'/images/ab.png',
  category:'Adventure',
  lang:'Igbo'
},
{
  id:10,
  title:'My Adventure to ...',
  image:'/images/ab.png',
  category:'Thrill',
  lang:'Yoruba'
},
{
  id:11,
  title:'Bonju ...',
  image:'/images/ab.png',
  category:'Thrill',
  lang:'French'
},
{
  id:12,
  title:'Love Not Returned...',
  image:'/images/ab.png',
  category:'Thrill',
  lang:'English'
},
{
  id:13,
  title:'Love ...',
  image:'/images/ab.png',
  category:'Thrill',
  lang:'Tonga'
},
];