import React, { useState,useEffect,useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "@firebase/firestore";
import './AddInventorBio.css';
import { toast } from 'react-toastify';
import { getDownloadURL, ref, uploadString } from "@firebase/storage";
import  Navbar  from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { db, storage } from "../../config/fire";
import { uuid } from 'uuidv4';

// const ref = firestore.firestore().collection('AfricanInventors');
function EditInventorBio() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const [loading, setLoading] = useState(false);
  const[invLife,setInvLife] = useState();
  const[invWork,setInvWork] = useState();
  const [name, setName] = useState("");
  const [origin, setOrigin ] = useState("");
  const [birthdate, setBirthDate] = useState ("");
  const [deathdate, setDeathDate] = useState("");
  const [profession, setProfession] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const filePickerRef = useRef(null);
  const [progress, setProgress] = useState(0);

  const sendPost = async () => {
    if (loading) return;
    setLoading(true);

const docRef = await updateDoc(collection(db, "AfricaInventors"), {
    id: uuid(),
    authorID: userId,
    name: name,
    life: invLife,
    work:invWork,
    origin: origin,
    birthdate: birthdate,
    deathdate: deathdate,
    profession: profession,
    time:  serverTimestamp(),

  });

  const imageRef = ref(storage, `AfricaInventors/${docRef.id}/image`);
  const imageRef1 = ref(storage, `AfricaInventors/${docRef.id}/image1`);
  const imageRef2 = ref(storage, `AfricaInventors/${docRef.id}/image2`);

  if (selectedFile) {
    await uploadString(imageRef, selectedFile, "data_url").then(async () => {
      const downloadURL = await getDownloadURL(imageRef);
      await updateDoc(doc(db, "AfricaInventors", docRef.id), {
        image: downloadURL,
      });
    });
  }
  if (selectedFile1) {
    await uploadString(imageRef1, selectedFile1, "data_url").then(async () => {
      const downloadURL1 = await getDownloadURL(imageRef1);
      await updateDoc(doc(db, "AfricaInventors", docRef.id), {
        paraimg1: downloadURL1,
      });
    });
  }
  if (selectedFile2) {
    await uploadString(imageRef2, selectedFile2, "data_url").then(async () => {
      const downloadURL2 = await getDownloadURL(imageRef2);
      await updateDoc(doc(db, "AfricaInventors", docRef.id), {
        paraimg2: downloadURL2,
      });
    });
  }




  setLoading(false);
  setInvLife("");
  setInvWork("");
  setName("");
  setOrigin("");
  setBirthDate("");
  setDeathDate("");
  setProfession("");
  setProgress(false);
  setSelectedFile(null);
  setSelectedFile1(null);
  setSelectedFile2(null);
  
  toast.success("Bio Edit successful!!!")
};

const addImageToPost = (e) => {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      setSelectedFile(readerEvent.target.result);
    };
  };
const addImageToPost1 = (e) => {
    const reader1 = new FileReader();
    if (e.target.files[0]) {
      reader1.readAsDataURL(e.target.files[0]);
    }
    reader1.onload = (readerEvent) => {
      setSelectedFile1(readerEvent.target.result);
    };
  };
const addImageToPost2 = (e) => {
    const reader2 = new FileReader();
    if (e.target.files[0]) {
      reader2.readAsDataURL(e.target.files[0]);
    }
    reader2.onload = (readerEvent) => {
      setSelectedFile2(readerEvent.target.result);
    };
  };



  return (
    <><Navbar /><div className='afriInv-container'>
      <div className='form-box'>
        <div className='mb-4 ml-3 text-muted'>
          <h1 class='text-muted'>EDIT BIOGRAPHY</h1>
        </div>
        <form className='form-group '>
          <div class='mb-3 col-10'>
            <label class='form-label text-muted' for='inventor-name'>Enter the Inventor's Full Name:</label>
            <br />
            <input
              type="text"
              id='inventor-name'
              class='form-control'
              placeholder='Name'
              value={name}
              onChange={(e) => setName(e.target.value)} />
            <br />
            <label class='form-label text-muted'>Country of Origin:</label>
            <br />
            <input
              type="text"
              id='inventor-origin'
              class='form-control'
              placeholder='Country'
              value={origin}
              onChange={(e) => setOrigin(e.target.value)} />
            <br />
            <label class='form-label text-muted'>Date of birth:</label>
            <br />
            <input
              type="text"
              id='inventor-birth'
              class='form-control'
              placeholder='dd/mm/yyyy'
              value={birthdate}
              onChange={(e) => setBirthDate(e.target.value)} />
            <br />
            <label class='form-label text-muted'>Date of death:</label>
            <br />
            <input
              type="text"
              id='inventor-death'
              class='form-control'
              placeholder='dd/mm/yyyy'
              value={deathdate}
              onChange={(e) => setDeathDate(e.target.value)} />
            <br/>
    <label class='form-label text-muted'>Enter the Inventor's profession:</label>
    <br/>
    <input
    type="text"
      id='inventor-profession'
      class='form-control'
      placeholder='Profession'
      value={profession}
      onChange={(e)=>setProfession(e.target.value)}
      />
 
          </div>

          <div class='col-10 mb-3'>
      <label class='form-label text-muted'>Insert the inventor's profile here:</label>
      <br/>
      <input
        class='form-control'
        type="file"
        id='inventor-profile'
        accept="invprofile/jpeg, invprofile/jpg, invprofile/png"
        ref={filePickerRef}
        onChange={addImageToPost}
        onClick={() => setSelectedFile(null)}
       
        />
    </div>

          <div className="form-group mb-3 col-12">
      <label class='form-label text-muted'>Type the inventor's life here:</label>
      <Editor
            initialValue="<p>start typing here</p>"
            init={{

              height: 800,
              menubar: false,
              plugins: [
                'advlist autolink lists link invprofile',
                'charmap print preview anchor help',
                'searchreplace visualblocks code',
                'insertdatetime media table paste wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help'
        }}
            onEditorChange={(newText)=>setInvLife(newText)}
  
      />
      <textarea className='form-control '
    value={invLife}
    onChange={(e)=>setInvLife(e.target.value)}
    />
    </div>
    <div className="form-group col-12">
      <label class='form-label text-muted'>Type the inventor's work here:</label>
      <Editor
            initialValue={''}
            init={{
              height: 800,
              menubar: false,
              plugins: [
                'advlist autolink lists link invprofile',
                'charmap print preview anchor help',
                'searchreplace visualblocks code',
                'insertdatetime media table paste wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help'
        }}
        onEditorChange={(newText)=>setInvWork(newText)}
  
      />
   
    </div>
          <div class='mb-3 col-12'>
      <label class='form-label text-muted'> Edit paragraph image for the inventor's life:</label>
      <br/>
      <input
        class='form-control'
        type="file"
        id='inventor-img1'
        accept="image/jpeg, image/jpg, image/png"
        ref={filePickerRef}
        onChange={addImageToPost1}
        />
    </div>

          <div class='mb-3 col-12'>
      <label class='form-label text-muted'>Edit paragraph image for the inventor's work:</label>
      <br/>
      <input
        class='form-control'
        type="file"
        id='inventor-img2'
        accept="image/jpeg, image/jpg, image/png"
        
        onChange={addImageToPost2}
        />
    </div> 

      
          <progress value={progress} max="100%" style={{ width: progress, height: "10px", margin: "0% 10%", backgroundColor: "green" }} />
        </form>
        <div class='col-12 mt-3 '>
            <button
              onClick={sendPost}
              class='inv-btn btn-dark form-control'
            >Add Bio</button>
          </div>
      </div>
    </div></>
  )
}

export default EditInventorBio