import React, {useState, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import "./Chapter.css"
import fire from "../../config/fire";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";

import { Modal, ListGroup, Button } from "react-bootstrap";


function Library() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const history = useHistory();
    const [UserDetails, setUserDetails] = useState({});
    const ref = fire.firestore().collection("UserAbout");
    const auth = getAuth();
    const getauth = auth.currentUser

    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    const ref1 = fire.firestore().collection(`${UserDetails.name} ReadingList`);


    // const [ uid, setUid] = useState({});
// Get story files from firebase

const get = async () => {
    ref1
    .onSnapshot((querySnapshot)=> {
        const items = []
        querySnapshot.forEach((doc)=>{
            items.push({ ...doc.data(), id: doc.id })
        })            
        setdata(items)
        setloader(false)
    })

  }

  useEffect(() => {
    if (data) {
    createDoc({ ReadingList: data.length});
    }

  }, [data]);

  function createDoc(newDataObj){
    ref
      .doc(getauth.uid)
      .update( newDataObj)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })

}

function deleteDoc(docx){
ref1.doc(docx.id).delete();
createDoc({ ReadingList: (data.length - 1)});

}
  // function get1(){
  //   ref1
  //   .onSnapshot((querySnapshot)=> {
  //       const items = []
  //       querySnapshot.forEach((doc)=>{
  //           items.push({ ...doc.data(), id: doc.id })
             
  //       })
  //       setdata1(items)
  //       setloader1(false)
  //   })
  // }

  async function getUserData() {
    if (getauth !== null) {

      const uid = getauth.uid;
      ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            setUserDetails(snapshot.data());
          }
        });
    }
  }

  useEffect(() => {
    getUserData();
  }, []); 
  const dispatch = useDispatch();

       // Data declaration
       const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

       useEffect(() => {
        fire.auth().onAuthStateChanged((user) => {
          if (user) {
            if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
            if (window.location.pathname === "/admin")
              history.push("/admin/dashboard");
            history.push(window.location.pathname);
          } 
          else {
            history.push("/admin/login");
            
          }
        });
      }, [dispatch]);  

      
  return (
    <div className='body '>

        <div className='addButton shadow '>
                <span  className='plusTxt'>Library</span>
        </div>
 
        <div className='bookSlides shadow'>
        {/* <h2 className="Movetile changeColortwo ">Talk of the Town</h2> */}
        <Button className="btn btn-primary" onClick={() => {
              get();
          }}>
           <i class="fas fa-chevron-circle-down"></i> View reading list..
          </Button>
<div className='rowPosters'>
        {loader === false && (data.map((doc) =>
        <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                          <img
                                          key={doc.id}
                                            src={doc.image}                                         
                                            className="rowPoster"
                                          />
                                      </Link>     
                                      <br/>    
                                      {doc.category}
<br />

<i class="fa fa-trash dati" style={{fontSize:"16px"}}  onClick={() => deleteDoc(doc)}></i> 


        </div>                 

        ))}
</div>

        </div>
    </div>
  )
}

export default Library