import React from 'react';
import './HIWpopup.css';
import {Link} from 'react-router-dom';

function HIWpopup(props) {
  return (props.trigger)?( 
        <div className='HIWpopup'>
            <div className='HIWpopup-inner'>
                <button className='HIW-closebtn'
                       onClick={()=>props.setTrigger(false)}>
                      <Link style={{textDecoration:"none",color:"#9f194a"}}
                       exact to={"/"}
                       >×</Link></button>
                {props.children} 
            </div>
        </div>
  ):"";
}

export default HIWpopup