export const MenuItems = [


  {
    
    title: 'Write a Story',
    path: '/admin/dashboard/WritersForm',
    cName: 'dropL'
  },
  {
    title: 'My Stories',
    path: '/admin/dashboard/posts',
    cName: 'dropL'
  },
  {
    title: 'Short stories',
    path: '/posts',
    cName: 'dropL'
  },
  {
    title: 'Complete stories',
    path: '/admin/dashboard/Chapter',
    cName: 'dropL'
  },
  {
    title: 'Inventors',
    path: '/admin/dashboard/Africaninventers',
    cName: 'dropL'
  },

  

];
