import React,{useState, useEffect } from 'react';
import Popup from "./ProfileAbout";
import { shallowEqual, useSelector } from "react-redux";
import fire from "../../config/fire";
import { getAuth} from "firebase/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "./Profile.css";
import { serverTimestamp } from "firebase/firestore";
import Avatar from "@mui/material/Avatar";






function Notify() {
  const history = useHistory();
  const [userDetails, setUserDetails] =useState({});
  const auth = getAuth();
  const user = auth.currentUser;
    const ref = fire.firestore().collection("Notification");
    const [name, setName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [note, setNote] = useState("");
    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);
    
   
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

    function createDoc(newDataObj){
        ref
          .doc()
          .set(newDataObj)
          .catch((err)=> {
            alert(err)
            console.error(err);
          })
    
        
    
    }

    function getData(){
        ref
        .orderBy('timestamp','desc')
        .onSnapshot((querySnapshot)=> {
            const items = []
            querySnapshot.forEach((doc)=>{
                items.push(doc.data())
            })
            setdata(items)
            setloader(false)
        })
    }

  

  function editDoc(updtedDoc){
    if (user !== null) {
      
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;
    
      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;

      
      ref
      .doc(uid)
      .update(updtedDoc)
      .catch((err) => {
        alert(err)
        console.error(err);
      })


     

    }

  }
useEffect(() => {
getData();
}, [])

var viewport = document.querySelector('meta[name=viewport]');
var viewportContent = 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0';

if (viewport === null) {
  var head = document.getElementsByTagName('head')[0];
  viewport = document.createElement('meta');
  viewport.setAttribute('name', 'viewport');
  head.appendChild(viewport);
}

viewport.setAttribute('content', viewportContent);

    return (
        <div className="">
              {/* <input
      type="button"
      value="Add Notification"
      onClick={togglePopup}
    /> */}
                    <div className='changeColor '>
                    <h2 className='changeColor' id="notifh" style={{fontFamily:"'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif", fontSize: "29px"}}>Notifications <i class="bi bi-bell-fill" ></i></h2> 
                   
            {loader === false && (data.map((dev) =>
              <div key={dev.id} className='changeColor'>
              <div className='notcard'>   
          
            <br/>
               <Avatar
            className="bi "
            alt="(-_-)"
            src={"https://oniontable.com/images/logo.png"}
            sx={{ width: 25, height: 25 }}
          />
               {dev.name}
            <br />
            <div className='notmove'>
            <lable className='changeColor'  style={{fontFamily:"'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif", color: "grey"}}>Note <i class="bi bi-megaphone-fill" style={{color: "grey"}}></i></lable> 
            <br/>
            <p  className='changeColor' id="notes" style={{fontFamily: "times new roman", fontSize:"18px"}}>{dev.note}</p></div>
              </div></div>
            ))}
        
    </div>
        
<br/>
   

    {isOpen && <Popup
      content={<>
       <h2 className='changeColor'><b>Create Notifications</b></h2> 
       <lable className='changeColor'><b>Name</b></lable>
       <br/>
            <input
               type="text"
                placeholder="First Name"
                id="name"
                onChange={(e) => setName(e.target.value)}
            />
          <br />
        <br />
        <label className='changeColor'><i class="far fa-bell"></i><b>Note</b></label>
        <br />
       <textarea
                         placeholder="Enter Notification"
                         className="form-control"
                         rows="4"
                         id='note'
                         onChange={(e) => setNote(e.target.value)}
                      
       >

       </textarea>
        <br />
        <br/>
        <div className="form-group">
                     
                     <input 
                     onClick={() => {
                      if ( !name || !note  ) {
                        return toast.warning("Please fill in all fields!!");
                      }
                      createDoc({note, name, timestamp: serverTimestamp()});
                      document.getElementById("note").value =""
                    document.getElementById("name").value =""
                     }}
                     type="submit"           
                     className="btn btn-primary "
                     />
             </div>
      </>
      
    }
      handleClose={togglePopup}
    />}
  </div>
    )
}
export default Notify
