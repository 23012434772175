import "./TextNav2.css"


const TextNav2 =()=>{

  return (
    <>
  <nav class="Qnav2">
      <ul class="Qnavmove2">
       
        <li class="itemQ2 " id="ItemQ2"> <button style={{borderColor: "lightgrey", borderWidth:"1px", width: "200px", background: "white", color: "white" , borderStyle:"solid"}}>
          <a class="nav-link "  style={{color: "black", fontWeight: "bold"}}href="./PostQuote"> PICTURE + TEXT <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
</svg><span class="sr-only">(current)</span></a>
       </button > </li>
        <li class="ItemQ2  " >  <button style={{background: "white", borderColor: "lightgrey", borderWidth:"1px", borderStyle:"solid"}}>
          <a class="nav-link" style={{color: "black", fontWeight: "bold"}} href="./TextQuote"> TEXT ONLY <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-file-word-fill" viewBox="0 0 16 16">
  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM5.485 4.879l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 7.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 1 1 .97-.242z"/>
</svg> </a>
        </button></li>
        
      </ul>
      
  </nav>
 

    </>
  );
}

export default TextNav2;