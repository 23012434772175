import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import fire from "../../../config/fire";
import '../Languages/English.css'
import { getPosts } from "../../../redux/actionCreators/postsActionCreator";

// import { Skeleton } from "@material-ui/lab";
// import { Grid } from '@material-ui/core';


const Posts = () => {
    const ref = fire.firestore().collection("ENGLISH");
    const Action = fire.firestore().collection("Action");
    const Adventure = fire.firestore().collection("Adventure");
    const Fantasy = fire.firestore().collection("Fantasy");
    const Fiction = fire.firestore().collection("Fiction");
    const history = useHistory();

    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    const [data1, setdata1] = useState([]);
    const [loader1, setloader1] = useState(true);

    const [data2, setdata2] = useState([]);
    const [loader2, setloader2] = useState(true);

    const [data3, setdata3] = useState([]);
    const [loader3, setloader3] = useState(true);
    
    const [data4, setdata4] = useState([]);
    const [loader4, setloader4] = useState(true);


    function getData(){
        ref
        .orderBy('image','desc')
        .onSnapshot((querySnapshot)=> {
            const items = []
            querySnapshot.forEach((doc)=>{
                items.push(doc.data())
                 
            })
            setdata(items)
            setloader(false)
        })
    }

    // Get story files from firebase

function getAction(){
  Action
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items = []
      querySnapshot.forEach((doc)=>{
          items.push(doc.data())
           
      })
      setdata1(items)
      setloader1(false)
  })
}

function getAdventure(){
  Adventure
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items2 = []
      querySnapshot.forEach((doc)=>{
          items2.push(doc.data())
           
      })
      setdata2(items2)
      setloader2(false)
  })
}

function getFantasy(){
  Fantasy
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items3 = []
      querySnapshot.forEach((doc)=>{
          items3.push(doc.data())
           
      })
      setdata3(items3)
      setloader3(false)
  })
}

function getFiction(){
  Fiction
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items4 = []
      querySnapshot.forEach((doc)=>{
          items4.push(doc.data())
           
      })
      setdata4(items4)
      setloader4(false)
  })
}

    useEffect(() => {
        getData();
        getAction();
        getAdventure();
        getFantasy();
        getFiction();

      }, []);
     

     //Home posts const and firebase links
 const { posts, postsLoading, isLoggedIn, userId } = useSelector(
    (state) => ({
      posts: state.posts.posts,
      postsLoading: state.posts.postsLoading,
      isLoggedIn: state.auth.isLoggedIn,
      userId: state.auth.userId,
    }),
    shallowEqual
  );

       //Home posts const and firebase links
       const latestPosts = posts;
       latestPosts.sort((a, b) => {
           const postA = new Date( a.post.createdAt);
           const postB = new Date( b.post.createdAt);
       
           if (postA < postB) return 1;
           if (postA > postB) return -1;
           return 0;
         })
         .slice(0, 5);
       
       const dispatch = useDispatch();
       useEffect(() => {
         if (postsLoading) {
           dispatch(getPosts());
         }
       }, [dispatch]);
       
     // Data declaration
 
     useEffect(() => {
       fire.auth().onAuthStateChanged((user) => {
         if (user) {
           if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
           if (window.location.pathname === "/admin")
             history.push("/admin/dashboard");
           history.push(window.location.pathname);
         } 
         else {
           history.push("/admin/login");
           
         }
       });
     }, [dispatch]);         

    return (

<div className='row'>

                    <div className='rowPosters'>
                            {loader === false && (data.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>  
                                                          <br/>    
                                                          {doc.category}

                               </div>                 

                            ))}
                    </div>


                    <div className='rowPosters'>
                            {loader1 === false && (data1.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link> 
                                                          <br/>    
                                                          {doc.category}

                             </div>                     

                            ))}
                    </div>


                    <div className='rowPosters'>
                            {loader2 === false && (data2.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>  
                                                          <br/>    
                                                          {doc.category}                                                                               
                            </div>
                            ))}
                    </div>


                    <div className='rowPosters'>
                            {loader3 === false && (data3.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>     
                                                          <br/>    
                                                          {doc.category}                                                                               
                            </div>                                                                            

                            ))}
                    </div> 


                    <div className='rowPosters'>
                            {loader4 === false && (data4.map((doc) =>
                            <div>
                                                          <Link  to={`/post/${doc.id}/${doc.title}`}>
                                                              <img
                                                              key={doc.id}
                                                                src={doc.image}
                                                                alt="---"
                                                                className="rowPoster"
                                                              />
                                                          </Link>     
                                                          <br/>    
                                                          {doc.category}                                                                               
                            </div>                                                                            

                            ))}
                    </div>   

<div className='rowPosters'>
        {latestPosts.map((post, id) => (
        <div>
          <Link to={`/post/${post.postId}/${post.post.title}`}>
                                          <img
                                          key={id}
                                            src={post.post.image}
                                            alt="---"
                                            className="rowPoster"
                                          />
                                      </Link>     
                                      <br/>    
                                      {post.post.category}
        </div>                 

        ))}
</div>

  
</div>



        
    );
};

export default Posts;