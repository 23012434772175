import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import fire from "../../config/fire";
import './Languages/English.css'
import { getPosts } from "../../redux/actionCreators/postsActionCreator";
import "./ChapterAdd.css"
import FullStoryAdd from "./FullStoryAdd"

import {Figure,Modal,Button,Form} from "react-bootstrap"
const ChapterAdd = () => {
    const ref = fire.firestore().collection("ENGLISH");
    const Action = fire.firestore().collection("Action");
    const Adventure = fire.firestore().collection("Adventure");
    const Fantasy = fire.firestore().collection("Fantasy");
    const Bemba = fire.firestore().collection("BEMBA");
    
    const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [data, setdata] = useState([]);
    const [loader, setloader] = useState(true);

    const [data1, setdata1] = useState([]);
    const [loader1, setloader1] = useState(true);

    const [data2, setdata2] = useState([]);
    const [loader2, setloader2] = useState(true);

    const [data3, setdata3] = useState([]);
    const [loader3, setloader3] = useState(true);
    
    const [data4, setdata4] = useState([]);
    const [loader4, setloader4] = useState(true);


    function getData(){
        ref
        .orderBy('image','desc')
        .onSnapshot((querySnapshot)=> {
            const items = []
            querySnapshot.forEach((doc)=>{
                items.push(doc.data())
                 
            })
            setdata(items)
            setloader(false)
        })
    }

    // Get story files from firebase

function getAction(){
  Action
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items = []
      querySnapshot.forEach((doc)=>{
          items.push(doc.data())
           
      })
      setdata1(items)
      setloader1(false)
  })
}

function getAdventure(){
  Adventure
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items2 = []
      querySnapshot.forEach((doc)=>{
          items2.push(doc.data())
           
      })
      setdata2(items2)
      setloader2(false)
  })
}

function getFantasy(){
  Fantasy
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items3 = []
      querySnapshot.forEach((doc)=>{
          items3.push(doc.data())
           
      })
      setdata3(items3)
      setloader3(false)
  })
}

function getBemba(){
  Bemba
  .orderBy('image','desc')
  .onSnapshot((querySnapshot)=> {
      const items4 = []
      querySnapshot.forEach((doc)=>{
          items4.push(doc.data())
           
      })
      setdata4(items4)
      setloader4(false)
  })
}

    useEffect(() => {
        getData();
        getAction();
        getAdventure();
        getFantasy();
        getBemba();

      }, []);
     

     //Home posts const and firebase links
 const { posts, postsLoading, isLoggedIn, userId } = useSelector(
    (state) => ({
      posts: state.posts.posts,
      postsLoading: state.posts.postsLoading,
      isLoggedIn: state.auth.isLoggedIn,
      userId: state.auth.userId,
    }),
    shallowEqual
  );

       //Home posts const and firebase links
       const latestPosts = posts;
       latestPosts.sort((a, b) => {
           const postA = new Date( a.post.createdAt);
           const postB = new Date( b.post.createdAt);
       
           if (postA < postB) return 1;
           if (postA > postB) return -1;
           return 0;
         })
         .slice(0, 5);
       
       const dispatch = useDispatch();
       useEffect(() => {
         if (postsLoading) {
           dispatch(getPosts());
         }
       }, [dispatch]);
       

 

  return (
    <>
    {/* Navbar */}
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 fixed-top">
      <div className="container">
        <a href="#" className="navbar-brand">
          Frontend Bootcamp
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navmenu"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navmenu">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a href="#learn" className="nav-link">
                What You'll Learn
              </a>
            </li>
            <li className="nav-item">
              <a href="#questions" className="nav-link">
                Questions
              </a>
            </li>
            <li className="nav-item">
              <a href="#instructors" className="nav-link">
                Instructors
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    {/* Showcase */}
    <section className="bg-dark text-light p-5 p-lg-0 pt-lg-5 text-center text-sm-start">
      <div className="container">
        <div className="d-sm-flex align-items-center justify-content-between">
          <div>
            <h1>
            <i class='fas fa-book-reader '></i> Full Stories <span className="text-warning">With Chapters. </span>
            </h1>
            <p className="lead my-4">
              Here, you will be able to add full stories and chapters . 
            </p>
            
            <FullStoryAdd />
           
          </div>
          <img
            className="img-fluid w-50 d-none d-sm-block"
            src="img/showcase.svg"
            alt=""
          />
        </div>
      </div>
    </section>
  
    {/* Boxes */}
    
    <h1 className='text-info text-center'>English Stories</h1><hr></hr>
    {loader === false && (data.map((doc) =>
        
    <Figure className="m-3 "
    
    >
    <Link  to={`/post/${doc.id}/${doc.title}`}>
  <Figure.Image
    key={doc.id}
    width={171}
    height={180}
    alt="171x180"
    src={doc.image}
    
  />
  </Link>
  <Figure.Caption>
  <div className="btn-group ">
  <Link  to={`/post/${doc.id}/${doc.title}`}>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          View
        </button>
       </Link>

       <Link to={`/EditPost/${doc.id}/${doc.title}`}>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
         
        >
          Edit
        </button>
        </Link>

        <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={handleShow}
      >
        Delete
      </button>
      </div>
</Figure.Caption>

</Figure>
))}
 
<h1 className='text-info text-center mt-2'>Action Stories</h1><hr></hr>
{loader === false && (data1.map((doc) =>
        
    <Figure className="m-3 "
    
    >
    <Link  to={`/post/${doc.id}/${doc.title}`}>
  <Figure.Image
    key={doc.id}
    width={171}
    height={180}
    alt="171x180"
    src={doc.image}
    
  />
  </Link>
  <Figure.Caption>
  <div className="btn-group ">
  <Link  to={`/post/${doc.id}/${doc.title}`}>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          View
        </button>
       </Link>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
          onClick={handleShow}
        >
          Edit
        </button>
        <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={handleShow}
      >
        Delete
      </button>
      </div>
</Figure.Caption>

</Figure>
))}

<h1 className='text-info text-center mt-2'>Adventure Stories</h1><hr></hr>
{loader === false && (data2.map((doc) =>
        
    <Figure className="m-3 "
    
    >
    <Link  to={`/post/${doc.id}/${doc.title}`}>
  <Figure.Image
    key={doc.id}
    width={171}
    height={180}
    alt="171x180"
    src={doc.image}
    
  />
  </Link>
  <Figure.Caption>
  <div className="btn-group ">
  <Link  to={`/post/${doc.id}/${doc.title}`}>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          View
        </button>
       </Link>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
          onClick={handleShow}
        >
          Edit
        </button>
        <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={handleShow}
      >
        Delete
      </button>
      </div>
</Figure.Caption>

</Figure>
))}


<h1 className='text-info text-center mt-2'>Fantasy Stories</h1><hr></hr>
{loader === false && (data3.map((doc) =>
        
    <Figure className="m-3 "
    
    >
    <Link  to={`/post/${doc.id}/${doc.title}`}>
  <Figure.Image
    key={doc.id}
    width={171}
    height={180}
    alt="171x180"
    src={doc.image}
    
  />
  </Link>
  <Figure.Caption>
  <div className="btn-group ">
  <Link  to={`/post/${doc.id}/${doc.title}`}>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          View
        </button>
       </Link>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
          onClick={handleShow}
        >
          Edit
        </button>
        <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={handleShow}
      >
        Delete
      </button>
      </div>
</Figure.Caption>

</Figure>
))}


<h1 className='text-info text-center mt-2'>Bemba Stories</h1><hr></hr>
{loader === false && (data4.map((doc) =>
        
    <Figure className="m-3 "
    
    >
    <Link  to={`/post/${doc.id}/${doc.title}`}>
  <Figure.Image
    key={doc.id}
    width={171}
    height={180}
    alt="171x180"
    src={doc.image}
    
  />
  </Link>
  <Figure.Caption>
  <div className="btn-group ">
  <Link  to={`/post/${doc.id}/${doc.title}`}>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          View
        </button>
       </Link>
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
          onClick={handleShow}
        >
          Edit
        </button>
        <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={handleShow}
      >
        Delete
      </button>
      </div>
</Figure.Caption>

</Figure>
))}

<Modal show={show} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title>Story Name</Modal.Title>
</Modal.Header>
<Modal.Body>

<Form>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Chapter Title</Form.Label>
    <Form.Control type="email" placeholder="Chapter Name" />
    
  </Form.Group>

  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Label>Add Chapter</Form.Label>
    <Form.Control as="textarea" rows={3} placeholder="Chapter Description"/>
  </Form.Group>
  <Button variant="primary" type="submit">
    Add Chapter
  </Button>
</Form>

</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
  <Button variant="primary" onClick={handleClose}>
    Save Changes
  </Button>
</Modal.Footer>
</Modal>
   
    
  </>
  
  

  );
};


export default ChapterAdd;
