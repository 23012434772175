import {useState,useEffect} from "react";
import QuotePageInput from "./QuotePageInput";
import { db } from "../../config/fire";
import { onSnapshot, collection, query, orderBy } from "@firebase/firestore";
import QuotePage from "./QuotePage";
import QuoteCategory from "./QuoteCategory";
import  Navbar  from "./Navbar";
import "./QuotePage.css"
import "./TextQuote.css"
import TextNav2 from "./TextNav2";



const PostQuote = () => {
    
    const [posts, setPosts] = useState([]);
    useEffect(
        () =>
          onSnapshot(
            query(collection(db, "proverbs"), orderBy("timestamp", "desc")),
            (snapshot) => {
              setPosts(snapshot.docs);
            }
          ),
        [db]
      );


  return (
<>
<Navbar />
<TextNav2/>
<QuotePageInput />
<QuoteCategory />

<div >
        {posts.map((post) => (
       
          <QuotePage key={post.id} id={post.id} post={post.data()}  />
  
        ))}
        
        </div>
        
</>
  );
};


export default PostQuote;
