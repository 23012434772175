import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Admin from "./admin";

import Home from "./Components/Home";
import "./App.css";
import Navbar from "./admin/Dashboard/Navbar";
import { useDispatch, useSelector } from "react-redux";
import fire from "./config/fire";
import SeePost from "./admin/Dashboard/SeePost";
import Offline from "./Components/Offline";
import Posts from "./admin/Dashboard/Stories/index";  
import Register from "./admin/Register"; 
import Nxt from "./admin/Register/Nxt"
import ChapterAdd from "./admin/Dashboard/ChapterAdd"
import EditPost from "./admin/Dashboard/EditPost"
const App = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
      }
    });
  }, [dispatch]);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [onLine, setOnLine] = useState(false);

  useEffect(() => {
    setOnLine(navigator.onLine);
    if (!onLine) dispatch({ type: "RESET_USER" });
  }, [navigator.onLine]);

  if (!onLine) {
    return <Offline />;
  }

  return (
    <>
    <div className="sharethis-sticky-share-buttons mt-3" 
    style={{paddingTop:"11%"}}
    />

    <div className="App">
      <ToastContainer />
      <Switch>
        <Route path={""}>
          <Route exact path={"/"}>
          <Navbar />
            <Home />
          </Route>
          <Route path={"/posts"}>
          <Navbar />
            <Posts />
          </Route>
          <Route exact path={"/post/:id/:title"}>
          <Navbar />
            <SeePost />
          </Route>
          <Route path={"/admin"}>
          <Navbar />
            <Admin />
          </Route>
          <Route exact path={`/register`}>
          <Register />
        </Route>
        <Route  path={`/ChapterAdd`}>
        <ChapterAdd />
      </Route>
        <Route exact path={`/next`}>
          <Nxt />
        </Route>

        <Route exact path={`/EditPost`}>
        <EditPost />
      </Route>
        </Route> 
      </Switch>
      
    </div>
    </>
  );
};

export default App;
