import { useEffect, useState } from "react";
import { useAuth, upload } from "../../config/fire";
import { shallowEqual, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import {  Link  } from "react-router-dom";
import "./Profile.css";
import ProfileNav from "./ProfileNav";
import fire from "../../config/fire";
import { getAuth } from "firebase/auth";
import ProfileView from "./ProfileView";
import { Modal, ListGroup, Button } from "react-bootstrap";
import firebase from "firebase/compat/app";


export default function Profile() {
  const [userDetails, setUserDetails] = useState({});
  const currentUser = useAuth();
  const auth = getAuth();
  const user = auth.currentUser;
  const ref = fire.firestore().collection("UserAbout");
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState("/images/user.png");
  const [disable, setDisable] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [show, setShow] = useState(false);


  const [following1, setFollowing1] = useState([]);
  const [othersFollowers1, setOthersFollowers1] = useState([]);
  const [userDetails1, setUserDetails1] = useState({});
  const [othersFollowers, setOthersFollowers] = useState([]);
  const [otherUserDetail, setOtherUserDetail] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => {
    let promises = [];
    userDetails1.followedBy.forEach((item) => {
      let follower = ref.doc(item).get();
      promises.push(follower);
    });
    Promise.all(promises).then((res) => {
      let temp = [];
      res.forEach((item) => {
        if (item.data()) temp.push(item.data());
        else console.log("not ok");
      });
      setFollowers(temp);
      if(temp.length>0)
      setShow(true);
    });
  };

  function handleChange(e) {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
      alert("Click the Edit Profile button to Upload");
    }
  }

  function handleClick() {
    upload(photo, currentUser, setLoading);
    setDisable(true);
  }

  useEffect(() => {
    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL);
      createDoc({ ProfilePic: currentUser.photoURL, id: user.uid});

    }
  }, [currentUser]);

 

  function createDoc(newDataObj){
    ref
      .doc(user.uid)
      .update( newDataObj)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })

}

  async function getData1() {
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      // const displayName = user.displayName;
      // const email = user.email;
      // const photoURL = user.photoURL;
      // const emailVerified = user.emailVerified;

      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;
      await ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            setUserDetails1(snapshot.data());

            console.log('following', snapshot.data().following)
            setFollowing1(
              snapshot.data().following ? [...snapshot.data().following] : []
            );
          }
        });
        
    }
  }




  function getOtherUserData() {
    const uid = user.uid; /// others profile uid
    ref
      .doc(uid)
      .get()
      .then((snapshot) => {
        if (snapshot) {
          setOtherUserDetail(snapshot.data());
          setOthersFollowers(
            snapshot.data().followedBy ? snapshot.data().followedBy : []
          );
        }
      });
  }
  useEffect(() => {
    getData1();
    getOtherUserData();
  }, []);
  return (
    
    <div className="fields">
      {/* profile picture upload */}
      <div className="profileCover">
        <label class="filebutton">
          <i class="fas fa-camera "></i>
          <span>
            <input type="file" onChange={handleChange} />
          </span>
        </label>
        <>
          <Avatar
            className="avatar"
            alt="Remy Sharp"
            src={photoURL}
            sx={{ width: 156, height: 156 }}
          />
        </>
        {/* user proflie details */}
        <div className="userN">@{otherUserDetail.name}</div>

        <div className="form-group">
          <input
            type="submit"
            value={disable ? "Uploading..." : "Edit Profile"}
            disabled={disable}
            className="btn btn-primary uploadImg"
            onClick={handleClick}
          />
        </div>

        <div className="d-flex justify-content-center mt-3">
          <button  className="FollowB works">
            <h6 className="text-center mb-0">{otherUserDetail.works}</h6>
            <h6 className="text-center">Works</h6>
          </button>
          <Link
               to="/admin/dashboard/Library"
              >
          <button  className="FollowB reading">
            <h6 className="text-center mb-0">{otherUserDetail.ReadingList}</h6>
            <h6 className="text-center">Reading List</h6>
          </button>
          </Link>
          <button  className="FollowB followers" onClick={handleShow}>
            <h6 className="text-center mb-0">
            {othersFollowers.length}
            </h6>
            <h6 className="text-center">Followers</h6>
          </button>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="customModal">
            <ListGroup style={{background: '#121217'}}>
              {followers.map((item, index) => {
                return (
                  
                  <ListGroup.Item  className="text-secondary d-flex flex-column" style={{background: '#121217'}}>
                   <div className="d-flex align-items-center">
                    <Avatar
                      className=""
                      alt="Remy Sharp"
                      src={item.image}
                      sx={{ width: 50, height: 50 }}
                    />
                    <p className="m-3">{item.name}</p>
                    <Button   className=" d-flex justify-content-end" 
                    onClick={() =>{

                      let temp = othersFollowers1;
                      let temp2 = following1;
                      // console.log(getauth)
                      let isFollower = temp.find((f) => f == user.uid);
                      let isfollowing = temp2.find((f) => f == item.id); /// others profile uid
                      if (isFollower) {
                        ref.doc(item.id).update({ /// others profile uid
                          followedBy: firebase.firestore.FieldValue.arrayRemove(user.uid),
                        });
                        let filtered = temp.filter(t => t != user.uid)
                        setOthersFollowers1(filtered)
                      } else {
                        ref.doc(item.id).update({ /// others profile uid
                          followedBy: firebase.firestore.FieldValue.arrayUnion(user.uid),
                        });
                        temp.push(user.uid)
                        setOthersFollowers1([...temp])
                      }
                  
                      if(isfollowing){
                        ref.doc(user.uid).update({
                          following: firebase.firestore.FieldValue.arrayRemove(item.id), /// others profile uid
                        });
                        let filtered = temp2.filter(t => t != item.id) /// others profile uid
                        setFollowing1(filtered)
                      } else {
                        ref.doc(user.uid).update({
                          following: firebase.firestore.FieldValue.arrayUnion(item.id), /// others profile uid
                        });
                        temp2.push(item.id) /// others profile uid
                        setFollowing1([...temp2])
                      }
                    }} 
                    disabled={loading}>
                         {following1.find((f) => f == item.id) ? <i class="fas fa-user">Following</i> : <i class="fas fa-user-plus"> Follow</i> }
                   </Button>
                    </div>
                  </ListGroup.Item>
                  
                );
              })}
            </ListGroup>
            <div className="d-flex justify-content-end"><Button variant="primary" onClick={handleClose}>
              Close
            </Button></div>
            
          </Modal.Body>
        </Modal>
      </div>
      <br />
      <br />
      <br />
      <>
      <body>
           {/* Profile NavBar */}
    <div className="navp">
        <div className="navbar-containerp " >
          <ul  >
            <li className="nav-itemp">
              <Link
               to="/admin/dashboard/profile"
                className="nav-linksp"
                
              >
               About
              </Link>
            </li>
            <li className="nav-itemp">
              <Link
               to={`/admin/dashboard/OthersChat/${user.uid}`}
               
                className="nav-linksp"
               
              >
              Chats
              </Link>
            </li>
            <li className="nav-itemp" >
              <Link to="/admin/dashboard/notification" 
              className="nav-linksp" 
              >
             Notification
              </Link>
            </li>
            {/* <li className="nav-itemp" >
              <Link to="/admin/dashboard/books" 
              className="nav-linksp" 
              onClick={closeMobileMenu}>
             Following
              </Link>
            </li> */}
          </ul>
        </div>
     </div>
     </body>
        <div className=" AboutUserin   shadow p-5  ">
          <ProfileView />
        </div>
      </>
    </div>
  );
}
