import { Modal, Button } from "react-bootstrap";
import "./premiumModal.css";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

const PremiumModal = (props) => {
  // const config = {
  //   public_key: "FLWPUBK_TEST-ab6ff11e6cd4b9aafd84b2c1de91e32e-X",
  //   tx_ref: Date.now(),
  //   amount: 100,
  //   currency: "ZMW",
  //   payment_options: "card,mobilemoney,ussd",
  //   customer: {
  //     email: "christopherzulu04@gmail.com",
  //     phonenumber: "0971488377",
  //     name: "CHRISTOPHER ZULU",
  //   },
  //   customizations: {
  //     title: "Onion Table Payment Method",
  //     description: "Payment for items in cart",
  //     logo: "https://oniontable.com/wp-content/uploads/2021/08/logo-new-colour-png.png",
  //   },
  // };

  // const handleFlutterPayment = useFlutterwave(config);

  return (
    <Modal
      dialogClassName="custom-modal"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Body>
        <div>
          <h4 className="text-dark text-center font-weight-bold">Premium+</h4>
          <h4
            className="text-center font-weight-bold mt-4"
            style={{ color: "rgba(224,57,91,1)" }}
          >
            From $7/month USD
          </h4>
          <h6
            className="text-secondary text-center"
            style={{ lineHeight: "2px" }}
          >
            Billed in local currency
          </h6>
          <div className="d-flex justify-content-center mt-5 ml-5">
            <div className="d-flex flex-column bottom-section">
              <div className="d-flex">
                <i
                  class="fa fa-check font-weight-bold text-dark position-static"
                  aria-hidden="true"
                ></i>
                <h6 className="font-weight-bold text-dark ml-2">
                  Unlock any 2 Paid Stories per month from hundreds of stories
                </h6>
              </div>

              <div className="d-flex mt-1">
                <i
                  class="fa fa-check font-weight-bold text-secondary position-static"
                  aria-hidden="true"
                ></i>
                <h6 className=" text-secondary ml-2">No ad intruption</h6>
              </div>

              <div className="d-flex mt-1">
                <i
                  class="fa fa-check font-weight-bold text-secondary position-static"
                  aria-hidden="true"
                ></i>
                <h6 className="text-secondary ml-2">
                  Unlimited offline stories
                </h6>
              </div>

              <div className="d-flex mt-1">
                <i
                  class="fa fa-check font-weight-bold text-secondary position-static"
                  aria-hidden="true"
                ></i>
                <h6 className=" text-secondary ml-2">
                  Bonus coins on purchase
                </h6>
              </div>
              <div className="d-flex mt-1">
                <i
                  class="fa fa-check font-weight-bold text-secondary position-static"
                  aria-hidden="true"
                ></i>
                <h6 className=" text-secondary ml-2">Theme color</h6>
              </div>
            </div>
          </div>
        </div>
        {/* <button
      className='btn btn-alert btn-sm'
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
               console.log(response);
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
          });
        }}
      >
      <i class="fa fa-credit-card btn-large" aria-hidden="true" style={{fontSize:"25px"}}></i>
      </button> */}
        <div className="d-flex justify-content-center mt-5">
          <Button variant="primary" onClick={props.handlePremium}>
            Go Premium+
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PremiumModal;
