/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams ,Link} from "react-router-dom";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import Image from 'react-bootstrap/Image'
import {Card,Button,Offcanvas,Carousel,Figure,Container,Row,Toast,Dropdown,Tab,Tabs,ListGroup,Badge} from "react-bootstrap"
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import {Helmet} from "react-helmet";
import Avatar from "@mui/material/Avatar";

import {
  doComment,
  doReply,
  getPosts,
  deleteComment,
} from "../../redux/actionCreators/postsActionCreator";
import '../../admin/Dashboard/seePost.css'
import AddReply from "./AddReply";
import {  useHistory, useRouteMatch } from "react-router-dom";
import fire from "../../config/fire";
import CommentSidebar from "./CommentSidebar"

import "./seePost.css"
import {useAuth} from "../../config/fire";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { storage } from "../../config/fire";
import Moment from "react-moment";
const SeePost = (children, eventKey) => {

  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [lgShow, setLgShow] = useState(false);
  const [photoURL, setPhotoURL] = useState("/images/user.png");
  const [key, setKey] = useState('home');
  const auth = getAuth();
  const getauth = auth.currentUser
  const [author, setAuthor] = useState({});
  const ref = fire.firestore().collection("UserAbout");
  const ref1 = fire.firestore().collection("NewandHot");
  const [UserDetails, setUserDetails] = useState({});

  const [data1, setdata1] = useState([]);
  const [loader1, setloader1] = useState(true);
  const [othersFollowers, setOthersFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref2 = fire.firestore().collection(`${UserDetails.name} ReadingList`);

//   async function ProfileImgLink (){

//     // Getting Download Link
//     storage.ref(`${otherUserid}.png`).getDownloadURL()
//       .then((photoURL) => {
//         setPhotoURL(photoURL);
//       });
// }
     
 
  useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!isLoggedIn) dispatch({ type: "SET_USER", payload: user });
        if (window.location.pathname === "/admin")
          history.push("/admin/dashboard");
        history.push(window.location.pathname);
      } 
      else {
        history.push("/admin/login");
        
      }
    });
  }, [dispatch]);

  const { id } = useParams();
 const [otherUserid, setOtherUserId] = useState("");

  const { posts, postsLoading, isLoggedIn, user, userId } = useSelector(
    (state) => ({
      posts: state.posts.posts,
      postsLoading: state.posts.postsLoading,
      isLoggedIn: state.auth.isLoggedIn,
      user: state.auth.user,
      userId: state.auth.userId,
    }),
    shallowEqual
  );


  const currentPost = posts.find((post) => post.postId === id && post);
 
  useEffect(() => {
    if (postsLoading) {
      dispatch(getPosts());
    }
  }, [dispatch]);

  useEffect(() => {
    
    if (currentPost) {
      getAuthordata();
    }
    
  }, [currentPost]);
  
  useEffect(() => {
    get1();
    getData();

  }, []);

  async function getData() {
    setLoading(true)
    if (getauth !== null) {

      const uid = getauth.uid;
      await ref
        .doc(uid)
        .get()
        .then((snapshot) => {
          if (snapshot) {
            setUserDetails(snapshot.data());
            // console.log('following', snapshot.data().following)
            setFollowing(
              snapshot.data().following ? [...snapshot.data().following] : []
            );
          }
          setLoading(false)
        });
    }
  }

  

  function get1(){
    ref1
    .orderBy('image','desc')
    .onSnapshot((querySnapshot)=> {
        const items = []
        querySnapshot.forEach((doc)=>{
            items.push(doc.data())
             
        })
        setdata1(items)
        setloader1(false)
    })
  }
  const getAuthordata = async () => {
   await ref
      .doc(currentPost.post.author)
      .get()
      .then((snapshot) => {

        if (snapshot) {

          // console.log(snapshot.data())
          setOtherUserId(snapshot.id);
          setAuthor(snapshot.data());
          setOthersFollowers(
            snapshot.data().followedBy ? snapshot.data().followedBy : []
          );

        }
      });
  };

  function handleClick() {
    let temp = othersFollowers;
    let temp2 = following;
    // console.log(getauth)
    let isFollower = temp.find((f) => f == getauth.uid);
    let isfollowing = temp2.find((f) => f == otherUserid); /// others profile uid
    if (isFollower) {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayRemove(getauth.uid),
      });
      let filtered = temp.filter(t => t != getauth.uid)
      setOthersFollowers(filtered)
    } else {
      ref.doc(otherUserid).update({ /// others profile uid
        followedBy: firebase.firestore.FieldValue.arrayUnion(getauth.uid),
      });
      temp.push(getauth.uid)
      setOthersFollowers([...temp])
    }

    if(isfollowing){
      ref.doc(getauth.uid).update({
        following: firebase.firestore.FieldValue.arrayRemove(otherUserid), /// others profile uid
      });
      let filtered = temp2.filter(t => t != otherUserid) /// others profile uid
      setFollowing(filtered)
    } else {
      ref.doc(getauth.uid).update({
        following: firebase.firestore.FieldValue.arrayUnion(otherUserid), /// others profile uid
      });
      temp2.push(otherUserid) /// others profile uid
      setFollowing([...temp2])
    }
  }


  function createDoc(newDataObj){
    ref2
      .doc(currentPost.post.id)
      .set( newDataObj)
      .catch((err)=> {
        alert(err)
        console.error(err);
      })
      alert("Added Successfully!");

}


 

  return (
    <div className="body-top">
    <>
    <nav className="sticky-top nav-res ">
    <div id="progress-bar" className="progress-bar" style={{ margin: "57px" }}></div>
     {/*Second Navbar */}
      </nav>
  {/* page loader */}
  <div className="theme-layout " style={{marginTop:"-5%"}}>
 
  
 
    {/* carousel menu */}
    <section>
      <div className="gap">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12 ">
              <div id="page-contents" className="row merged20 ">
              {postsLoading ? (
                <h1 className="text-center">Story Loading...</h1>
              ) : currentPost ? (
              <><div className="col-lg-9  " >
              
                            <div className="main-wraper shadow-post txx" id="text-title">
                              <div className="mt-3">
                              <img src={currentPost.post.image} className="img-fluid rounded-start img-fix" alt="..." 
      style={{width:"140px",marginTop:"-3%"}}
      />
      <h6 className="float-right fix-details"
      style={{marginRight:"50%"}}
      >
   <span >{currentPost.post.title}</span><br />
   <i class="fas fa-eye eye mt-2"></i>160k |{" "} <i class="fas fa-star star"></i>{currentPost.post.likes}k | {" "} <i class="fas fa-comment comment"></i>0k <br/>

   {currentPost.post.category.split(",").map((category, id) => (
        <p  key={id}>
        
     <Badge bg="primary" className="mt-2 mb-2"> {category.trim()}</Badge><br/>
          <small className="text-muted">
            <i class="fa fa-calendar " style={{fontSize:"16px"}}></i> {currentPost.post.date}</small><br/>
            <i class="fa fa-clock-o" aria-hidden="true" style={{fontSize:"16px"}}></i>  <Moment fromNow style={{fontSize:"16px"}}>{currentPost.post?.timestamp?.toDate()}</Moment>
        
        </p>
        ))}
    <Button  
                          onClick={() =>{
                            createDoc(currentPost.post);

                          }} >
                            <i class="fas fa-plus" ></i>
                      </Button>
  </h6>
 
                              </div>
                         
                            
                            </div>



                            {/* books carousel */}
                          </div>
                          
                          <div className="col-lg-3  " 
                          
                          >
                              <aside className="sidebar static right card-up  rounded" 
                              id="card-up"
                              
                              >


                                <div className="widget   shadow-lg p-3 mb-5 bg-light"
                                id="widget1"
                                >
                                 
                                  <div className="popular-book"  >

                                    

                                    <div className="book-about">
                                    {/* Card Profile Starts */}
                                    <div className="card cardpro   bg-light">
  {/* it Will contain 5 elements */}
  <div className="card-background11 card-p bg-red">
    <img
      src={author.ProfilePic}
      className="card-image11"
    />
  </div>
  {/* creating div class for names or heading */}
  <div className="card-info11">
    <h3 className="heading1">{author.name ? author.name : ""}</h3>
    <br />
    <p className="infotag">
    <Link
                                 to={`/admin/dashboard/othersProfile/${currentPost.post.author}`}
                               ><button className="btn-primary btn-sm " >View Profile</button></Link> 
                               <button className="btn-primary btn-sm" onClick={handleClick}>
                               {following.find((f) => f === otherUserid) ? <i class="fas fa-user-minus">Unfollow</i> : <i class="fas fa-user-plus"> Follow</i>}
                                 </button>
    </p>
  </div>
 
</div>
                                    {/*Card Profile Ends */}
                                    </div>
                                  </div>
                                 


                                </div>

                               
                              </aside>
                            </div>
                            
                          <div className="col-lg-9 paragraph-up">
                              <div className="main-wraper shadow-lg p-3 mb-5 bg-light">
                                <h4 className="main-title ">
                               
                                <div class="sharethis-inline-reaction-buttons"></div>
                             
                                </h4>
                                
                              

                                <div className="row  " style={{fontSize:"14px"}}>
                               <p className="text-dark">
                                  {parse(currentPost.post.description)}<br />
                                  </p>
                                  <CommentSidebar />
                                </div>

                                
                              </div>

                              

                              {/* books carousel */}
                            </div>
                            
                            
                            
                            <div className="col-lg-3 ">
                              <aside className="sidebar static right  ">


                                <div className="widget shadow-post ">
                                  <h4 className="widget-title">Latest Stories</h4>
                                  {loader1 === false && (data1.map((doc) => <div className="popular-book">

                                    <figure>
                                      <Link to={`/post/${doc.id}/${doc.title}`}>
                                        <img src={doc.image} alt="" 
                                         className="image"
                                        />
                                      </Link>
                                    </figure>

                                    <div className="book-about">
                                      <h6>
                                        <a href="#" title="">
                                          {doc.title}
                                        </a>
                                      </h6>
                                      <span></span>
                                      <a href="#" title="Book Mark">
                                        <i className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={20}
                                            height={20}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-bookmark"
                                          >
                                            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                                          </svg>
                                        </i>
                                      </a>
                                    </div>
                                  </div>
                                  ))}


                                </div>

                               
                              </aside>
                            </div></>
                ) : (
                  <h1 className="text-center">
                    <span className="text-primary"></span>Poor Internet Connnection.Try Again!
                  </h1>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    
  </div>
</>

    </div>
  );
};


export default SeePost;
