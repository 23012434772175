import firebase from 'firebase/compat/app';
import {arrayUnion} from 'firebase/firestore';



import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";



// const firebaseConfig = {
//   apiKey: process.env.React_App_ApiKey,
//   authDomain: process.env.React_App_AuthDomain,
//   projectId: process.env.React_App_ProjectId,
//   storageBucket: process.env.React_App_StorageBucket,
//   messagingSenderId: process.env.React_App_MessagingSenderId,
//   appId: process.env.React_App_AppId,
// };

// const fire = firebase.initializeApp(firebaseConfig);

// export const db = firebase.firestore();
// export const firestore = firebase.firestore();
// export const storage = firebase.storage();
// export const auth = firebase.auth();



// export default fire;




import { useEffect, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, updateProfile } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.React_App_ApiKey,
  authDomain: process.env.React_App_AuthDomain,
  projectId: process.env.React_App_ProjectId,
  storageBucket: process.env.React_App_StorageBucket,
  messagingSenderId: process.env.React_App_MessagingSenderId,
  appId: process.env.React_App_AppId,
};

// Initialize Firebase
// eslint-disable-next-line 
// const app = initializeApp(firebaseConfig);
const fire = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
// const analytics = firebase.analytics();


// Custom Hook
export function useAuth() {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, user => setCurrentUser(user));
    return unsub;

  }, [])

  return currentUser;
}

// Storage
export async function upload(file, currentUser, setLoading) {
  const fileRef = ref(storage, currentUser.uid + '.png');

  setLoading(true);
  // eslint-disable-next-line 
  const snapshot = await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);

  updateProfile(currentUser, {photoURL});
  
  setLoading(false);
  alert("Profile Picture Uploaded Successfully");
  alert("Finish By Reloading this page");


}

// export const createUserDocument = async (user, additionalData) => {
//   if (!user) return; 
//   const userRef = firestore.doc('users/${user.uid}');
//   const snapshot = await userRef.get();
//   if (!snapshot.exists){
//     const {email} = user;
//     const {displayName} = additionalData;

//     try {
// userRef.set({
//   displayName,
//   email,
//   createdAt: new Date()
// })
//     } catch(error){
//       console.log('Error in creating user', error)
//   }
//   }
// }
export {arrayUnion};
export default fire;