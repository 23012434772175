import React, { useState, useEffect} from 'react';
import './LanguageButtons.css';
import Content from './Content'; 
import ContentDisplay from './ContentDisplay';
import fire from "../../config/fire";
import { Link } from "react-router-dom";


function LanguageButtons(props) {



    return (
        <div className='bs-container'>
            
            <div className='language-label'>
                <h4>
                    <img className='pan-flag' src='/images/pan3.png' alt='Pan-African Flag'  />
                    <p className='changeColor'>Africa's first multilingal social story telling platform connecting readers and writers</p>
                </h4>
            </div>
            <div className='btns-boxL'>
                    
                    <Link to="/admin/dashboard/English" id='btn-boxL' ><button  >ENGLISH</button></Link>
                    <Link to="/admin/dashboard/Swahili" id='btn-boxL' ><button  >SWAHILI</button></Link>
                    <Link to="/admin/dashboard/Bemba" id='btn-boxL'><button >BEMBA</button></Link>
                    <Link id='btn-boxL'><button  >SOMALI</button></Link>
                    <Link id='btn-boxL'><button >LOZI</button></Link>
                    <Link id='btn-boxL'><button >NYANJA</button></Link>
                    <Link id='btn-boxL'><button  >SETWANA</button></Link>
                    <Link id='btn-boxL'><button >FRENCH</button></Link>
                    <Link id='btn-boxL'><button >HAUSA</button></Link>
                    <Link id='btn-boxL'><button >SHONA</button></Link>

            </div>
            
        </div>
    )
}

export default LanguageButtons;