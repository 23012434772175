import React, { useEffect, useState } from 'react';
import "./ImageUpload.css"
import firebase from '../../config/fire';
import {getDownloadURL,ref, uploadBytesResumable, getStorage,listAll} from "firebase/storage"

export default function ImageUpload({setAddImg,code}) {

    const [result, setResult] =useState("");
    const [imgURL, setImgURL] = useState("");
    const [image, setimage] = useState("");
    const [progress, setProgress] = useState(0);
    
    const handleChange = e => {
        if(e.target.files[0]){
            setimage(e.target.files[0]);
        }
    }

    // Image Upload and display
    function handleUpload () {
        const storage = getStorage();
        const storageRef = ref(storage, `CountryImages/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
            'state_changed',
            snapshot =>{
                const progress = Math.round(
                    (snapshot.bytesTransferred/snapshot.totalBytes) * 100
                );
                setProgress(progress);
            },
            (error) => {
             alert(error)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {

                    firebase
                    .firestore()
                    .collection('CountryImages')
                    .add({
                      imgURL: url, id:code
                    })
                    .then(() => {
                      setImgURL(url)
                    })
                    alert("upload successful ✔")
                    console.log("upload successful!");
            });
        }
      );
    }

        
    return (
        <div  className='imgUpd-box'>

            <progress value={progress} max="100%"style={{width:"40%",height: "30px",margin:"0% 10%"}}/>

            <br/>
            {/* country images upload */}
            <label id="file-label">
                choose image
                <br/>
                <i class="fas fa-image "></i>
                <input id='file' type='file' onChange={handleChange}/>
            </label>
            <br/>
            <button id='upload-btn' className='btn-primary' type='button' onClick={ () => {
                handleUpload({imgURL: imgURL})
                document.getElementById("file").file = "";
            }}>upload</button>

        </div>
    )
}
